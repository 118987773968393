<template>
  <div>
    <vfl-button
      is-icon-only
      variant="ghost"
      is-square
      @click="refreshMessages"
      :title="$t('buttons.refresh')"
      :disabled="isLoading"
      ><v-icon size="21">mdi-refresh</v-icon
      ><span class="d-sr-only">{{ $t("buttons.refresh") }}</span></vfl-button
    >
  </div>
</template>

<script>
import VflButton from "@/components/vitrue-foundation-library/VflButton.vue";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "MessagingHeaderActions",
  components: { VflButton },
  computed: {
    ...mapState("messagingStore", ["isLoading"]),
    ...mapGetters("userProfile", ["id"])
  },
  methods: {
    ...mapActions("messagingStore", ["initialiseMessages"]),
    refreshMessages() {
      this.initialiseMessages(this.id);
    }
  }
};
</script>

<style scoped lang="scss"></style>
