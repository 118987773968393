<template>
  <span v-if="isSending" class="text-caption vflGreyLight--text"
    >{{ $t("messaging.sending") }}...</span
  >
  <time
    v-else
    class="message-timestamp text-caption vflNeutralDark2--text"
    :datetime="timestamp"
  >
    {{ formattedTimestamp }}
  </time>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MessagingMessageTimestamp",
  props: {
    timestamp: {
      type: Date,
      required: true
    },
    messageId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("messagingStore", ["isSendingMessage"]),
    isSending() {
      return this.isSendingMessage(this.messageId);
    },
    timeOptions() {
      return { hour: "2-digit", minute: "2-digit" };
    },
    formattedTime() {
      return this.timestamp.toLocaleTimeString([], this.timeOptions);
    },
    isToday() {
      const now = new Date();
      return this.timestamp.toDateString() === now.toDateString();
    },
    isYesterday() {
      const now = new Date();
      const yesterday = new Date(now);
      yesterday.setDate(yesterday.getDate() - 1);
      return yesterday.toDateString() === this.timestamp.toDateString();
    },
    formattedTimestamp() {
      if (this.isToday) {
        return `${this.$t("messaging.today")} ${this.formattedTime}`;
      } else if (this.isYesterday) {
        return `${this.$t("messaging.yesterday")} ${this.formattedTime}`;
      } else {
        return this.timestamp.toLocaleString([], {
          ...this.timeOptions,
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });
      }
    }
  }
};
</script>

<style scoped>
.message-timestamp {
  font-weight: 500;
}
</style>
