<template>
  <div
    :class="[
      'pain-form-container',
      { 'horizontal-layout': isDynamicLayoutAssessment }
    ]"
  >
    <div :class="{ 'pain-person-container': isDynamicLayoutAssessment }">
      <div v-if="showSelectBodyPartTitle" class="select-body-part-header">
        <h2 class="select-part">
          {{ $t("assessment.form.pain.selectBodyPart") }}
        </h2>
      </div>
      <pain-person
        class="ma-4"
        ref="painPerson"
        v-model="value"
        :linkedToQuestions="true"
        assessmentType="deskAssessment"
        :showWhyDoWeAskThisButton="false"
        :showTooltipInstruction="!isDynamicLayoutAssessment"
        :painPersonMaxWidth="isDynamicLayoutAssessment ? 350 : null"
      />
    </div>

    <pain-question-panels
      :questions="questions"
      class="pain-panels-section"
      ref="painAreaPanels"
      :results="fullResults"
      @removePainArea="$refs.painPerson.confirmRemovePainArea($event)"
      :assessmentType="assessmentType"
      :headerColor="panelHeaderColor"
      :contentColor="panelContentColor"
    />
  </div>
</template>

<script>
import PainQuestionPanels from "@/components/deskassessment/PainQuestionPanels.vue";
import PainPerson from "@/components/painareas/PainPerson.vue";
import { assessmentTypes } from "@/constants/constants.js";
import debounce from "lodash/debounce";

export default {
  name: "PainForm",
  components: { PainPerson, PainQuestionPanels },
  props: {
    questions: Object,
    value: Object,
    fullResults: Object,
    assessmentType: {
      type: String,
      required: true
    }
  },
  computed: {
    isDynamicLayoutAssessment() {
      return (
        this.assessmentType === assessmentTypes.menopauseAssessment ||
        this.assessmentType === assessmentTypes.menstruationAssessment
      );
    },
    showSelectBodyPartTitle() {
      return this.isDynamicLayoutAssessment;
    },
    panelHeaderColor() {
      return this.isDynamicLayoutAssessment
        ? "vflNeutralLight3"
        : "vitruePaleGrey";
    },
    panelContentColor() {
      return this.isDynamicLayoutAssessment ? "transparent" : "";
    }
  },
  mounted() {
    if (this.isDynamicLayoutAssessment) {
      this.$nextTick(this.setPainPersonPosition);
      window.addEventListener("resize", this.debouncedResizeListener);
      this.$parent.$on(
        "on-dynamic-assessment-transition-complete",
        this.cleanup
      );
    }
  },

  methods: {
    setPainPersonPosition() {
      if (this.$refs.painPerson?.$el) {
        const element = this.$refs.painPerson.$el;

        // Override margin top to get original position
        element.parentElement.style.marginTop = 0;

        const parentRect = element.parentElement.getBoundingClientRect();

        document.documentElement.style.setProperty(
          "--pain-person-position-top",
          `${parentRect.top}px`
        );

        // Remove override
        element.parentElement.removeAttribute("style");
      }
    },
    debouncedResizeListener: debounce(function () {
      this.setPainPersonPosition();
    }, 200),
    cleanup() {
      if (this.isDynamicLayoutAssessment) {
        // Need to reset this because the negative margin will be accounted for in the calculation the second time
        document.documentElement.style.setProperty(
          "--pain-person-position-top",
          null
        );
        window.removeEventListener("resize", this.debouncedResizeListener);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$layout-change: 968px;

.pain-form-container {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  align-items: start;
  position: relative;
}

.pain-form-container.horizontal-layout {
  grid-template-columns: 1fr 1fr;
}

.select-body-part-header {
  background-color: var(--v-vflNeutralLight2-base);
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.select-part {
  color: var(--v-vflGreen-base);
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
}

@media (max-width: $layout-change) {
  .pain-form-container.horizontal-layout {
    grid-template-columns: 1fr;
  }
}

.pain-person-container {
  overflow: hidden;
  border: 3px solid var(--v-vflGreen-base);
  border-radius: 12px;
  background: white;
}

body.is-dynamic-layout-assessment {
  .pain-person-container {
    @media (min-width: $layout-change) {
      position: sticky;
      top: calc(1rem + var(--topbar-height) + 24px);
      margin-top: calc(
        var(--pain-person-placeholder-top) - var(--pain-person-position-top)
      );
    }
  }
}
</style>
