import PainRecommendations from "@/components/assessment/menstruation/report/recommendations/PainRecommendations.json";
import SymptomsRecommendations from "@/components/assessment/menstruation/report/recommendations/SymptomsRecommendations.json";
import { LinkTypes } from "@/services/recommendations/link-types.js";
import PainAssessmentService from "@/services/assessment/pain/pain-assessment-service.js";
import Answers from "@/components/assessment/menstruation/answers.json";

const cardGroups = {
  // we only want to show cramp relief in pain section if it exists and user has cramps - if no pain it should show in symptoms section
  crampRelief: {
    titleKey:
      "menstruationAssessment.report.recommendations.symptoms.groups.crampRelief",
    recommendationsSource: SymptomsRecommendations,
    cards: {
      menstruation_herbal_tea: {
        showConditions: results => hasCramps(results)
      },
      menstruation_diy_warm_compress: {
        showConditions: results => hasCramps(results)
      },
      menstruation_magnesium_rich_food: {
        showConditions: results => hasCramps(results)
      },
      menstruation_warm_shower: {
        showConditions: results => hasCramps(results)
      },
      menstruation_abs_massage: {
        showConditions: results => hasCramps(results)
      }
    }
  },
  painSoothers: {
    titleKey:
      "menstruationAssessment.report.recommendations.pain.groups.painSoothers",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_heat_therapy: {
        showConditions: results => !hasTriedPainRelief(results, 2)
      },
      menstruation_self_massage: {
        showConditions: results => !hasTriedPainRelief(results, 4)
      },
      menstruation_sleep_posture: {
        showConditions: results =>
          hasPainAreas(results, [
            "neck",
            "midback",
            "lowerback",
            "righthip",
            "lefthip",
            "gluteals",
            "righthamstring",
            "lefthamstring",
            "rightknee",
            "leftknee"
          ])
      },
      menstruation_micro_walks: {
        showConditions: () => true
      }
    }
  },
  neck: {
    titleKey: "menstruationAssessment.report.recommendations.pain.groups.neck",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_neck_tilt: {
        showConditions: results => hasPainAreas(results, ["neck"])
      },
      menstruation_neck_rotation: {
        showConditions: results => hasPainAreas(results, ["neck"])
      },
      menstruation_chin_tuck: {
        showConditions: results => hasPainAreas(results, ["neck"])
      }
    }
  },
  shoulder: {
    titleKey:
      "menstruationAssessment.report.recommendations.pain.groups.shoulder",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_shoulder_squeeze: {
        showConditions: results =>
          hasPainAreas(results, ["leftshoulder", "rightshoulder"])
      },
      menstruation_shoulder_roll: {
        showConditions: results =>
          hasPainAreas(results, ["leftshoulder", "rightshoulder"])
      },
      menstruation_across_chest_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftshoulder", "rightshoulder"])
      }
    }
  },
  midBack: {
    titleKey:
      "menstruationAssessment.report.recommendations.pain.groups.midBack",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_spinal_twist: {
        showConditions: results => hasPainAreas(results, ["midback"])
      },
      menstruation_seated_cat_cow: {
        showConditions: results => hasPainAreas(results, ["midback"])
      },
      menstruation_shoulder_squeeze: {
        showConditions: results => hasPainAreas(results, ["midback"])
      }
    }
  },
  lowerBack: {
    titleKey:
      "menstruationAssessment.report.recommendations.pain.groups.lowerBack",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_seated_forward_bend: {
        showConditions: results => hasPainAreas(results, ["lowerback"])
      },
      menstruation_knee_to_chest: {
        showConditions: results => hasPainAreas(results, ["lowerback"])
      },
      menstruation_seated_side_bend: {
        showConditions: results => hasPainAreas(results, ["lowerback"])
      }
    }
  },
  elbow: {
    titleKey: "menstruationAssessment.report.recommendations.pain.groups.elbow",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_tricep_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftelbow", "rightelbow"])
      },
      menstruation_wrist_flexor_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftelbow", "rightelbow"])
      },
      menstruation_desk_extension_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftelbow", "rightelbow"])
      }
    }
  },
  wrist: {
    titleKey: "menstruationAssessment.report.recommendations.pain.groups.wrist",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_prayer_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftwrist", "rightwrist"])
      },
      menstruation_finger_spread_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftwrist", "rightwrist"])
      },
      menstruation_wrist_circles: {
        showConditions: results =>
          hasPainAreas(results, ["leftwrist", "rightwrist"])
      }
    }
  },
  gluteals: {
    titleKey:
      "menstruationAssessment.report.recommendations.pain.groups.gluteals",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_seated_figure_four: {
        showConditions: results => hasPainAreas(results, ["gluteals"])
      },
      menstruation_cross_leg_twist: {
        showConditions: results => hasPainAreas(results, ["gluteals"])
      },
      menstruation_forward_bend_glute_stretch: {
        showConditions: results => hasPainAreas(results, ["gluteals"])
      }
    }
  },
  hip: {
    titleKey: "menstruationAssessment.report.recommendations.pain.groups.hip",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_seated_figure_four: {
        showConditions: results =>
          hasPainAreas(results, ["righthip", "lefthip"])
      },
      menstruation_seated_hip_flexor: {
        showConditions: results =>
          hasPainAreas(results, ["righthip", "lefthip"])
      },
      menstruation_forward_bend_glute_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["righthip", "lefthip"])
      }
    }
  },
  hamstring: {
    titleKey:
      "menstruationAssessment.report.recommendations.pain.groups.hamstring",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_seated_hamstring_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["righthamstring", "lefthamstring"])
      },
      menstruation_heel_slide_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["righthamstring", "lefthamstring"])
      }
    }
  },
  calf: {
    titleKey: "menstruationAssessment.report.recommendations.pain.groups.calf",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_desk_toe_touch: {
        showConditions: results =>
          hasPainAreas(results, ["rightcalf", "leftcalf"])
      },
      menstruation_ankle_circles: {
        showConditions: results =>
          hasPainAreas(results, ["rightcalf", "leftcalf"])
      }
    }
  },
  knee: {
    titleKey: "menstruationAssessment.report.recommendations.pain.groups.knee",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_seated_hamstring_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["rightknee", "leftknee"])
      },
      menstruation_knee_to_chest: {
        showConditions: results =>
          hasPainAreas(results, ["rightknee", "leftknee"])
      },
      menstruation_seated_knee_extension: {
        showConditions: results =>
          hasPainAreas(results, ["rightknee", "leftknee"])
      }
    }
  },
  foot: {
    titleKey: "menstruationAssessment.report.recommendations.pain.groups.foot",
    recommendationsSource: PainRecommendations,
    cards: {
      menstruation_seated_heel_raise: {
        showConditions: results =>
          hasPainAreas(results, ["rightankle", "leftankle"])
      },
      menstruation_ankle_circles: {
        showConditions: results =>
          hasPainAreas(results, ["rightankle", "leftankle"])
      }
    }
  }
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const hasCramps = results => {
  if (!results.symptoms?.menstruationSymptoms) {
    return false;
  }

  return results.symptoms.menstruationSymptoms.includes(
    Answers.symptoms.menstruationSymptoms.abdominalCramps
  );
};

const hasPainAreas = (results, areas) => {
  if (!results?.pain?.areas) {
    return false;
  }

  const resultAreas = Object.keys(results.pain.areas);
  return resultAreas.some(x => areas.some(a => x.includes(a)));
};

const hasTriedPainRelief = (results, value) => {
  if (!results?.pain?.areas) {
    return false;
  }

  const areas = results.pain.areas;
  return Object.values(areas).some(area => {
    return (
      Array.isArray(area.menstruationPainRelief) &&
      area.menstruationPainRelief.includes(value)
    );
  });
};

const getVisibleCardsForGroup = (
  groupCards,
  results,
  recommendationsSource
) => {
  return Object.entries(groupCards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, recommendationsSource[cardId])
    );
};

const hasVisibleCards = (group, results) => {
  return Object.values(group.cards).some(card => card.showConditions(results));
};

export const getPainRecommendations = results => {
  if (!PainAssessmentService.hasPain(results)) {
    return {
      groups: {}
    };
  }

  const visibleGroups = Object.entries(cardGroups).reduce(
    (acc, [groupId, group]) => {
      if (hasVisibleCards(group, results)) {
        acc[groupId] = {
          titleKey: group.titleKey,
          cards: getVisibleCardsForGroup(
            group.cards,
            results,
            group.recommendationsSource
          )
        };
      }
      return acc;
    },
    {}
  );

  return {
    groups: visibleGroups
  };
};
