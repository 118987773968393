import * as teamApi from "@/api/teamApi.js";

const defaultPredefinedMetrics = {
  absenteeismPercentage: 10,
  referralPercentage: 19,
  productivityLossPercentage: 32,
  percentageThinkPostureAffectsPain: 10,
  percentageWithWater: 15,
  percentageExercisingMoreThanThreeTimesAWeek: 29,
  percentageLunchAwayFromDesk: 21,
  percentageActiveBreaks: 79,
  percentageRegularBreaks: 38,
  averageHoursSpentAtDesk: 9,
  totalCount: 1200
};

function getLocalStorageMetrics() {
  try {
    const localStorageMetrics = localStorage.getItem("demoDashboardMetrics");
    const parsedMetrics =
      localStorageMetrics == null ? null : JSON.parse(localStorageMetrics);
    parsedMetrics.totalCount = defaultPredefinedMetrics.totalCount;
    return parsedMetrics;
  } catch (e) {
    return null;
  }
}

export const adminDashboardStore = {
  namespaced: true,
  state: {
    usePredefinedDashboardMetrics:
      getLocalStorageMetrics() == null
        ? false
        : getLocalStorageMetrics()?.usePredefinedDashboardMetrics,
    preDefinedMetrics:
      getLocalStorageMetrics() == null
        ? defaultPredefinedMetrics
        : getLocalStorageMetrics(),
    risks: null,
    painDistribution: null,
    painImpact: null,
    nonTypicalWorkers: [],
    mskHealthCulture: null,
    averageAssessmentScores: null,
    risksLoading: false,
    painDistributionLoading: false,
    painImpactLoading: false,
    nonTypicalWorkersLoading: false,
    mskHealthCultureLoading: false,
    averageAssessmentScoresLoading: false,
    filterTagsIds: []
  },
  mutations: {
    SET_STATE_OF_PREDEFINED_DASHBOARD_METRICS(state, active) {
      state.usePredefinedDashboardMetrics = active;
    },
    SET_PREDEFINED_DASHBOARD_METRIC(state, metricState) {
      state[metricState.metric] = metricState.value;
    },
     SET_RISKS(state, risks) {
      state.risks = risks;
    },
    SET_PAIN_DISTRIBUTION(state, painDistribution) {
      state.painDistribution = painDistribution;
    },
    SET_PAIN_IMPACT(state, painImpact) {
      state.painImpact = painImpact;
    },
    SET_NON_TYPICAL_WORKERS(state, nonTypicalWorkers) {
      state.nonTypicalWorkers = nonTypicalWorkers;
    },
    SET_MSK_HEALTH_CULTURE(state, mskHealthCulture) {
      state.mskHealthCulture = mskHealthCulture;
    },
    SET_AVERAGE_ASSESSMENT_SCORES(state, averageAssessmentScores) {
      state.averageAssessmentScores = averageAssessmentScores;
    },
    SET_RISKS_LOADING(state, loading) {
      state.risksLoading = loading;
    },
    SET_PAIN_DISTRIBUTION_LOADING(state, loading) {
      state.painDistributionLoading = loading;
    },
    SET_PAIN_IMPACT_LOADING(state, loading) {
      state.painImpactLoading = loading;
    },
    SET_NON_TYPICAL_WORKERS_LOADING(state, loading) {
      state.nonTypicalWorkersLoading = loading;
    },
    SET_MSK_HEALTH_CULTURE_LOADING(state, loading) {
      state.mskHealthCultureLoading = loading;
    },
    SET_AVERAGE_ASSESSMENT_SCORES_LOADING(state, loading) {
      state.averageAssessmentScoresLoading = loading;
    },
    SET_FILTER_TAGS_IDS(state, filterTagsIds) {
      state.filterTagsIds = filterTagsIds;
    }
  },
  actions: {
    refreshWhoNeedsAttention({ commit }) {
      commit("triggerRefresh");
    },
    async getRisksCardData({ commit }, tagIds = []) {
        commit("SET_RISKS_LOADING", true);
        const risks = tagIds?.length > 0 ? await teamApi.getTeamRisksForCurrentUserWithTags(tagIds) : await teamApi.getTeamRisksForCurrentUser();
        commit("SET_RISKS", risks);
        commit("SET_RISKS_LOADING", false);
    },
    async getPainDistributionCardData({ commit }, tagIds = []) {
        commit("SET_PAIN_DISTRIBUTION_LOADING", true);
        const painDistribution = tagIds?.length > 0 ? await teamApi.getTeamPainDistributionWithTags(tagIds) : await teamApi.getTeamPainDistribution();
        commit("SET_PAIN_DISTRIBUTION", painDistribution);
        commit("SET_PAIN_DISTRIBUTION_LOADING", false);
    },
    async getPainImpactCardData({ commit }, tagIds = []) {
        commit("SET_PAIN_IMPACT_LOADING", true);
        const painImpact = tagIds?.length > 0 ? await teamApi.getTeamPainImpactWithTags(tagIds) : await teamApi.getTeamPainImpact();
        commit("SET_PAIN_IMPACT", painImpact);
        commit("SET_PAIN_IMPACT_LOADING", false);
    },
    async getNonTypicalWorkersCardData({ commit }, tagIds = []) {
        commit("SET_NON_TYPICAL_WORKERS_LOADING", true);
        const nonTypicalWorkers = tagIds?.length > 0 ? await teamApi.getPeopleWithUniqueRequirementsWithTags(tagIds) : await teamApi.getPeopleWithUniqueRequirements();
        commit("SET_NON_TYPICAL_WORKERS", nonTypicalWorkers);
        commit("SET_NON_TYPICAL_WORKERS_LOADING", false);
    },
    async getMskHealthCultureCardData({ commit }, tagIds = []) {
        commit("SET_MSK_HEALTH_CULTURE_LOADING", true);
        const mskHealthCulture = tagIds?.length > 0 ? await teamApi.getMskHealthCultureWithTags(tagIds) : await teamApi.getMskHealthCulture();
        commit("SET_MSK_HEALTH_CULTURE", mskHealthCulture);
        commit("SET_MSK_HEALTH_CULTURE_LOADING", false);
    },
    async getAverageAssessmentScoresCardData({ commit }, tagIds = []) {
        commit("SET_AVERAGE_ASSESSMENT_SCORES_LOADING", true);
        const averageAssessmentScores = tagIds?.length > 0 ? await teamApi.getAverageAssessmentScoresWithTags(tagIds) : await teamApi.getAverageAssessmentScores();
        commit("SET_AVERAGE_ASSESSMENT_SCORES", averageAssessmentScores);
        commit("SET_AVERAGE_ASSESSMENT_SCORES_LOADING", false);
    }
  },
  getters: {
    usePredefinedDashboardMetrics: state => state.usePredefinedDashboardMetrics,
    dashboardDemoMetricsState: state => state.preDefinedMetrics,
    risks: state => state.risks,
    painDistribution: state => state.painDistribution,
    painImpact: state => state.painImpact,
    nonTypicalWorkers: state => state.nonTypicalWorkers,
    mskHealthCulture: state => state.mskHealthCulture,
    averageAssessmentScores: state => state.averageAssessmentScores,
    risksLoading: state => state.risksLoading,
    painDistributionLoading: state => state.painDistributionLoading,
    painImpactLoading: state => state.painImpactLoading,
    nonTypicalWorkersLoading: state => state.nonTypicalWorkersLoading,
    mskHealthCultureLoading: state => state.mskHealthCultureLoading,
    averageAssessmentScoresLoading: state => state.averageAssessmentScoresLoading,
    filterTagsIds: state => state.filterTagsIds
  }
};
