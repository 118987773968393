import { BaseTextModule } from "@/components/assessment/common/report/base-text-module.js";
import { MUSCLE_LOSS_CONTENT } from "@/components/assessment/menopause/report/text-modules/content/muscle-loss-content.js";

export class MuscleLossModule extends BaseTextModule {
  constructor() {
    super(MUSCLE_LOSS_CONTENT);
  }

  getSectionTitle() {
    return this.getText("title");
  }

  getCardContent() {
    return this.getText("content");
  }
}
