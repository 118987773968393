<template>
  <div class="note-sharing-info">
    <p class="mb-0 text-body-dark text-body-2">
      <v-icon size="20">mdi-eye-outline</v-icon>
      {{ $t("userProfile.notes.visibleToAdmins") }}
    </p>
    <p
      v-if="showNotVisibleToMessage"
      class="mb-0 mt-1 text-body-dark text-body-2"
    >
      <v-icon size="20">mdi-eye-off-outline</v-icon>
      {{ $t("userProfile.notes.notVisibleTo", { name: name || email }) }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UserProfileNoteSharingInfo",
  props: {
    hideNotVisibleToMessage: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({ adminId: "userId" }),
    ...mapGetters("userProfile", ["id", "name", "email"]),
    showNotVisibleToMessage() {
      return !this.hideNotVisibleToMessage && this.adminId !== this.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.note-sharing-info {
  background-color: var(--v-vflBeigeLight1-base);
  border-radius: 12px;
  padding: 0.5rem 1rem;

  i {
    color: inherit;
    margin-right: 0.25rem;
  }

  p {
    line-height: 1;
  }
}

::v-deep {
  fieldset {
    border-radius: 12px;
  }
}
</style>
