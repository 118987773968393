<template>
  <div id="pain-overview-container" class="d-flex flex-column">
    <pain-overview-header class="mb-4" :loading="painDistributionLoading" />

    <vfl-alert
      v-if="error && !painDistributionLoading"
      :message="$t('mskOverview.error')"
      type="error"
      is-light
    />
    <pain-overview-card
      v-else
      class="flex-grow-1"
      :painData="painData"
      :industryAverage="industryAverage"
      :loading="painDistributionLoading"
    />
  </div>
</template>

<script>
import PainOverviewHeader from "@/components/dashboard/cards/pain-overview/PainOverviewHeader.vue";
import PainOverviewCard from "@/components/dashboard/cards/pain-overview/PainOverviewCard.vue";
import VflAlert from "@/components/vitrue-foundation-library/VflAlert.vue";
import { mapPainData } from "@/components/dashboard/cards/pain-overview/pain-data-mapper";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "HealthOverview",
  components: {
    PainOverviewHeader,
    PainOverviewCard,
    VflAlert
  },
  computed: {
    ...mapGetters("adminDashboard", [
      "painDistribution",
      "painDistributionLoading",
      "filterTagsIds"
    ])
  },
  data() {
    return {
      error: false,
      industryAverage: 0,
      painData: {}
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    filterTagsIds() {
      this.getData(this.filterTagsIds);
    }
  },
  methods: {
    ...mapMutations("adminDashboard", [
      "SET_PAIN_DISTRIBUTION_LOADING",
      "SET_NON_TYPICAL_WORKERS_LOADING"
    ]),
    ...mapActions("adminDashboard", [
      "getPainDistributionCardData",
      "getNonTypicalWorkersCardData"
    ]),
    async getData(filterTagsIds) {
      try {
        await this.getPainDistributionCardData(filterTagsIds);

        this.industryAverage = this.painDistribution.industryAverage;
        this.painData = mapPainData(this.painDistribution);
        await this.getNonTypicalWorkersCardData(filterTagsIds);
      } catch (ex) {
        this.$logger.captureException(ex);
        this.error = true;
      } finally {
        this.SET_PAIN_DISTRIBUTION_LOADING(false);
        this.SET_NON_TYPICAL_WORKERS_LOADING(false);
      }
    }
  }
};
</script>
