import i18n from "@/plugins/i18n.js";

export const HEADER_CONTENT = {
  getTitleText: () => {
    return i18n.t("menstruationAssessment.report.content.header.title");
  },
  getContentText: () => {
    return i18n.t("menstruationAssessment.report.content.header.content");
  }
};
