<template>
  <div class="messaging-container" :class="`is-${layout}`">
    <messaging-header />
    <messaging-window />
    <messaging-footer />
  </div>
</template>

<script>
import MessagingHeader from "@/components/messaging/layout/MessagingHeader.vue";
import MessagingWindow from "@/components/messaging/layout/MessagingWindow.vue";
import MessagingFooter from "@/components/messaging/layout/MessagingFooter.vue";
import { mapState } from "vuex";

export default {
  name: "MessagingContainer",
  components: {
    MessagingHeader,
    MessagingWindow,
    MessagingFooter
  },
  props: {
    layout: {
      type: String,
      default: "page",
      validator: value => ["sidepanel", "page"].includes(value)
    }
  },
  computed: {
    ...mapState("messagingStore", ["isLoading"])
  }
};
</script>

<style scoped lang="scss">
.messaging-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &.is-page {
    height: calc(100vh - var(--topbar-height));
  }
}
</style>
