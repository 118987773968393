<template>
  <v-slide-y-reverse-transition>
    <v-snackbar
      light
      bottom
      right
      class="no-print feedback-container"
      v-model="showFeedback"
      :timeout="-1"
      v-scroll="onScroll"
      :style="{ marginBottom: bottomOffset }"
    >
      <button
        v-if="isDismissible && !showFeedbackThanks"
        class="close-button"
        @click="dismiss"
      >
        <v-icon color="black" :size="21">mdi-close</v-icon
        ><span class="d-sr-only">{{ $t("buttons.close") }}</span>
      </button>

      <div v-if="showFeedbackThanks" class="vfl-card thanks-feedback-container">
        <feedback-thanks
          :show-title="false"
          :is-dismissable="true"
          :has-text-center="false"
          @dismiss="dismiss"
        />
      </div>

      <v-card class="vfl-card" v-else style="max-width: 400px">
        <div class="pa-4">
          <p class="text-h6 text-none" style="max-width: 25ch">
            {{ title }}
          </p>

          <div>
            <feedback-emojis
              v-if="!ratingSubmitted"
              v-model="reaction"
              :showLabels="false"
              @input="submitFeedback()"
              class="mx-n4"
            />
            <v-textarea
              v-if="ratingSubmitted"
              ref="textInput"
              outlined
              label
              v-model="textFeedback"
              auto-grow
              hide-details
              filled
              no-resize
              class="text-area mb-3"
              background-color="white"
            >
            </v-textarea>

            <div class="d-flex">
              <button
                class="vfl-button-primary is-small mx-auto"
                v-if="ratingSubmitted"
                @click="addTextToFeedback"
                :disabled="sending"
                :loading="sending"
              >
                {{ $t("buttons.submit") }}
              </button>
            </div>
          </div>
        </div>

        <feedback-calendly-link />
      </v-card>
    </v-snackbar>
  </v-slide-y-reverse-transition>
</template>

<script>
import FeedbackEmojis from "@/components/common/Feedback/FeedbackEmojis.vue";
import FeedbackThanks from "@/components/common/Feedback/FeedbackThanks.vue";
import { mapGetters, mapMutations } from "vuex";
import { createFeedback, updateFeedback } from "@/customApi";
import { OnCloseTypes } from "@/services/feedback-service.js";
import FeedbackCalendlyLink from "@/components/common/Feedback/FeedbackCalendlyLink.vue";

export default {
  name: "short-feedback",
  components: {
    FeedbackEmojis,
    FeedbackThanks,
    FeedbackCalendlyLink
  },
  data() {
    return {
      reaction: null,
      hoverEmoji: null,
      textFeedback: "",
      feedbackId: null,
      sending: false,
      submitted: false,
      nearBottomOfPage: false,
      scrolledToEnd: false,
      dismissed: false
    };
  },
  props: {
    assessmentId: String,
    urlSuffix: String,
    triggerOnScroll: Boolean,
    isDismissible: {
      type: Boolean,
      default: false
    },
    bottomOffset: 0
  },
  mounted() {
    if (!this.triggerOnScroll) return;

    window.addEventListener("scroll", this.checkWindowScroll);
  },
  beforeDestroy() {
    if (!this.triggerOnScroll) return;

    window.removeEventListener("scroll", this.checkWindowScroll);
  },
  computed: {
    ...mapGetters(["userEmail", "isAdminUser"]),
    feedbackDto() {
      const guidRegex = new RegExp(this.assessmentId, "i");
      let cleanPath = this.$route.path.replace(guidRegex, "");
      const path = `${cleanPath}${this.urlSuffix ? `${this.urlSuffix}` : ""}`;

      return {
        Id: this.feedbackId,
        AssessmentId: this.assessmentId,
        Email: { value: this.userEmail },
        Score: this.reaction.value,
        WrittenFeedback: this.textFeedback,
        Url: path
      };
    },
    ratingSubmitted() {
      return !!this.feedbackId;
    },
    showFeedbackBasedOnConditions() {
      return (
        this.$vuetify.breakpoint.mdAndUp && !this.submitted && !this.dismissed
      );
    },
    showFeedbackOnScroll() {
      return !this.triggerOnScroll || this.nearBottomOfPage;
    },
    showFeedback() {
      return (
        (this.showFeedbackBasedOnConditions && this.showFeedbackOnScroll) ||
        this.showFeedbackThanks
      );
    },
    showFeedbackThanks() {
      return this.reaction?.value >= 4 && this.submitted && !this.dismissed;
    },
    title() {
      if (this.ratingSubmitted) {
        const key = this.reaction?.value < 4 ? "low" : "high";

        return this.$t(`feedback.shortFeedbackTitle.text.${key}`);
      }

      return this.$t(`feedback.shortFeedbackTitle.rating`);
    }
  },
  methods: {
    ...mapMutations(["addAsyncRequest", "removeAsyncRequest"]),
    async submitFeedback() {
      if (this.reaction && !this.sending) {
        try {
          this.sending = true;
          this.feedbackId = await createFeedback(this.feedbackDto);
          this.$gtag.event("Submit Short Feedback", {
            event_category: "Feedback"
          });
        } catch (err) {
          this.$logger.captureException(error);
        } finally {
          this.sending = false;
        }
      }
    },
    async addTextToFeedback() {
      if (this.feedbackDto.Id && !this.sending) {
        try {
          this.sending = true;
          await updateFeedback(this.feedbackDto);
          this.$gtag.event("Add text to Short Feedback", {
            event_category: "Feedback"
          });
        } catch (err) {
          this.$logger.captureException(err);
        } finally {
          this.sending = false;
          this.submitted = true;

          if (!this.showFeedbackThanks) {
            this.$emit("onClose", OnCloseTypes.SUBMITTED);
          }

          this.$emit("input", true);
        }
      }
    },
    onScroll(e) {
      if (!this.triggerOnScroll || typeof window === "undefined") return;

      const top = window.pageYOffset || e.target.scrollTop || 0;

      this.toTopVisible = top > 20;
      this.nearBottomOfPage =
        window.innerHeight + window.pageYOffset >=
        e.target.body.offsetHeight - 500;
    },
    checkWindowScroll() {
      if (!this.triggerOnScroll || this.scrolledToEnd) return;

      const scrolledFromTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const totalScrollableHeight = document.documentElement.scrollHeight;

      const buffer = 500;

      if (scrolledFromTop + windowHeight + buffer >= totalScrollableHeight) {
        this.$emit("scrolledToBottom");
        this.scrolledToEnd = true;
      }
    },
    dismiss() {
      this.$emit("onClose", OnCloseTypes.DISMISSED);
      this.dismissed = true;
    }
  }
};
</script>

<style scoped lang="scss">
.feedback-container {
  z-index: 10;

  ::v-deep .v-snack__wrapper {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    color: inherit;

    .v-snack__content {
      padding: 0;
    }
  }

  p {
    color: black;
  }
}

.close-button {
  position: absolute;
  right: 2.25rem;
  top: 1.5rem;
  z-index: 1;
  transition: transform 0.1s ease-out;

  &:hover {
    transform: scale(1.1);
  }
}

.text-area {
  width: 370px;
  height: 130px;
  overflow-y: auto;
}

.thanks-feedback-container {
  /* Prevent FOUC when image loads */
  min-height: 206px;
  overflow: hidden;
}

/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}
</style>
