import {
  STAGES,
  TREATMENT_TYPES
} from "@/components/assessment/menstruation/report/constants/index.js";

export const GRAPH_TYPES = {
  ...STAGES,
  ...TREATMENT_TYPES
};

export const GRAPH_TITLES = {
  HORMONE_LEVELS:
    "menstruationAssessment.report.content.mskHealth.graphs.titles.stages",
  CONTRACEPTIVE_INJECTIONS:
    "menstruationAssessment.report.content.mskHealth.graphs.titles.contraceptiveInjections"
};
