<template>
  <div class="menopause-report-graph-card">
    <h4
      class="text-body-darkest mb-8"
      :class="$vuetify.breakpoint.lgAndUp ? 'text-h4' : 'text-h6'"
    >
      {{ $t(title) }}
    </h4>
    <menopause-graph v-if="isStageGraph" :stage="type" />
    <injectable-contraceptives-graph
      v-else-if="isInjectableContraceptiveGraph"
    />
    <hormone-replacement-therapy-graph v-else-if="isHrt" />
  </div>
</template>

<script>
import MenopauseGraph from "@/components/assessment/menopause/graphs/MenopauseGraph.vue";
import InjectableContraceptivesGraph from "@/components/assessment/menopause/graphs/InjectableContraceptivesGraph.vue";
import HormoneReplacementTherapyGraph from "@/components/assessment/menopause/graphs/HormoneReplacementTherapyGraph.vue";
import { STAGES } from "@/components/assessment/menopause/report/constants/index.js";
import { GRAPH_TYPES } from "@/components/assessment/menopause/report/constants/graphs.js";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";

export default {
  name: "MenopauseReportGraph",
  components: {
    HormoneReplacementTherapyGraph,
    InjectableContraceptivesGraph,
    MenopauseGraph
  },
  props: {
    type: {
      type: String,
      default: "perimenopause",
      validator: value => Object.values(GRAPH_TYPES).includes(value)
    }
  },
  computed: {
    isStageGraph() {
      return Object.values(STAGES).includes(this.type);
    },
    isInjectableContraceptiveGraph() {
      return this.type === "injectable_contraceptives";
    },
    isHrt() {
      return this.type === "hrt";
    },
    title() {
      return menopauseReportService.getGraphTitle(this.type);
    }
  }
};
</script>

<style scoped lang="scss">
.menopause-report-graph-card {
  background-color: var(--v-vflBeigeLight2-base);
  border-radius: 16px;
  padding: 2rem 1rem;

  h4 {
    margin-bottom: 1rem;
    padding: 0 4rem 0 6rem;

    @media screen and (max-width: 1200px) {
      padding: 0 2rem 0 3rem;
    }
  }

  ::v-deep {
    svg {
      min-width: 180px;
      width: 100%;
    }
  }
}
</style>
