<template>
  <div class="menstruation-timeline-container">
    <h3 class="text-overline vflNeutralDark2--text" style="position: absolute">
      {{ $t("menstruationAssessment.timelineComponent.title") }}
    </h3>
    <svg
      viewBox="0 0 633 278"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="timeline-svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <!-- Arrow axis -->
      <path
        d="M629.707 249.707C630.098 249.317 630.098 248.683 629.707 248.293L623.343 241.929C622.953 241.538 622.319 241.538 621.929 241.929C621.538 242.319 621.538 242.953 621.929 243.343L627.586 249L621.929 254.657C621.538 255.047 621.538 255.681 621.929 256.071C622.319 256.462 622.953 256.462 623.343 256.071L629.707 249.707ZM25 250H629V248H25V250Z"
        fill="black"
      />

      <!-- Flag -->
      <rect x="315" y="107" width="2" height="141" rx="1" fill="#936B2E" />
      <path
        d="M343 109.402H313V129.402H343L335.414 119.402L343 109.402Z"
        fill="#005F61"
      />

      <!-- Follicular curve -->
      <path
        d="M315 248C315 248 250.081 248 170 248C89.9187 248 25 248 25 248C25 182.278 89.9187 129 170 129C250.081 129 315 182.278 315 248Z"
        fill="#F4B4BE"
      />
      <!-- Luteal curve -->
      <path
        d="M607 248C607 248 542.081 248 462 248C381.919 248 317 248 317 248C317 182.278 381.919 129 462 129C542.081 129 607 182.278 607 248Z"
        fill="#F7BE7D"
      />

      <!-- Period curve -->
      <path
        d="M153 248.5C153 248.5 123.413 248.5 90 248.5C56.5868 248.5 32 248.5 32 248.5C32 215.087 59.0868 188 92.5 188C125.913 188 153 215.087 153 248.5Z"
        fill="#E03336"
      />
      <circle cx="146" cy="200" r="5" fill="#E03336" />
      <circle cx="152.5" cy="212.5" r="3.5" fill="#E03336" />
      <circle cx="141.5" cy="213.5" r="4.5" fill="#E03336" />
    </svg>

    <div class="timeline-overlay">
      <div class="timeline-label follicular-phase">
        {{ $t("menstruationAssessment.timelineComponent.follicular") }}
      </div>
      <div class="timeline-label ovulation">
        {{ $t("menstruationAssessment.timelineComponent.ovulation") }}
      </div>
      <div class="timeline-label luteal-phase">
        {{ $t("menstruationAssessment.timelineComponent.luteal") }}
      </div>
      <div class="timeline-label period">
        {{ $t("menstruationAssessment.timelineComponent.period") }}
      </div>
      <div class="timeline-day day-1">
        {{ $t("menstruationAssessment.timelineComponent.day1") }}
      </div>
      <div class="timeline-day day-7">
        {{ $t("menstruationAssessment.timelineComponent.day7") }}
      </div>
      <div class="timeline-day day-14">
        {{ $t("menstruationAssessment.timelineComponent.day14") }}
      </div>
      <div class="timeline-day day-28">
        {{ $t("menstruationAssessment.timelineComponent.day28") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenstruationTimeline"
};
</script>

<style lang="scss" scoped>
.menstruation-timeline-container {
  container-type: inline-size;
  position: relative;
  width: 100%;
  max-width: 700px;
  margin-bottom: 3rem;
  aspect-ratio: 633 / 278; // original svg dimensions
}

.timeline-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.timeline-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.timeline-label {
  position: absolute;
  padding: 0.25rem 0.75rem;
  background-color: #e5f0f0;
  border-radius: 4px;
  color: #2a3c3c;
  font-size: calc(0.875rem + 0.1vw);
  white-space: nowrap;
  transform-origin: left top;
  transform: scale(var(--scale-factor, 1));
}

.timeline-day {
  position: absolute;
  padding: 0.25rem 0.5rem;
  color: #2a3c3c;
  font-size: calc(0.875rem + 0.1vw);
  white-space: nowrap;
  transform-origin: left top;
  transform: scale(var(--scale-factor, 1));
}

.follicular-phase {
  left: 17%;
  top: 23%;
}

.luteal-phase {
  left: 65%;
  top: 23%;
}

.ovulation {
  left: 44%;
  top: 23%;
}

.period {
  left: 8%;
  top: 55%;
}

.day-1 {
  left: 3%;
  top: 90%;
}

.day-7 {
  left: 20%;
  top: 90%;
}

.day-14 {
  left: 45%;
  top: 90%;
}

.day-28 {
  left: 90%;
  top: 90%;
}

/* Add transitions for smooth scaling */
.timeline-label,
.timeline-day {
  transition: transform 0.3s ease-in-out;
}

@container (max-width: 600px) {
  .timeline-label,
  .timeline-day {
    --scale-factor: 0.85;
  }
}

@container (max-width: 500px) {
  .timeline-label,
  .timeline-day {
    --scale-factor: 0.75;
  }
}

@container (max-width: 400px) {
  .timeline-label,
  .timeline-day {
    --scale-factor: 0.6;
  }
}
</style>
