import { assessmentTypes } from "@/constants/constants.js";
import DriverAssessmentSchema from "@/assets/json/DriverAssessment/questions.json";
import PhysicalLabourAssessmentSchema from "@/components/assessment/physical-labour/questionSchema.json";
import PreExistingConditionsSchema from "@/assets/json/pre-existing-conditions.json";
import DeskAssessmentSchema from "@/assets/json/DeskAssessment/DeskAssessmentQuestions.json";
import MenopauseAssessmentSchema from "@/components/assessment/menopause/question-schema.json";
import MenstruationAssessmentSchema from "@/components/assessment/menstruation/question-schema.json";
import DeskAssessmentV2Schema from "@/components/assessment/desk/question-schema.json";
import sentry from "@/sentry";
import store from "@/store";
import { cloneDeep } from "lodash";

const ErrorMessages = {
  INVALID_SCHEMA_B_TYPE: "schemaBs must be an array",
  INVALID_SCHEMA_A_TYPE: "schemaA must be either an array or an object",
  MISSING_MERGE_PROPERTIES: "Missing properties required for schema merge",
  SECTION_NOT_FOUND: section => `Section '${section}' not found in schema`,
  INSERT_AFTER_NOT_FOUND: insertAfter =>
    `'insertAfter' target not found: ${insertAfter}`,
  PAIN_AREA_NOT_FOUND: painArea =>
    `Matching pain area '${painArea}' not found in questions`,
  THEME_NOT_FOUND: theme => `No entry in schema with theme '${theme}' found`,
  NO_PAGE_SPECIFIED: theme =>
    `'pages' are present in theme '${theme}', but no 'page' specified for merging`,
  PAGE_NOT_FOUND: (page, theme) =>
    `Page named '${page}' not found within theme '${theme}'`,
  NO_QUESTIONS_FOUND: (section, page) =>
    `No questions found in section '${section}'${
      page ? ` page '${page}'` : ""
    }`,
  QUESTIONS_NOT_ARRAY: "Questions must be an array"
};
export const getSchema = assessmentType => {
  switch (assessmentType) {
    case assessmentTypes.driverAssessment:
      return getDriverAssessmentSchema();
    case assessmentTypes.physicalLabourAssessment:
      return getPhysicalLabourAssessmentSchema();
    case assessmentTypes.deskAssessment:
      return getDeskAssessmentSchema();
    case assessmentTypes.menopauseAssessment:
      return getMenopauseAssessmentSchema();
    case assessmentTypes.menstruationAssessment:
      return getMenstruationAssessmentSchema();
    case "desk-v2":
      return getDeskAssessmentV2Schema();
  }
};

function getDriverAssessmentSchema() {
  const schema = cloneDeep(DriverAssessmentSchema);

  if (!store.getters.enablePreExistingConditions) {
    return schema;
  } else {
    return combineAssessmentSchemas(
      schema,
      PreExistingConditionsSchema,
      assessmentTypes.driverAssessment
    );
  }
}

function getPhysicalLabourAssessmentSchema() {
  return PhysicalLabourAssessmentSchema;
}

function getMenopauseAssessmentSchema() {
  const schema = cloneDeep(MenopauseAssessmentSchema);

  if (!store.getters.enablePreExistingConditions) {
    return schema;
  } else {
    return combineAssessmentSchemas(
      schema,
      PreExistingConditionsSchema,
      assessmentTypes.menopauseAssessment
    );
  }
}

function getMenstruationAssessmentSchema() {
  const schema = cloneDeep(MenstruationAssessmentSchema);

  if (!store.getters.enablePreExistingConditions) {
    return schema;
  } else {
    return combineAssessmentSchemas(
      schema,
      PreExistingConditionsSchema,
      assessmentTypes.menstruationAssessment
    );
  }
}

function getDeskAssessmentSchema() {
  const schema = cloneDeep(DeskAssessmentSchema);

  if (!store.getters.enablePreExistingConditions) {
    return schema;
  } else {
    return combineAssessmentSchemas(
      schema,
      PreExistingConditionsSchema,
      assessmentTypes.deskAssessment
    );
  }
}

function getDeskAssessmentV2Schema() {
  const schema = cloneDeep(DeskAssessmentV2Schema);

  if (!store.getters.enablePreExistingConditions) {
    return schema;
  } else {
    return combineAssessmentSchemas(
      schema,
      PreExistingConditionsSchema,
      assessmentTypes.deskAssessment
    );
  }
}

function combineAssessmentSchemas(
  baseSchema,
  extensionSchemas,
  assessmentType = null
) {
  if (!baseSchema || !Array.isArray(extensionSchemas)) {
    handleError(ErrorMessages.INVALID_SCHEMA_B_TYPE);
    return baseSchema;
  }

  const isObjectSchema =
    !Array.isArray(baseSchema) && typeof baseSchema === "object";
  const isArraySchema = Array.isArray(baseSchema);

  if (!isObjectSchema && !isArraySchema) {
    handleError(ErrorMessages.INVALID_SCHEMA_A_TYPE);
    return baseSchema;
  }

  const schemasToMerge = filterSchemasByAssessmentType(
    extensionSchemas,
    assessmentType
  );

  schemasToMerge.forEach(extensionSchema => {
    if (!validateMergeProps(extensionSchema)) return;

    if (isObjectSchema) {
      mergeQuestionsIntoSection(baseSchema, extensionSchema);
    } else {
      mergeQuestionsIntoTheme(baseSchema, extensionSchema);
    }
  });

  return baseSchema;
}

function mergeQuestionsIntoTheme(baseSchema, extensionSchema) {
  const {
    merge: { theme, page, insertAfter, painAreas },
    ...newQuestion
  } = extensionSchema;

  const themeToUpdate = baseSchema.find(item => item.theme === theme);

  if (!themeToUpdate) {
    handleError(ErrorMessages.THEME_NOT_FOUND(theme));
    return;
  }

  let targetQuestions;

  if (themeToUpdate.pages) {
    if (!page) {
      handleError(ErrorMessages.NO_PAGE_SPECIFIED(theme));
      return;
    }

    const pageToUpdate = themeToUpdate.pages.find(p => p.name === page);

    if (!pageToUpdate) {
      handleError(ErrorMessages.PAGE_NOT_FOUND(page, theme));
      return;
    }

    targetQuestions = pageToUpdate.questions;
  } else {
    targetQuestions = themeToUpdate.questions;
  }

  if (Array.isArray(targetQuestions)) {
    const newQuestionClone = cloneDeep(newQuestion);
    insertQuestion(targetQuestions, insertAfter, newQuestionClone);
  } else {
    addQuestionToPainAreas(
      targetQuestions,
      insertAfter,
      newQuestion,
      painAreas
    );
  }
}

function filterSchemasByAssessmentType(schemas, assessmentType) {
  return assessmentType
    ? schemas.filter(schema => {
        if (!schema.merge?.assessmentTypes) return true;
        return schema.merge.assessmentTypes.includes(assessmentType);
      })
    : schemas;
}

function validateMergeProps(schema) {
  if (!schema.merge?.theme || !schema.merge?.insertAfter) {
    handleError(ErrorMessages.MISSING_MERGE_PROPERTIES);
    return false;
  }
  return true;
}

function mergeQuestionsIntoSection(baseSchema, extensionSchema) {
  const {
    merge: { theme: section, insertAfter, painAreas, page },
    ...newQuestion
  } = extensionSchema;

  if (!baseSchema[section]) {
    handleError(ErrorMessages.SECTION_NOT_FOUND(section));
    return;
  }

  let targetQuestions;

  if (baseSchema[section].pages) {
    if (!page) {
      handleError(ErrorMessages.NO_PAGE_SPECIFIED(section));
      return;
    }

    const pageToUpdate = baseSchema[section].pages.find(p => p.name === page);
    if (!pageToUpdate) {
      handleError(ErrorMessages.PAGE_NOT_FOUND(page, section));
      return;
    }

    targetQuestions = pageToUpdate.questions;
  } else {
    targetQuestions = baseSchema[section].questions;
  }

  if (!targetQuestions) {
    handleError(ErrorMessages.NO_QUESTIONS_FOUND(section, page));
    return;
  }

  if (Array.isArray(targetQuestions)) {
    insertQuestion(targetQuestions, insertAfter, cloneDeep(newQuestion));
  } else {
    addQuestionToPainAreas(
      targetQuestions,
      insertAfter,
      newQuestion,
      painAreas
    );
  }
}

function addQuestionToPainAreas(
  questions,
  insertAfter,
  newQuestion,
  painAreas
) {
  if (painAreas && painAreas.length) {
    painAreas.forEach(painArea => {
      if (!questions[painArea]) {
        handleError(ErrorMessages.PAIN_AREA_NOT_FOUND(painArea));
        return;
      }

      const newQuestionClone = cloneDeep(newQuestion);
      insertQuestion(questions[painArea], insertAfter, newQuestionClone);
    });
  }
}

function insertQuestion(questions, insertAfter, newQuestion) {
  if (!Array.isArray(questions)) {
    handleError(ErrorMessages.QUESTIONS_NOT_ARRAY);
    return;
  }

  const alreadyMerged = questions.some(q => q.name === newQuestion.name);
  if (alreadyMerged) return;

  const index = questions.findIndex(q => q.name === insertAfter);

  if (index !== -1) {
    questions.splice(index + 1, 0, newQuestion);
  } else {
    handleError(ErrorMessages.INSERT_AFTER_NOT_FOUND(insertAfter));
  }
}

function handleError(message) {
  if (import.meta.env.DEV) {
    console.error(message);
  } else {
    sentry.captureMessage(message, "error");
  }
}
