import store from "@/store.js";
import sentry from "@/sentry";
import {
  RedirectParameters,
  RedirectStoreRoutes
} from "@/services/authorization/url-redirect/redirect-constants.js";

const SESSION_STORAGE_ID = "redirectData";

export function setUpRedirectData(queryString = null) {
  let data = getRedirectDataFromQueryOrSession(queryString);
  if (!data) {
    return;
  }

  store.commit(RedirectStoreRoutes.MUTATIONS.SET_REDIRECT_DATA, data);
}

export function saveRedirectDataToSessionStorage() {
  if (!store.getters[RedirectStoreRoutes.GETTERS.REDIRECT_DATA]) {
    return;
  }

  sessionStorage.setItem(
    SESSION_STORAGE_ID,
    JSON.stringify(store.getters[RedirectStoreRoutes.GETTERS.REDIRECT_DATA])
  );
}

function getRedirectDataFromQueryOrSession(queryString) {
  if (queryString) {
    const data = {};
    Object.values(RedirectParameters).forEach(param => {
      if (queryString.has(param)) {
        data[param] = queryString.get(param);
      }
    });

    if (Object.keys(data).length > 0) {
      return data;
    }
  }

  // Following SSO login
  let sessionData = sessionStorage.getItem(SESSION_STORAGE_ID);
  if (!sessionData) {
    return null;
  }

  return tryParseFromSessionStorage(sessionData);
}

function tryParseFromSessionStorage(sessionData) {
  try {
    const data = JSON.parse(sessionData);
    if (!Object.values(RedirectParameters).some(param => data[param])) {
      return null;
    }
    return data;
  } catch (e) {
    sentry.captureException(e);
    return null;
  }
}
