import Answers from "@/components/assessment/menstruation/answers.json";

export const STAGES = {
  PERIOD: "period",
  FOLLICULAR: "follicular",
  OVULATION: "ovulation",
  LUTEAL: "luteal"
};

export const STAGE_MAPPING = {
  [Answers.stage.menstruationPhase.follicular]: STAGES.FOLLICULAR,
  [Answers.stage.menstruationPhase.ovulation]: STAGES.OVULATION,
  [Answers.stage.menstruationPhase.luteal]: STAGES.LUTEAL
};

export const TREATMENT_TYPES = {
  INJECTABLE_CONTRACEPTIVES: "injectable_contraceptives"
};

export const REPORT_SECTION_CONFIG = [
  {
    id: "header",
    component: "menstruation-report-header",
    isVisible: () => true
  },
  {
    id: "mskHealth",
    component: "menstruation-report-msk-health",
    isVisible: () => true
  },
  {
    id: "pain",
    component: "menstruation-report-pain",
    isVisible: vm => vm.hasPain
  },
  {
    id: "muscleStrength",
    component: "menstruation-report-muscle-strength",
    isVisible: () => true
  },
  {
    id: "symptoms",
    component: "menstruation-report-symptoms",
    isVisible: vm => vm.hasSymptoms
  }
];
