<template>
  <div :class="['message  mb-8', { 'is-admin': message.isAdmin }]">
    <message-avatar
      class="message-avatar"
      :display-name="message.sentByUserDisplayName"
    />
    <div class="message-content d-flex flex-column">
      <div class="d-flex" :class="{ 'justify-end': message.isAdmin }">
        <span class="user-name" :class="{ 'is-admin': message.isAdmin }">{{
          messageDisplayName
        }}</span>
      </div>

      <div class="mb-4 text-center text-body-2" v-if="message.subject">
        <strong>{{ message.subject }}</strong>
      </div>
      <div
        :class="{ 'is-admin': message.isAdmin }"
        class="message-bubble"
        v-html="compiledMarkdown"
      ></div>
    </div>
    <message-timestamp
      class="message-timestamp"
      :timestamp="message.timestamp"
      :messageId="message.id"
    />
  </div>
</template>

<script>
import { marked } from "marked";
import { mapGetters } from "vuex";
import MessageAvatar from "@/components/messaging/message/WindowMessageAvatar.vue";
import MessageTimestamp from "@/components/messaging/message/WindowMessageTimestamp.vue";

export default {
  name: "MessagingWindowMessage",
  components: {
    MessageAvatar,
    MessageTimestamp
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["userId"]),
    ...mapGetters("userProfile", ["id", "name", "email"]),
    compiledMarkdown() {
      return marked(this.message.content);
    },
    messageDisplayName() {
      if (this.message.sentByUserId === this.id) {
        return this.name || this.email;
      }

      return this.message.sentByUserId === this.userId
        ? this.$t("messaging.headers.you")
        : this.message.sentByUserDisplayName;
    }
  }
};
</script>

<style scoped lang="scss">
.message {
  display: grid;
  grid-gap: 0.25rem 0.5rem;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "avatar content" ". time";
  max-width: 90%;
  width: fit-content;

  &.is-admin {
    grid-template-areas: "content avatar" "time .";
    margin-left: auto;

    .message-bubble {
      background-color: var(--v-vflNeutralLight1-base);
    }

    .message-timestamp {
      justify-self: end;
    }
  }
}

.message-avatar {
  align-self: end;
  grid-area: avatar;
}

.message-content {
  grid-area: content;
}

.message-timestamp {
  grid-area: time;
}

.message-bubble {
  background-color: var(--v-vflBeigeLight1-base);
  padding: 1rem;
  border-radius: 12px;
  align-self: flex-start;

  .is-admin & {
    align-self: flex-end;
  }

  ::v-deep {
    p {
      font-size: 0.875rem;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.user-name {
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--v-vflTextDark-base);
  margin-bottom: 0.25rem;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.user-name.is-admin {
  text-align: right;
}
</style>
