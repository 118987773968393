import {
  getAssessmentAnswers,
  getCurrentUserMostRecentAssessmentAnswers
} from "@/customApi";
import SchemaService from "@/services/question-schema/schema-service.js";
import { assessmentTypes } from "@/constants/constants.js";
import { getAssessmentResult } from "@/services/modules/api-queries";
import sentry from "@/sentry";
import { getSchema } from "@/services/assessment/get-schema-service";

export async function GetAssessmentResultsForType(
  assessmentId,
  assessmentType
) {
  if (
    assessmentType === assessmentTypes.driverAssessment ||
    assessmentType === assessmentTypes.physicalLabourAssessment ||
    assessmentType === assessmentTypes.menopauseAssessment ||
    assessmentType === assessmentTypes.menstruationAssessment
  ) {
    const schema = getSchema(assessmentType);
    return await GetAssessmentResults(assessmentId, assessmentType, schema);
  }

  return await getAssessmentResult(assessmentId, assessmentType);
}

export async function GetAssessmentResults(
  assessmentId,
  assessmentType,
  schema
) {
  const schemaArray = getSchemaAsArray(schema);
  const results = createBaseResultsObjectFromSchema(schemaArray);
  const answers = await getAssessmentAnswers(assessmentId, assessmentType);
  const allQuestions = schemaArray.flatMap(p =>
    SchemaService.getPageQuestions(p)
  );
  answers.forEach(a => addToResults(a, allQuestions, results));

  return results;
}

export async function getLatestAssessmentResults(assessmentType) {
  if (
    assessmentType !== assessmentTypes.driverAssessment &&
    assessmentType !== assessmentTypes.physicalLabourAssessment &&
    assessmentType !== assessmentTypes.menopauseAssessment &&
    assessmentType !== assessmentTypes.menstruationAssessment
  ) {
    return null;
  }

  const schema = getSchema(assessmentType);
  const schemaArray = getSchemaAsArray(schema);
  const assessment = await getCurrentUserMostRecentAssessmentAnswers(
    assessmentType
  );
  if (!assessment) {
    return null;
  }
  const allQuestions = schemaArray.flatMap(p =>
    SchemaService.getPageQuestions(p)
  );
  const results = createBaseResultsObjectFromSchema(schemaArray);
  assessment.answers.forEach(a => addToResults(a, allQuestions, results));

  return {
    id: assessment.id,
    type: assessment.type,
    answers: results
  };
}

function addToResults(answer, questions, results) {
  const { question, group } = answer;
  const schemaQuestion = questions.find(
    q => q.name === question && q.group === group
  );
  if (!schemaQuestion) {
    sentry.captureMessage(`Cannot find question ${answer.question}`);
    return;
  }

  if (schemaQuestion.theme === "pain") {
    addPainAnswer(schemaQuestion, answer, results);
    return;
  }
  addAnswer(schemaQuestion, results[schemaQuestion.theme], answer.answer);
}

function addPainAnswer(question, answer, results) {
  const { group } = answer;
  const root = results.pain.areas;
  root[group] = root[group] || {};
  addAnswer(question, root[group], answer.answer);
}

function addAnswer(question, root, answer) {
  const { name, fieldType } = question;
  switch (fieldType) {
    case "CheckBoxListQuestion":
    case "CheckBoxPillsQuestion":
    case "SelectBodyAreaQuestion":
    case "CheckBoxCardsQuestion":
      root[name] = root[name] || [];
      root[name].push(answer);
      break;
    case "BMIQuestion":
      // Question removed, do not add to results
      break;
    default:
      root[name] = answer;
      break;
  }
}

function createBaseResultsObjectFromSchema(schema) {
  const results = {};
  schema.forEach(x => {
    results[x.theme] = x.theme === "pain" ? { areas: {} } : {};
  });
  return results;
}

function getSchemaAsArray(schema) {
  return Array.isArray(schema) ? schema : Object.values(schema);
}
