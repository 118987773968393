import * as sentry from "@sentry/vue";
import Vue from "vue";

const isLocalEnvironment = import.meta.env.VITE_NODE_ENV === "development";

let sentryDSN =
  "https://0eec96f904d54acdb7dbc835a09b6c17@o327323.ingest.sentry.io/6170729";

sentry.init({
  Vue: Vue,
  dsn: sentryDSN,
  environment: import.meta.env.VITE_NODE_ENV,
  tracesSampleRate: 1.0,
  logErrors: true,
  release: "Vida-Version@" + import.meta.env.VITE_VERSION
});

const ignoreSentryMessage = function (message) {
  return (
    /The user is not authenticated/i.test(message) ||
    /Could not start video source/i.test(message) ||
    /Failed to allocate videosource/i.test(message) ||
    /The request is not allowed by the user agent or the platform in the current context./i.test(
      message
    )
  );
};

const shapeAndFilter = raw => {
  if (!raw) return null;

  if (raw.message && raw.technicalDetails) {
    return JSON.stringify(raw);
  }

  if (raw.response) {
    let data = raw.response.data ? raw.response.data.error : null;
    if (data) {
      raw.message = data.Message ?? data.message;
      raw.stack = data.StackTraceString ?? data.stackTrace;
    }
  }

  if (ignoreSentryMessage(raw) || ignoreSentryMessage(raw.message)) {
    return null;
  }

  return raw;
};

const shapeRestException = raw => {
  var responseData = raw.response?.data ?? raw.data;
  if (!raw || !responseData) return null;

  if (raw.message) {
    raw.message += " - ";
  }

  if (responseData.error?.message) {
    raw.message = (raw.message || "") + responseData.error.message;
  }

  if (raw.message) {
    raw.message += "\r\n";
  }

  if (
    responseData.error?.technicalDetails &&
    responseData.error.technicalDetails !== responseData.error.message
  ) {
    raw.message =
      (raw.message || "") + `(${responseData.error.technicalDetails})`;
  }

  return raw;
};

const localLog = (type, ...args) => {
  if (isLocalEnvironment) {
    console.group(
      "%c🐛" + type,
      "background: #FCEBEB; color: #000000; padding: 2px 6px; border-radius: 4px;"
    );
    args.forEach(arg => {
      if (typeof arg === "object") {
        console.log("%c📦 Data:", "color: #9580ff", arg);
      } else {
        console.log("%c📝 Message:", "color: #9580ff", arg);
      }
    });
    console.groupEnd();
  }
};

export default {
  setUser: user => {
    sentry.setUser(user);
  },
  captureException: (error, message) => {
    if (message) {
      sentry.captureException(error, { context: message });
      localLog("captureException", error, { context: message });
      return;
    }
    let filtered = shapeAndFilter(error);
    if (filtered !== null) {
      sentry.captureException(filtered);
      localLog("captureException", filtered);
    }
  },
  captureMessage: (message, level) => {
    sentry.captureMessage(message, level);
  },
  captureRestException: errorResponse => {
    let augmented = shapeRestException(errorResponse);
    if (augmented) {
      sentry.captureException(augmented);
      localLog("captureRestException", augmented);
    }
  },
  addBreadcrumb: breadcrumb => {
    sentry.addBreadcrumb(breadcrumb);
  }
};
