<template>
  <div class="vfl-card-light py-4 px-8">
    <img
      :src="illustration"
      aria-hidden="true"
      alt=""
      class="mb-4 illustration"
    />
    <h4 class="text-h6 text-body-darkest mb-4">
      {{ title }}
    </h4>
    <p v-for="(p, index) in text" :key="index">
      {{ p }}
    </p>
    <did-not-know-that-fact-button
      :fact="title"
      class="mt-8"
      :assessmentType="assessmentType"
    />
  </div>
</template>

<script>
import DidNotKnowThatFactButton from "./DidNotKnowThatFactButton.vue";
export default {
  name: "ReportInfoCard",
  components: {
    DidNotKnowThatFactButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    illustration: {
      type: String,
      required: true
    },
    text: {
      type: Array,
      required: true
    },
    assessmentType: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.illustration {
  max-width: 120px;
  width: 100%;
}

.vfl-card-light {
  background: white;
}
</style>
