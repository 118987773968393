<template>
  <vfl-callout
    backgroundColor="vflNeutralLight2"
    icon="Lightbulb"
    :icon-width="26"
  >
    <span
      class="align-self-center text-body-1"
      v-html="$t('menopauseAssessment.report.content.pain.callout.text')"
    ></span>
  </vfl-callout>
</template>

<script>
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";

export default {
  name: "MenopauseReportPainCallout",
  components: { VflCallout }
};
</script>
