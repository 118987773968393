import { BaseTextModule } from "@/components/assessment/common/report/base-text-module.js";
import { HEADER_CONTENT } from "./content/header-content";

export class HeaderModule extends BaseTextModule {
  constructor() {
    super();
    this.content = HEADER_CONTENT;
  }

  getHeaderTitle() {
    return this.content.getTitleText();
  }

  getHeaderContent() {
    return this.content.getContentText();
  }
}
