import RestApiClient from "@/infrastructure/restApiClient.js";

export async function getUserDetails(userId, signal) {
  const path = `/api/user/${userId}/details`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function getUserMskHealth(userId, signal) {
  const path = `/api/user/${userId}/msk-health`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function getUserNotes(userId, signal) {
  const path = `/api/user/${userId}/notes`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function addUserNote(userId, text) {
  const path = `/api/user/${userId}/notes`;
  const body = text;
  return await RestApiClient.post(path, body);
}

export async function deleteUserNote(userId, noteId) {
  const path = `/api/user/${userId}/notes/${noteId}`;
  return await RestApiClient.delete(path);
}

export async function getLatestAssessments(userId, signal) {
  const path = `/api/user/${userId}/assessments/latest`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function getAssessmentHistory(userId, signal) {
  const path = `/api/user/${userId}/assessments/history`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function getRisksForLocation(userId, location, signal) {
  const path = `/api/user/${userId}/risks/${location}`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function getRecommendationInteractions(userId, signal) {
  const path = `/api/user/${userId}/recommendations/interactions`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function getUserAccountHistory(userId, signal) {
  const path = `/api/user/${userId}/account/history`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function getPainHistory(userId, signal) {
  const path = `/api/user/${userId}/pain/history`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function getNeedsAttentionState(userId, signal) {
  const path = `/api/user/${userId}/needs-attention`;
  return await RestApiClient.getWithSignal(path, signal);
}

export async function updateUserNeedsAttentionState(userId, action) {
  const path = `/api/user/${userId}/needs-attention`;
  const body = action;
  return await RestApiClient.post(path, body);
}

export async function getUserNeedsAttentionHistory(userId) {
  const path = `/api/user/${userId}/needs-attention/history`;
  return await RestApiClient.get(path);
}

export async function getPainImpactQuestionVersions(userId, area) {
  const path = `/api/user/${userId}/pain/impact-follow-up/${area}`;
  return await RestApiClient.get(path);
}

export async function sendAdminMessageToUser(userId, message) {
  const path = `/api/user/${userId}/message`;
  const body = message;
  return await RestApiClient.post(path, body);
}

export async function getAdminConversationWithUserMessages(userId) {
  const path = `/api/user/${userId}/message`;
  return await RestApiClient.get(path);
}

export async function saveTimeZone(userId, timeZoneId) {
  const path = `/api/user/${userId}/details/time-zone`;
  const body = {
    timeZoneId: timeZoneId
  };
  return await RestApiClient.patch(path, body);
}