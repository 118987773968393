<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M119.517 299.795L139.043 308.116L133.001 315.766L159.603 322.885L142.132 314.647L148.618 306.515L123.728 294.705L119.517 299.795Z"
      fill="#F2575D"
    />
    <path
      d="M157.008 281.257L165.413 295.657L158.019 297.645L171.444 314.604L164.297 301.22L172.197 299.137L162.049 280.024L157.008 281.257Z"
      fill="#F2575D"
    />
    <path
      d="M122.888 334.927L137.494 334.829L135.776 341.314L154.519 338.52L141.233 338.107L143.1 331.196L124.152 330.56L122.888 334.927Z"
      fill="#F2575D"
    />
  </svg>
</template>

<script>
export default {
  name: "MusclePainOverlay"
};
</script>
