<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{ sectionTitle }}</template>
    <template #content>
      <div class="vfl-card-light py-4 px-8 pb-12 align-center d-md-flex">
        <img
          src="/images/illustrations/muscle-strength-decrease.svg"
          alt=""
          aria-hidden="true"
        />
        <div class="content">
          <p v-for="p in content" :key="p">{{ p }}</p>
          <did-not-know-that-fact-button
            class="mt-8"
            :fact="sectionTitle"
            :assessmentType="$options.assessmentTypes.menstruationAssessment"
          />
        </div>
      </div>
      <recommendation-container
        class="mt-10"
        :title="
          $t(
            'menstruationAssessment.report.recommendations.muscleStrength.title'
          )
        "
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="muscleStrengthRecommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import DidNotKnowThatFactButton from "@/components/assessment/common/report/DidNotKnowThatFactButton.vue";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";
import { assessmentTypes } from "@/constants/constants.js";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/menstruation/report/recommendations/recommendation-service.js";

export default {
  name: "MenstruationReportMuscleStrength",
  components: {
    DidNotKnowThatFactButton,
    ReportSection,
    RecommendationContainer
  },
  data() {
    return { muscleStrengthRecommendations: {} };
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.muscleStrengthRecommendations = getRecommendationsByType(
      RecommendationSections.MUSCLE_STRENGTH,
      this.results
    );
  },
  computed: {
    sectionTitle() {
      return this.$t(
        "menstruationAssessment.report.content.muscleStrength.title"
      );
    },
    content() {
      return this.$t(
        "menstruationAssessment.report.content.muscleStrength.text"
      );
    }
  }
};
</script>

<style scoped lang="scss">
.vfl-card-light {
  background: white;
  gap: 1rem;
}
</style>
