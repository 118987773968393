import {
  getUserDetails,
  getUserMskHealth,
  getLatestAssessments,
  getUserNotes,
  deleteUserNote,
  addUserNote,
  getRisksForLocation,
  getNeedsAttentionState
} from "@/api/userApi.js";
import { getUnifiedHistory } from "@/components/user-profile/history/unifiedHistoryService.js";
import { irrelevantRisks } from "@/components/common/assessment-history/userHistoryFilterService.js";
import { deleteAssessment } from "@/customApi.js";
import sentry from "@/sentry.js";
import { NEEDS_ATTENTION_STATE } from "@/components/user-profile/constants.js";
import i18n from "@/plugins/i18n.js";

const fetchActions = {
  userDetails: {
    apiCall: getUserDetails,
    commitType: "SET_USER_DETAILS"
  },
  mskDetails: {
    apiCall: getUserMskHealth,
    commitType: "SET_MSK_DETAILS"
  },
  assessments: {
    apiCall: getLatestAssessments,
    commitType: "SET_LATEST_ASSESSMENTS"
  },
  notes: {
    apiCall: getUserNotes,
    commitType: "SET_NOTES"
  },
  officeRisks: {
    apiCall: getRisksForLocation,
    commitType: "SET_OFFICE_RISKS",
    path: "Office"
  },
  homeRisks: {
    apiCall: getRisksForLocation,
    commitType: "SET_HOME_RISKS",
    path: "Home"
  },
  history: {
    apiCall: getUnifiedHistory,
    commitType: "SET_HISTORY"
  },
  needsAttention: {
    apiCall: getNeedsAttentionState,
    commitType: "SET_NEEDS_ATTENTION"
  }
};

const getInitialState = () => ({
  id: null,
  name: null,
  email: null,
  tags: null,
  pain: null,
  mskRisks: null,
  healthConditions: null,
  assessments: {
    all: null,
    latest: null
  },
  notes: null,
  officeRisks: null,
  homeRisks: null,
  history: null,
  needsAttention: null,
  deletingAssessmentId: null,
  viewingAssessmentId: null,
  currentViewedAssessment: null,
  currentAssessmentResults: null,
  isSavingNote: false,
  isDeletingNote: false,
  isDownloadingReport: false,
  snackbar: {
    show: false,
    message: "",
    type: "success",
    undoAction: null
  },
  loadingStates: {
    userDetails: false,
    mskDetails: false,
    assessments: false,
    notes: false,
    officeRisks: false,
    homeRisks: false,
    history: false,
    needsAttention: false
  },
  errors: {
    userDetails: null,
    mskDetails: null,
    assessments: null,
    notes: null,
    officeRisks: null,
    homeRisks: null,
    history: null,
    needsAttention: null
  },
  currentControllers: {
    userDetails: null,
    mskDetails: null,
    assessments: null,
    notes: null,
    officeRisks: null,
    homeRisks: null,
    history: null,
    needsAttention: null
  }
});

export const userProfileStore = {
  namespaced: true,
  state: {
    ...getInitialState(),
    requestVersion: 0
  },
  mutations: {
    INCREMENT_REQUEST_VERSION(state) {
      state.requestVersion += 1;
    },
    SET_USER_ID(state, id) {
      state.id = id;
    },
    SET_USER_DETAILS(state, details) {
      state.name = details?.name;
      state.email = details?.email;
      state.tags = details?.tags;
    },
    SET_MSK_DETAILS(state, details) {
      state.healthConditions = details.healthConditions;
      state.pain = details.painAreas;
      state.mskRisks = details.risks;
    },
    SET_LATEST_ASSESSMENTS(state, assessments) {
      state.assessments.latest = assessments;
    },
    SET_ALL_ASSESSMENTS(state, assessments) {
      state.assessments.all = assessments;
    },
    SET_NOTES(state, notes) {
      // noteType 'User' is for general notes.
      // Other types (e.g. adjustments) may be added at a later date
      const filteredNotes = notes.filter(x => x.noteType === "User");

      state.notes = filteredNotes;
    },
    ADD_NOTE(state, note) {
      state.notes = [note, ...state.notes];
    },
    DELETE_NOTE(state, noteId) {
      state.notes = state.notes.filter(note => note.id !== noteId);
    },
    SET_OFFICE_RISKS(state, risks) {
      const relevantRisks = risks.filter(
        risk => !irrelevantRisks.includes(risk.name)
      );
      state.officeRisks = relevantRisks;
    },
    SET_HOME_RISKS(state, risks) {
      const relevantRisks = risks.filter(
        risk => !irrelevantRisks.includes(risk.name)
      );
      state.homeRisks = relevantRisks;
    },
    SET_HISTORY(state, history) {
      state.history = history.everything;
      state.assessments.all = history.assessments;
    },
    SET_NEEDS_ATTENTION(state, needsAttention) {
      state.needsAttention =
        needsAttention.state === NEEDS_ATTENTION_STATE.NEEDS_ATTENTION;
    },
    SET_LOADING(state, { key, isLoading }) {
      state.loadingStates[key] = isLoading;
    },
    SET_DELETING_ASSESSMENT_ID(state, id) {
      state.deletingAssessmentId = id;
    },
    SET_VIEWING_ASSESSMENT_ID(state, id) {
      state.viewingAssessmentId = id;
    },
    SET_CURRENT_VIEWED_ASSESSMENT(state, assessment) {
      state.currentViewedAssessment = assessment;
    },
    SET_CURRENT_ASSESSMENT_RESULTS(state, results) {
      state.currentAssessmentResults = results;
    },
    SET_SAVING_NOTE(state, isSaving) {
      state.isSavingNote = isSaving;
    },
    SET_DELETING_NOTE(state, isDeleting) {
      state.isDeletingNote = isDeleting;
    },
    SET_ERROR(state, { key, error }) {
      state.errors[key] = error;
    },
    SET_CONTROLLER(state, { key, controller }) {
      state.currentControllers[key] = controller;
    },
    RESET_STATE(state) {
      Object.assign(state, getInitialState());
    },
    SET_SNACKBAR(state, { show, message, type, undoAction }) {
      state.snackbar = { show, message, type, undoAction };
    },
    SET_DOWNLOADING_REPORT(state, isDownloading = false) {
      state.isDownloadingReport = isDownloading;
    }
  },
  actions: {
    setUserId({ commit, dispatch }, userId) {
      dispatch("reset");

      commit("SET_USER_ID", userId);
    },
    async getUserData({ commit, dispatch, state }, userId) {
      commit("INCREMENT_REQUEST_VERSION");

      const fetchPromises = Object.keys(fetchActions).map(key =>
        dispatch("fetchData", { key, userId })
      );

      await Promise.all(fetchPromises);
    },
    async fetchData({ commit, state }, { key, userId }) {
      const controller = new AbortController();
      const currentVersion = state.requestVersion;

      commit("SET_CONTROLLER", { key, controller });
      commit("SET_LOADING", { key, isLoading: true });
      commit("SET_ERROR", { key, error: null });

      try {
        const { apiCall, commitType, path } = fetchActions[key];

        const data = path
          ? await apiCall(userId, path, controller.signal)
          : await apiCall(userId, controller.signal);

        if (currentVersion === state.requestVersion) {
          commit(commitType, data);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          commit("SET_ERROR", { key, error });
        }
      } finally {
        if (currentVersion === state.requestVersion) {
          commit("SET_CONTROLLER", { key, controller: null });
          commit("SET_LOADING", { key, isLoading: false });
        }
      }
    },
    async deleteNote({ commit, dispatch, state }, { userId, noteId }) {
      if (state.isDeletingNote) return;

      commit("SET_DELETING_NOTE", true);

      try {
        await deleteUserNote(userId, noteId);
        commit("DELETE_NOTE", noteId);
        dispatch("showSnackbar", {
          message: i18n.t("userProfile.notes.snackbar.deleting.success"),
          type: "success"
        });
      } catch (error) {
        commit("SET_ERROR", { key: "notes", error });
        dispatch("showSnackbar", {
          message: i18n.t("userProfile.notes.snackbar.deleting.error"),
          type: "error"
        });
      } finally {
        commit("SET_DELETING_NOTE", false);
      }
    },
    async addNote({ commit, dispatch, state }, { userId, note }) {
      if (state.isSavingNote) return;

      commit("SET_SAVING_NOTE", true);

      try {
        const newNote = await addUserNote(userId, note);
        commit("ADD_NOTE", newNote);
        dispatch("showSnackbar", {
          message: i18n.t("userProfile.notes.snackbar.adding.success"),
          type: "success"
        });
      } catch (error) {
        commit("SET_ERROR", { key: "notes", error });
        dispatch("showSnackbar", {
          message: i18n.t("userProfile.notes.snackbar.adding.error"),
          type: "error"
        });
      } finally {
        commit("SET_SAVING_NOTE", false);
      }
    },
    async deleteAssessment({ commit, dispatch, state }, id) {
      if (!id) return;
      if (state.deletingAssessmentId) return;

      commit("SET_DELETING_ASSESSMENT_ID", id);

      try {
        await deleteAssessment(id);
        const latestAssessments = state.assessments.latest.filter(
          assessment => assessment.id !== id
        );
        const allAssessments = state.assessments.all.filter(
          assessment => assessment.id !== id
        );

        commit("SET_LATEST_ASSESSMENTS", latestAssessments);
        commit("SET_ALL_ASSESSMENTS", allAssessments);

        dispatch("showSnackbar", {
          message: i18n.t("userProfile.assessments.snackbar.delete.success"),
          type: "success"
        });
      } catch (e) {
        dispatch("showSnackbar", {
          message: i18n.t("userProfile.assessments.snackbar.delete.error"),
          type: "error"
        });

        sentry.captureMessage("Error deleting assessment", e);
      } finally {
        commit("SET_DELETING_ASSESSMENT_ID", null);
      }
    },
    async viewAssessment({ state, dispatch }, assessment) {
      if (state.viewingAssessmentId) return;

      try {
        const assessmentAndResults = await dispatch(
          "loadAssessment",
          assessment
        );
        await dispatch("openReportDialog", assessmentAndResults);
      } catch (err) {
        sentry.captureMessage("Error viewing assessment", err);
      }
    },
    async loadAssessment({ commit, state }, assessment) {
      commit("SET_VIEWING_ASSESSMENT_ID", assessment.id);

      try {
        const { GetAssessmentResultsForType } = await import(
          "@/components/common/assessments/get-assessment-service.js"
        );

        const assessmentResults = await GetAssessmentResultsForType(
          assessment.id,
          assessment.assessmentType
        );

        const viewedAssessment = {
          ...assessment,
          lastUpdatedAt: assessment.lastUpdatedAt ?? assessment.completedAt,
          email: state.email,
          name: state.name,
          assessmentType: assessment.assessmentType,
          id: assessment.id
        };

        return {
          assessment: viewedAssessment,
          results: assessmentResults
        };
      } catch (err) {
        sentry.captureMessage("Error viewing assessment", err);
      }
    },
    async openReportDialog({ commit }, assessmentAndResults) {
      commit("SET_CURRENT_VIEWED_ASSESSMENT", assessmentAndResults.assessment);
      commit("SET_CURRENT_ASSESSMENT_RESULTS", assessmentAndResults.results);
    },
    resetViewedAssessment({ commit }) {
      commit("SET_VIEWING_ASSESSMENT_ID", null);
      commit("SET_CURRENT_VIEWED_ASSESSMENT", null);
      commit("SET_CURRENT_ASSESSMENT_RESULTS", null);
    },
    reset({ state, commit }) {
      // Abort any current requests before resetting state
      Object.values(state.currentControllers).forEach(controller => {
        if (controller) {
          controller.abort();
        }
      });
      commit("RESET_STATE");
    },
    showSnackbar({ commit }, { message, type, undoAction }) {
      commit("SET_SNACKBAR", { show: true, message, type, undoAction });
    },
    hideSnackbar({ commit }) {
      commit("SET_SNACKBAR", {
        show: false,
        message: "",
        type: "success",
        undoAction: null
      });
    },
    setNeedsAttention({ commit }, needsAttention) {
      const state = needsAttention
        ? NEEDS_ATTENTION_STATE.NEEDS_ATTENTION
        : NEEDS_ATTENTION_STATE.NONE;
      commit("SET_NEEDS_ATTENTION", { state });
    },
    closeUserAssessmentReport({ dispatch }) {
      dispatch("resetViewedAssessment");
    },
    async downloadReport({ commit, dispatch }, assessment) {
      commit("SET_DOWNLOADING_REPORT", true);
      await dispatch("viewAssessment", assessment);
    }
  },
  getters: {
    loadingStates: state => state.loadingStates,
    id: state => state.id,
    name: state => state.name,
    email: state => state.email,
    tags: state => state.tags,
    healthConditions: state => state.healthConditions,
    pain: state => state.pain,
    mskRisks: state => state.mskRisks,
    latestAssessments: state => state.assessments.latest,
    allAssessments: state => state.assessments.all,
    notes: state => state.notes,
    officeRisks: state => state.officeRisks,
    homeRisks: state => state.homeRisks,
    hasRisks: state => state.homeRisks?.length || state.officeRisks?.length,
    allDataLoaded: state =>
      Object.values(state.loadingStates).every(v => v === false),
    history: state => state.history,
    deletingAssessmentId: state => state.deletingAssessmentId,
    currentViewedAssessment: state => state.currentViewedAssessment,
    currentAssessmentResults: state => state.currentAssessmentResults,
    isSavingNote: state => state.isSavingNote,
    isDeletingNote: state => state.isDeletingNote,
    snackbar: state => state.snackbar,
    needsAttention: state => state.needsAttention,
    showUserAssessmentReport: state => state.viewingAssessmentId !== null,
    isDownloadingReport: state => state.isDownloadingReport
  }
};
