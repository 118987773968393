<template>
  <div id="average-scores-container" class="d-flex flex-column">
    <average-scores-header
      class="mb-4"
      @onImageClonedForDownload="onImageClonedForDownload"
    />

    <vfl-alert
      v-if="error && !averageAssessmentScoresLoading"
      :message="$t('averageScoresOverview.error')"
      type="error"
      is-light
    />

    <div
      v-if="averageAssessmentScoresLoading"
      class="mr-4 d-flex pb-4"
      style="gap: 1rem"
    >
      <v-skeleton-loader
        v-for="i in visibleAssessmentTypesCount"
        :key="i"
        type="image"
        class="average-scores-loader"
      />
    </div>

    <average-score-cards v-else :cards="processedData" />
  </div>
</template>

<script>
import VflAlert from "@/components/vitrue-foundation-library/VflAlert.vue";
import AverageScoresHeader from "./AverageScoresHeader.vue";
import AverageScoreCards from "./AverageScoreCards.vue";
import { getCard } from "@/components/dashboard/cards/average-scores/cards.service.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { assessmentTypes } from "@/constants/constants.js";

export default {
  name: "AverageScores",
  components: {
    AverageScoresHeader,
    AverageScoreCards,
    VflAlert
  },
  data() {
    return {
      error: false
    };
  },
  computed: {
    ...mapGetters([
      "driverAssessmentEnabled",
      "hideBurnoutCompletely",
      "hidePhysicalLabourFromAdminDashboard",
      "physicalLabourAssessmentEnabled",
      "demoFeatures"
    ]),
    ...mapGetters("adminDashboard", [
      "dashboardDemoMetricsState",
      "usePredefinedDashboardMetrics",
      "averageAssessmentScores",
      "averageAssessmentScoresLoading",
      "filterTagsIds"
    ]),
    usePredefinedMetrics() {
      return this.demoFeatures && this.usePredefinedDashboardMetrics;
    },
    showPhysicalLabour() {
      return (
        this.physicalLabourAssessmentEnabled &&
        !this.hidePhysicalLabourFromAdminDashboard
      );
    },
    visibleAssessmentTypes() {
      return {
        [assessmentTypes.deskAssessment]: true, // Always show desk assessment
        [assessmentTypes.driverAssessment]: this.driverAssessmentEnabled,
        [assessmentTypes.burnoutAssessment]: !this.hideBurnoutCompletely,
        [assessmentTypes.physicalLabourAssessment]: this.showPhysicalLabour
      };
    },
    processedData() {
      // Get list of assessment types that are currently enabled
      const enabledAssessmentTypes = Object.keys(
        this.visibleAssessmentTypes
      ).filter(type => this.visibleAssessmentTypes[type]);

      const cards = [];

      // Handle desk assessments first as we need to check on location
      if (enabledAssessmentTypes.includes(assessmentTypes.deskAssessment)) {
        const locations = ["Office", "Home"];

        const deskCards = locations.map(location => {
          const deskData = this.averageAssessmentScores?.find(
            data =>
              data.assessmentType === assessmentTypes.deskAssessment &&
              data.assessmentLocation === location
          );

          return getCard({
            assessmentType: assessmentTypes.deskAssessment,
            assessmentLocation: location,
            averageScore: null,
            industryAverage: null,
            ...deskData, // order is important
            peopleAssessed: this.usePredefinedMetrics
              ? this.getDemoMetricCount()
              : deskData?.peopleAssessed || 0
          });
        });

        cards.push(...deskCards);
      }

      const orderedTypes = [
        assessmentTypes.driverAssessment,
        assessmentTypes.physicalLabourAssessment,
        assessmentTypes.burnoutAssessment
      ];

      // Add the other cards in a specific order
      orderedTypes.forEach(assessmentType => {
        if (enabledAssessmentTypes.includes(assessmentType)) {
          const assessmentData = this.averageAssessmentScores?.find(
            data => data.assessmentType === assessmentType
          );

          const card = getCard({
            assessmentType,
            assessmentLocation: null,
            averageScore: null,
            industryAverage: null,
            ...assessmentData, // order is important
            peopleAssessed: this.usePredefinedMetrics
              ? this.getDemoMetricCount()
              : assessmentData?.peopleAssessed || 0
          });

          cards.push(card);
        }
      });

      // Sort the cards by peopleAssessed
      return cards.sort((a, b) => {
        // If both cards have peopleAssessed data, sort by descending order
        if (a.peopleAssessed && b.peopleAssessed) {
          return b.peopleAssessed - a.peopleAssessed;
        }

        // If only one card has peopleAssessed data, put the one with data first
        if (a.peopleAssessed && !b.peopleAssessed) return -1;
        if (!a.peopleAssessed && b.peopleAssessed) return 1;

        // If neither has peopleAssessed data, maintain original order
        // Find original index of each card type
        const originalOrder = [
          "deskAssessment-Office",
          "deskAssessment-Home",
          "driverAssessment",
          "physicalLabourAssessment",
          "burnoutAssessment"
        ];

        const aKey = a.assessmentLocation
          ? `${a.assessmentType}-${a.assessmentLocation}`
          : a.assessmentType;
        const bKey = b.assessmentLocation
          ? `${b.assessmentType}-${b.assessmentLocation}`
          : b.assessmentType;

        return originalOrder.indexOf(aKey) - originalOrder.indexOf(bKey);
      });
    },
    visibleAssessmentTypesCount() {
      return Object.values(this.visibleAssessmentTypes).filter(Boolean).length;
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    filterTagsIds() {
      this.getData(this.filterTagsIds);
    }
  },
  methods: {
    ...mapMutations("adminDashboard", [
      "SET_AVERAGE_ASSESSMENT_SCORES_LOADING"
    ]),
    ...mapActions("adminDashboard", ["getAverageAssessmentScoresCardData"]),
    async getData(filterTagsIds) {
      try {
        await this.getAverageAssessmentScoresCardData(filterTagsIds);
      } catch (ex) {
        this.$logger.captureException(ex);
        this.error = true;
      } finally {
        this.SET_AVERAGE_ASSESSMENT_SCORES_LOADING(false);
      }
    },
    onImageClonedForDownload(clonedDocument) {
      const scrollerContainer = clonedDocument.querySelector(
        ".score-cards-container"
      );

      if (scrollerContainer) {
        scrollerContainer.classList.add("is-downloading-score-cards-image");
      }
    },
    getDemoMetricCount() {
      const metrics = this.dashboardDemoMetricsState;
      return metrics.totalCount;
    }
  }
};
</script>

<style scoped lang="scss">
.average-scores-loader {
  ::v-deep {
    > div {
      height: 150px;
      width: 278px;
    }
  }
}
</style>
