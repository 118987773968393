<template>
  <v-tooltip
    v-model="tooltipVisible"
    ref="tooltip"
    :top="top"
    :bottom="bottom"
    color="transparent"
    :open-on-click="openOnClick"
    :open-on-hover="openOnHover"
    :open-delay="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>
    <div ref="tooltipContent">
      <slot name="content">
        <span class="default-tooltip text-body-2">{{ text }}</span>
      </slot>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: "vfl-tooltip",
  data() {
    return { tooltipVisible: false, ignoreClose: false };
  },
  props: {
    top: Boolean,
    bottom: Boolean,
    openOnHover: {
      type: Boolean,
      default: true
    },
    openOnClick: Boolean,
    text: {
      type: String
    }
  },
  methods: {
    handleClickOutside(event) {
      if (!this.openOnClick) {
        return;
      }

      if (this.ignoreClose) {
        this.ignoreClose = false;
        return;
      }

      const tooltip = this.$refs.tooltip;
      const tooltipContent = this.$refs.tooltipContent;

      if (tooltip && tooltipContent && !tooltipContent.contains(event.target)) {
        this.tooltipVisible = false;
      }
    },
    showTooltip() {
      this.tooltipVisible = true;
      this.ignoreClose = true;
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>

<style scoped lang="scss">
.v-tooltip__content {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.default-tooltip {
  background-color: var(--v-vflGreenDark1-base) !important;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: white;
  max-width: 400px;
  padding: 0.25rem 0.5rem;
}
</style>
