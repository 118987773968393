<template>
  <div
    class="messaging-window pt-8 py-4 px-4 vfl-scrollbar-vertical"
    ref="messageContainer"
  >
    <div class="loading-container" v-if="isLoading">
      <v-progress-circular
        color="vflNeutralLight2"
        width="5"
        indeterminate
        size="64"
      />
    </div>
    <messaging-empty-state v-else-if="!messages?.length" />
    <template v-else>
      <messaging-window-message
        v-for="message in messages"
        :key="message.id"
        :message="message"
    /></template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MessagingWindowMessage from "@/components/messaging/message/WindowMessage.vue";
import MessagingEmptyState from "@/components/messaging/layout/MessagingEmptyState.vue";

export default {
  name: "MessagingWindow",
  components: {
    MessagingEmptyState,
    MessagingWindowMessage
  },
  computed: {
    ...mapState("messagingStore", ["messages", "isLoading"])
  },
  watch: {
    messages: {
      handler() {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      },
      deep: true
    }
  },
  methods: {
    scrollToBottom() {
      const container = this.$refs.messageContainer;
      container.scrollTop = container.scrollHeight;
    }
  },
  mounted() {
    this.scrollToBottom();
  }
};
</script>

<style scoped lang="scss">
.messaging-window {
  flex: 1;
  overflow-y: auto;
}

.loading-container {
  display: grid;
  height: 100%;
  place-content: center;
}
</style>
