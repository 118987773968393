/**
 * Gets unique stepper groups from steps
 */
function getUniqueStepperGroups(stepperSteps) {
  const allGroups = stepperSteps
    .map(([_, step]) => step.STEPPER_GROUP)
    .filter(group => group);

  return [...new Set(allGroups)];
}

/**
 * Gets the main step for a specific group
 */
function getMainStepForGroup(stepperSteps, group) {
  return stepperSteps.find(([_, step]) => step.STEPPER_GROUP === group)[1];
}

/**
 * Counts how many steps are in a group
 */
function countStepsInGroup(stepperSteps, group) {
  return stepperSteps.filter(([_, step]) => step.STEPPER_GROUP === group)
    .length;
}

/**
 * Creates step structure from steps and groups
 */
function buildStepStructure(stepperSteps, stepperGroups) {
  return stepperGroups.map(group => {
    const mainStep = getMainStepForGroup(stepperSteps, group);
    const stepsInGroup = countStepsInGroup(stepperSteps, group);

    return {
      mainStep: mainStep.THEME,
      hasSubstep: stepsInGroup > 1
    };
  });
}

/**
 * Builds complete stepper structure
 */
export function buildStepperStructure(steps) {
  const stepperSteps = Object.entries(steps).filter(
    ([_, step]) => step.STEPPER
  );

  const stepperGroups = getUniqueStepperGroups(stepperSteps);

  return buildStepStructure(stepperSteps, stepperGroups);
}

/**
 * Gets current stepper index
 */
export function getCurrentStepperIndex(currentTheme, stepStructure, steps) {
  const currentStep = Object.values(steps).find(
    step => step.THEME === currentTheme
  );
  if (!currentStep?.STEPPER_GROUP) return 1;

  const mainStepForGroup = stepStructure.find(
    step => step.mainStep === currentStep.STEPPER_GROUP
  )?.mainStep;

  const groupIndex = stepStructure.findIndex(
    step => step.mainStep === mainStepForGroup
  );

  return groupIndex + 1;
}
