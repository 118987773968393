<template>
  <div class="graph-container">
    <svg
      viewBox="0 0 652 400"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <!-- Dynamic highlight region -->
        <rect
          :x="config.x"
          y="9"
          :width="config.width"
          height="315"
          fill="#FDEBD8"
        />

        <!-- Phase label -->
        <text
          fill="#2A3C3C"
          :x="config.textX"
          y="23.362"
          style="font-family: Manrope; font-size: 14px; font-weight: 500"
        >
          {{
            $t(
              `menstruationAssessment.report.content.mskHealth.graphs.stages.${config.text}`
            )
          }}
        </text>

        <!-- Drop -->
        <path
          v-if="isPeriodStage"
          d="M143.636 38.6325L150 32.2686L156.364 38.6325C159.879 42.1472 159.879 47.8457 156.364 51.3604C152.849 54.8751 147.151 54.8751 143.636 51.3604C140.121 47.8457 140.121 42.1472 143.636 38.6325Z"
          fill="#F43A6D"
        />

        <!-- flag -->
        <rect
          v-if="isOvulationStage"
          x="360"
          y="32"
          width="2"
          height="60"
          rx="1"
          fill="#936B2E"
        />
        <path
          v-if="isOvulationStage"
          d="M388 34.4023H358V54.4023H388L380.414 44.4023L388 34.4023Z"
          fill="#005F61"
        />

        <!-- Graph lines -->
        <path
          d="M97 305.5C124.667 306.667 189.9 301.4 229.5 271C269.1 240.6 294.333 193.667 302 174C306 165.667 315.6 154 322 174C330 199 345.5 266 355.5 281.5C365.5 297 370 300 394 285.5C418 271 415 269.5 432.5 259.5C450 249.5 477 237.5 509 259.5C541 281.5 551 298 593.5 305.5"
          stroke="#005F61"
          stroke-width="5"
        />

        <path
          d="M98.5 312.999C133.333 312.999 211.1 312.999 243.5 312.999C284 312.999 345 316.499 419.5 226.499C477.858 156 511.04 178.999 520 190.499C553.5 233.498 562 283.499 595 300.499"
          stroke="#B15E00"
          stroke-width="5"
        />

        <!-- Axes -->
        <path
          d="M95.5 325L95 325L95 325.5L95.5 325.5V325ZM95.8535 8.64642C95.6583 8.45114 95.3417 8.45114 95.1464 8.64642L91.9645 11.8284C91.7692 12.0237 91.7692 12.3402 91.9645 12.5355C92.1597 12.7308 92.4763 12.7308 92.6716 12.5355L95.5 9.70706L98.3284 12.5355C98.5237 12.7308 98.8403 12.7308 99.0355 12.5355C99.2308 12.3402 99.2308 12.0237 99.0355 11.8284L95.8535 8.64642ZM588.354 325.354C588.549 325.158 588.549 324.842 588.354 324.646L585.172 321.464C584.976 321.269 584.66 321.269 584.464 321.464C584.269 321.66 584.269 321.976 584.464 322.172L587.293 325L584.464 327.828C584.269 328.024 584.269 328.34 584.464 328.536C584.66 328.731 584.976 328.731 585.172 328.536L588.354 325.354ZM96 325L96 8.99997L95 8.99997L95 325L96 325ZM95.5 325.5L588 325.5L588 324.5L95.5 324.5V325.5Z"
          fill="#5F7B7C"
        />

        <!-- Y-axis label -->
        <text
          transform="matrix(0 -1 1 0 55 309)"
          fill="#2A3C3C"
          style="font-family: Manrope; font-size: 14px; font-weight: 500"
        >
          <tspan x="0" y="14.362">
            {{
              $t(
                "menstruationAssessment.report.content.mskHealth.graphs.stages.hormoneLevelsLabel"
              )
            }}
          </tspan>
        </text>

        <!-- X-axis labels -->
        <text
          fill="#5F7B7C"
          xml:space="preserve"
          style="white-space: pre"
          font-family="Manrope"
          font-size="14"
          font-weight="500"
          letter-spacing="0px"
        >
          <tspan x="443" y="344.362">
            {{
              $t(
                `menstruationAssessment.report.content.mskHealth.graphs.stages.luteal`
              )
            }}
          </tspan>
        </text>
        <text
          fill="#5F7B7C"
          xml:space="preserve"
          style="white-space: pre"
          font-family="Manrope"
          font-size="14"
          font-weight="500"
          letter-spacing="0px"
        >
          <tspan x="333" y="344.362">
            {{
              $t(
                `menstruationAssessment.report.content.mskHealth.graphs.stages.ovulation`
              )
            }}
          </tspan>
        </text>
        <text
          fill="#5F7B7C"
          xml:space="preserve"
          style="white-space: pre"
          font-family="Manrope"
          font-size="14"
          font-weight="500"
          letter-spacing="0px"
        >
          <tspan x="208" y="343.362">
            {{
              $t(
                `menstruationAssessment.report.content.mskHealth.graphs.stages.follicular`
              )
            }}
          </tspan>
        </text>
        <text
          fill="#5F7B7C"
          xml:space="preserve"
          style="white-space: pre"
          font-family="Manrope"
          font-size="14"
          font-weight="500"
          letter-spacing="0px"
        >
          <tspan x="128" y="343.362">
            {{
              $t(
                `menstruationAssessment.report.content.mskHealth.graphs.stages.period`
              )
            }}
          </tspan>
        </text>
        <line
          opacity="0.1"
          x1="202.5"
          y1="9"
          x2="202.5"
          y2="324"
          stroke="black"
        />
        <line
          opacity="0.1"
          x1="342.5"
          y1="9"
          x2="342.5"
          y2="324"
          stroke="black"
        />
        <line
          opacity="0.1"
          x1="382.5"
          y1="9"
          x2="382.5"
          y2="324"
          stroke="black"
        />
        <line
          opacity="0.1"
          x1="579.5"
          y1="9"
          x2="579.5"
          y2="324"
          stroke="black"
        />

        <circle cx="196" cy="379" r="6" fill="#005F61" />
        <text
          fill="#2A3C3C"
          xml:space="preserve"
          style="white-space: pre"
          font-family="Manrope"
          font-size="14"
          font-weight="500"
          letter-spacing="0px"
        >
          <tspan x="210" y="384.362">
            {{
              $t(
                "menstruationAssessment.report.content.mskHealth.graphs.stages.oestrogenLabel"
              )
            }}
          </tspan>
        </text>

        <circle cx="352" cy="379" r="6" fill="#B15E00" />
        <text
          fill="#2A3C3C"
          xml:space="preserve"
          style="white-space: pre"
          font-family="Manrope"
          font-size="14"
          font-weight="500"
          letter-spacing="0px"
        >
          <tspan x="366" y="384.362">
            {{
              $t(
                "menstruationAssessment.report.content.mskHealth.graphs.stages.progesteroneLabel"
              )
            }}
          </tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import { STAGES } from "@/components/assessment/menstruation/report/constants/index.js";
const Config = {
  [STAGES.PERIOD]: {
    x: 95,
    width: 108,
    text: "period",
    textX: 128
  },
  [STAGES.FOLLICULAR]: {
    x: 203,
    width: 140,
    text: "follicular",
    textX: 223
  },
  [STAGES.OVULATION]: {
    x: 343,
    width: 39,
    text: "ovulation",
    textX: 330
  },
  [STAGES.LUTEAL]: {
    x: 383,
    width: 196,
    text: "luteal",
    textX: 439
  }
};

export default {
  name: "MenstruationStageGraph",
  props: {
    stage: {
      type: String,
      default: STAGES.PERIOD
    }
  },
  computed: {
    config() {
      return Config[this.stage];
    },
    isPeriodStage() {
      return this.stage === STAGES.PERIOD;
    },
    isOvulationStage() {
      return this.stage === STAGES.OVULATION;
    }
  }
};
</script>

<style scoped lang="scss">
.graph-container {
  position: relative;
  width: 100%;
  aspect-ratio: 652 / 400; // original svg dimensions
}
</style>
