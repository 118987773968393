import RestApiClient from "@/infrastructure/restApiClient.js";

  export async function getTeamRisksForCurrentUser() {
    const path = `/api/team/risks`;
    return await RestApiClient.get(path);
  }

export async function getTeamRisksForCurrentUserWithTags(tagIds) {
  const path = `/api/team/risks`;
  const body = { tagIds };
  return await RestApiClient.post(path, body);
}

export async function getTeamPainDistribution() {
  const path = `/api/users/pain/distribution`;
  return await RestApiClient.get(path);
}

export async function getTeamPainDistributionWithTags(tagIds) {
  const path = `/api/users/pain/distribution`;
  const body = { tagIds };
  return await RestApiClient.post(path, body);
}

export async function getTeamPainImpact() {
  const path = `/api/users/pain/impact`;
  return await RestApiClient.get(path);
}

export async function getTeamPainImpactWithTags(tagIds) {
  const path = `/api/users/pain/impact`;
  const body = { tagIds };
  return await RestApiClient.post(path, body);
}

export async function getPeopleWithUniqueRequirements() {
  const path = `/api/users/unique-requirements`;
  return await RestApiClient.get(path);
}

export async function getPeopleWithUniqueRequirementsWithTags(tagIds) {
  const path = `/api/users/unique-requirements`;
  const body = { tagIds };
  return await RestApiClient.post(path, body);
}

export async function getMskHealthCulture() {
  const path = `/api/users/msk-health/habits`;
  return await RestApiClient.get(path);
}

export async function getMskHealthCultureWithTags(tagIds) {
  const path = `/api/users/msk-health/habits`;
  const body = { tagIds };
  return await RestApiClient.post(path, body);
}

export async function getAverageAssessmentScores() {
  const path = `/api/assessments/scores/averages`;
  return await RestApiClient.get(path);
}

export async function getAverageAssessmentScoresWithTags(tagIds) {
  const path = `/api/assessments/scores/averages`;
  const body = { tagIds };
  return await RestApiClient.post(path, body);
}

