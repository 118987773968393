import { BaseTextModule } from "@/components/assessment/common/report/base-text-module.js";
import { PAIN_CONTENT } from "./content/pain-content";

export class PainModule extends BaseTextModule {
  constructor() {
    super(PAIN_CONTENT);
  }

  getPainSectionTitle(results) {
    const stageText = this.content.getStageText(results.stage.stage);
    return this.content.getSectionTitle(stageText);
  }

  getPainAreasCardTitle() {
    return this.content.areasCardTitle;
  }

  getPainContentCardTitle() {
    return this.content.contentCardTitle();
  }

  getPainContentCardBody(results) {
    return this.content.getContentBody(results?.stage?.stage);
  }
}
