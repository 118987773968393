<template>
  <svg
    width="652"
    height="369"
    viewBox="0 0 652 369"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3752_18323)">
      <path
        d="M109.5 316L109 316L109 316.5L109.5 316.5V316ZM109.854 -0.353577C109.658 -0.548859 109.342 -0.548859 109.146 -0.353577L105.964 2.8284C105.769 3.02365 105.769 3.34024 105.964 3.53549C106.16 3.73077 106.476 3.73077 106.672 3.53549L109.5 0.707062L112.328 3.53549C112.524 3.73077 112.84 3.73077 113.036 3.53549C113.231 3.34024 113.231 3.02365 113.036 2.8284L109.854 -0.353577ZM602.354 316.354C602.549 316.158 602.549 315.842 602.354 315.646L599.172 312.464C598.976 312.269 598.66 312.269 598.464 312.464C598.269 312.66 598.269 312.976 598.464 313.172L601.293 316L598.464 318.828C598.269 319.024 598.269 319.34 598.464 319.536C598.66 319.731 598.976 319.731 599.172 319.536L602.354 316.354ZM110 316L110 -3.05394e-05L109 -3.04957e-05L109 316L110 316ZM109.5 316.5L602 316.5L602 315.5L109.5 315.5V316.5Z"
        fill="#5F7B7C"
      />
      <!-- Age markers -->
      <text
        v-for="(age, index) in ageMarkers"
        :key="index"
        fill="#2A3C3C"
        xml:space="preserve"
        style="white-space: pre"
        font-family="Manrope"
        font-size="14"
        font-weight="500"
        letter-spacing="0px"
        :x="age.x"
        y="340.362"
      >
        <tspan>{{ age.value }}</tspan>
      </text>

      <!-- Axis labels -->
      <text
        fill="#2A3C3C"
        xml:space="preserve"
        style="white-space: pre"
        font-family="Manrope"
        font-size="14"
        font-weight="500"
        letter-spacing="0px"
      >
        <tspan x="310" y="365.362">
          {{
            $t(
              "menopauseAssessment.report.content.mskHealth.graphs.hrt.ageLabel"
            )
          }}
        </tspan>
      </text>

      <text
        transform="matrix(0 -1 1 0 49 300)"
        fill="#2A3C3C"
        xml:space="preserve"
        style="white-space: pre"
        font-family="Manrope"
        font-size="14"
        font-weight="500"
        letter-spacing="0px"
      >
        <tspan x="0" y="14.362">
          {{
            $t(
              "menopauseAssessment.report.content.mskHealth.graphs.hrt.oestrogenLabel"
            )
          }}
        </tspan>
      </text>

      <g clip-path="url(#clip1_3752_18323)">
        <path
          d="M152 30C193.231 30 234.462 30 275.692 54.902C316.923 79.8039 358.154 154.51 399.385 229.216C432 282.5 488.5 279 688 277.5"
          stroke="#005F61"
          stroke-width="4"
        />
        <path
          d="M152 30C193.231 30 234.462 30 275.692 54.8333C316.923 79.6667 358.154 129.333 399.385 154.167C440.615 179 481.846 179 688 179"
          stroke="#F7BE7D"
          stroke-width="4"
        />
        <text
          fill="#2A3C3C"
          xml:space="preserve"
          style="white-space: pre"
          font-family="Manrope"
          font-size="14"
          font-weight="500"
          letter-spacing="0px"
        >
          <tspan x="491" y="255.362">
            {{
              $t(
                "menopauseAssessment.report.content.mskHealth.graphs.hrt.naturalLevel"
              )
            }}
          </tspan>
        </text>
        <text
          fill="#2A3C3C"
          xml:space="preserve"
          style="white-space: pre"
          font-family="Manrope"
          font-size="14"
          font-weight="500"
          letter-spacing="0px"
        >
          <tspan x="491" y="158.362">
            {{
              $t(
                "menopauseAssessment.report.content.mskHealth.graphs.hrt.withHrt"
              )
            }}
          </tspan>
        </text>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3752_18323">
        <rect width="652" height="369" fill="white" />
      </clipPath>
      <clipPath id="clip1_3752_18323">
        <rect
          width="451"
          height="254"
          fill="white"
          transform="translate(152 30)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "HormoneReplacementTherapy",
  data() {
    return {
      ageMarkers: [
        { x: 172, value: "35" },
        { x: 239, value: "40" },
        { x: 308, value: "45" },
        { x: 377, value: "50" },
        { x: 448, value: "55" },
        { x: 517, value: "60" },
        { x: 587, value: "70" }
      ]
    };
  }
};
</script>
