<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{ $t(title) }}</template>
    <template #content>
      <vfl-tabs :initial-tab="initialTab">
        <vfl-tab
          v-for="tab in filteredTabs"
          :key="tab.type"
          :title="tab.title()"
        >
          <div class="tab-wrapper">
            <menstruation-report-graph :type="tab.type" />
            <report-info-accordion
              :sections="currentSections(tab.type)"
              :assessmentType="$options.assessmentTypes.menstruationAssessment"
            />
          </div>
        </vfl-tab>
      </vfl-tabs>
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import VflTabs from "@/components/vitrue-foundation-library/tabs/VflTabs.vue";
import VflTab from "@/components/vitrue-foundation-library/tabs/VflTab.vue";
import ReportInfoAccordion from "@/components/assessment/common/report/ReportInfoAccordion.vue";
import MenstruationReportGraph from "@/components/assessment/menstruation/report/graphs/MenstruationReportGraph.vue";
import { menstruationReportTextService } from "@/components/assessment/menstruation/report/text-service.js";
import {
  STAGE_MAPPING,
  STAGES
} from "@/components/assessment/menstruation/report/constants/index.js";
import {
  TABS,
  TAB_INDICES
} from "@/components/assessment/menstruation/report/constants/msk-health-tabs.js";
import { assessmentTypes } from "@/constants/constants.js";

export default {
  name: "MenstruationReportMskHealth",
  components: {
    ReportInfoAccordion,
    VflTab,
    VflTabs,
    ReportSection,
    MenstruationReportGraph
  },
  props: {
    results: {
      type: Object,
      required: true
    },
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tabs: TABS,
      initialTab: 0
    };
  },
  assessmentTypes: assessmentTypes,
  created() {
    this.setInitialTab();
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter(tab => {
        return tab.show(this.results);
      });
    },
    title() {
      return menstruationReportTextService.getMskHealthTitle();
    }
  },
  methods: {
    currentSections(tab) {
      return menstruationReportTextService.getAccordionContent(tab);
    },
    setInitialTab() {
      const { menstruationPhase } = this.results.stage;
      const mappedStage = STAGE_MAPPING[menstruationPhase];

      switch (mappedStage) {
        case STAGES.FOLLICULAR:
          this.initialTab = TAB_INDICES.FOLLICULAR;
          break;
        case STAGES.OVULATION:
          this.initialTab = TAB_INDICES.OVULATION;
          break;
        case STAGES.LUTEAL:
          this.initialTab = TAB_INDICES.LUTEAL;
          break;
        default:
          this.initialTab = TAB_INDICES.PERIOD;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.tab-wrapper {
  align-items: start;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}
</style>
