<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{ sectionTitle }}</template>
    <template #content>
      <div class="content-wrapper">
        <div class="vfl-card-light py-4 px-8 pb-12">
          <h4 class="text-h6 text-center mb-8 text-body-darkest">
            {{ painAreasCardTitle() }}
          </h4>
          <pain-person
            v-model="results.pain"
            disabled
            :painPersonMaxWidth="280"
          />
        </div>

        <report-info-card
          :title="painContentCardTitle"
          illustration="/images/illustrations/menopause-pain.png"
          :text="painContentCardBody"
          :assessmentType="$options.assessmentTypes.menopauseAssessment"
        />
      </div>
      <menopause-report-pain-callout
        v-if="showChronicPainWarning"
        class="mt-4"
      />
      <recommendation-container
        class="mt-10"
        :title="recommendationTitle"
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="painRecommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import ReportInfoCard from "@/components/assessment/common/report/ReportInfoCard.vue";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";
import PainPerson from "@/components/painareas/PainPerson.vue";
import MenopauseReportPainCallout from "@/components/assessment/menopause/report/MenopauseReportPainCallout.vue";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/menopause/report/recommendations/recommendation-service.js";
import BodyAreas from "@/components/painareas/body-areas.json";
import { assessmentTypes } from "@/constants/constants.js";

export default {
  name: "MenopauseReportPain",
  components: {
    MenopauseReportPainCallout,
    PainPerson,
    ReportSection,
    RecommendationContainer,
    ReportInfoCard
  },
  data() {
    return { painRecommendations: {} };
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.painRecommendations = getRecommendationsByType(
      RecommendationSections.PAIN,
      this.results
    );
  },
  computed: {
    sectionTitle() {
      return menopauseReportService.getPainSectionTitle(this.results);
    },
    painAreasCardTitle() {
      return menopauseReportService.getPainAreasCardTitle();
    },
    painContentCardTitle() {
      return menopauseReportService.getPainContentCardTitle();
    },
    painContentCardBody() {
      return menopauseReportService.getPainContentCardBody(this.results);
    },
    showChronicPainWarning() {
      if (!this.results?.pain?.areas) {
        return false;
      }

      const isChronicDurationBoundary = 4;

      return Object.values(this.results.pain.areas).some(
        area => area.duration < isChronicDurationBoundary
      );
    },
    recommendationTitle() {
      const painAreaKeys = Object.keys(this.results.pain.areas);
      if (painAreaKeys.length > 1) {
        return this.$t(
          "menopauseAssessment.report.recommendations.pain.title.generic"
        );
      }

      const translatedArea = this.$t(
        BodyAreas[painAreaKeys[0]].shortenedName
      ).toLowerCase();
      return this.$t(
        "menopauseAssessment.report.recommendations.pain.title.withArea",
        { area: translatedArea }
      );
    }
  }
};
</script>
<style scoped lang="scss">
.content-wrapper {
  display: grid;
  grid-template-columns: minmax(300px, 430px) 1fr;
  grid-gap: 1rem;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}
.vfl-card-light {
  background: white;
}
</style>
