import i18n from "@/plugins/i18n.js";

import { GRAPH_TYPES } from "@/components/assessment/menopause/report/constants/graphs.js";

export const MSK_HEALTH_CONTENT = {
  title: "menopauseAssessment.report.content.mskHealth.title",
  accordion: {
    [GRAPH_TYPES.PERIMENOPAUSE]: [
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.perimenopause.whatIs.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.perimenopause.whatIs.description",
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.perimenopause.whatHappens.title"
          ),
        type: "list",
        items: () =>
          i18n
            .t(
              "menopauseAssessment.report.content.mskHealth.stages.perimenopause.whatHappens.items",
              [],
              { returnObjects: true }
            )
            .map(text => ({ text }))
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.perimenopause.oestrogen.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.perimenopause.oestrogen.description",
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.perimenopause.impact.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.perimenopause.impact.description",
            [],
            { returnObjects: true }
          )
      }
    ],
    [GRAPH_TYPES.MENOPAUSE]: [
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.menopause.whatIs.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.menopause.whatIs.description",
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.menopause.whatHappens.title"
          ),
        type: "list",
        items: () =>
          i18n
            .t(
              "menopauseAssessment.report.content.mskHealth.stages.menopause.whatHappens.items",
              [],
              { returnObjects: true }
            )
            .map(text => ({ text }))
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.menopause.oestrogen.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.menopause.oestrogen.description",
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.menopause.impact.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.menopause.impact.description",
            [],
            { returnObjects: true }
          )
      }
    ],
    [GRAPH_TYPES.POSTMENOPAUSE]: [
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.postmenopause.whatIs.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.postmenopause.whatIs.description",
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.postmenopause.whatHappens.title"
          ),
        type: "list",
        items: () =>
          i18n
            .t(
              "menopauseAssessment.report.content.mskHealth.stages.postmenopause.whatHappens.items",
              [],
              { returnObjects: true }
            )
            .map(text => ({ text }))
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.postmenopause.oestrogen.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.postmenopause.oestrogen.description",
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.postmenopause.impact.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.postmenopause.impact.description",
            [],
            { returnObjects: true }
          )
      }
    ],
    [GRAPH_TYPES.INJECTABLE_CONTRACEPTIVES]: [
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.injectableContraceptives.hormoneProfile.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.injectableContraceptives.hormoneProfile.description",
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.injectableContraceptives.insights.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.injectableContraceptives.insights.description",
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.injectableContraceptives.impact.title"
          ),
        type: "list",
        items: () =>
          i18n
            .t(
              "menopauseAssessment.report.content.mskHealth.stages.injectableContraceptives.impact.items",
              [],
              { returnObjects: true }
            )
            .map(text => ({ text }))
      }
    ],
    [GRAPH_TYPES.HRT]: [
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.hrt.whatIs.title"
          ),
        paragraphs: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.hrt.whatIs.description",
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(
            "menopauseAssessment.report.content.mskHealth.stages.hrt.healthAndHrt.title"
          ),
        sections: () =>
          i18n
            .t(
              "menopauseAssessment.report.content.mskHealth.stages.hrt.healthAndHrt.sections",
              [],
              { returnObjects: true }
            )
            .map(section => ({
              subtitle: section.subtitle,
              type: "list",
              items: section.items.map(text => ({ text }))
            }))
      }
    ]
  }
};
