import { v4 as uuidv4 } from "uuid";
import {
  getAdminConversationWithUserMessages,
  sendAdminMessageToUser
} from "@/api/userApi.js";

const messagingStore = {
  namespaced: true,
  state: {
    messages: [],
    isLoading: false,
    sendingMessageId: null
  },
  mutations: {
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    },
    SET_INITIAL_MESSAGES(state, messages) {
      state.messages = messages;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_SENDING_MESSAGE_ID(state, id) {
      state.sendingMessageId = id;
    },
    CLEAR_MESSAGES(state) {
      state.messages = [];
    }
  },
  actions: {
    async sendMessage({ commit }, { content, userId, currentUserId }) {
      const messageId = uuidv4();
      commit("SET_SENDING_MESSAGE_ID", messageId);
      const message = {
        id: messageId,
        content,
        timestamp: new Date(),
        isAdmin: true,
        sentByUserId: currentUserId
      };
      commit("ADD_MESSAGE", message);
      const apiMessage = await sendAdminMessageToUser(userId, content);
      message.id = apiMessage.id;
      commit("SET_SENDING_MESSAGE_ID", null);
    },
    async initialiseMessages({ commit, dispatch }, userId) {
      dispatch("setLoading", true);

      const messages = await getAdminConversationWithUserMessages(userId);
      const initialMessages = messages.map(message => ({
        ...message,
        isAdmin: message.sentByUserId !== userId,
        timestamp: new Date(message.timestamp)
      }));
      commit("SET_INITIAL_MESSAGES", initialMessages);
      dispatch("setLoading", false);
    },
    clearMessages({ commit }) {
      commit("CLEAR_MESSAGES");
    },
    setLoading({ commit }, isLoading) {
      commit("SET_LOADING", isLoading);
    }
  },
  getters: {
    isLoading: state => state.isLoading,
    isSendingMessage: state => id => state.sendingMessageId === id
  }
};

export default messagingStore;
