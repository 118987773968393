<template>
  <div>
    <div class="pain-person-key">
      <span>{{ $t("painPerson.front") }}</span>
      <span>{{ $t("painPerson.back") }}</span>
    </div>
    <pain-person-question-dialog
      ref="discardPainDialog"
      :heading="dialogHeading"
      :subheading="dialogSubheading"
      :confirmText="$t('buttons.yes')"
      :declineText="$t('buttons.no')"
      :image="areaToRemoveImage"
      outlineImage
    />
    <v-dialog
      v-if="showPainQuestionPurposeDialog"
      v-model="showPainQuestionPurposeDialog"
      :width="$vuetify.breakpoint.smAndDown ? '85vw' : '75vw'"
    >
      <v-card>
        <v-img src="/images/wellness/pain-reason.png" />
        <v-btn
          class="close-button"
          icon
          :large="$vuetify.breakpoint.mdAndUp"
          @click="showPainQuestionPurposeDialog = false"
        >
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-row
      justify="center"
      class="pain-person-wrapper"
      :style="{ maxWidth: `${painPersonMaxWidth}px` }"
    >
      <v-tooltip
        top
        :max-width="analyticsMode ? 120 : ''"
        content-class="text-center"
        :nudge-bottom="analyticsMode ? 100 : 0"
        :disabled="disabled && !analyticsMode"
        :open-on-click="analyticsMode"
        z-index="0"
      >
        <template v-slot:activator="{ on, attrs }">
          <svg
            height="100%"
            width="100%"
            viewBox="0 0 472 503"
            v-bind="attrs"
            v-on="on"
            preserve
            style="cursor: default; outline: none !important"
          >
            <path
              v-for="([name, path], index) in regionsToPlot"
              :key="index"
              :d="path"
              :id="name"
              @mouseover="onHover(name)"
              @mouseleave="onLeave(name)"
              @click="onClick(name)"
              @touchstart="touchEvent = true"
              :fill="
                analyticsMode ? getAnalyticsPainColor(name) : regionColour[name]
              "
              :class="[
                'body-area',
                {
                  'is-highlighted': isHighlightingAll,
                  'has-transition': isTransitioning
                }
              ]"
              :style="disabled || isAreaDisabled(name) ? '' : 'cursor: pointer'"
            />
          </svg>
        </template>
        <span v-html="bodyPartToolTip"></span>
      </v-tooltip>
    </v-row>

    <v-row justify="center" v-if="!disabled && !hideChips" no-gutters>
      <v-col cols="12" class="d-flex justify-center">
        <v-checkbox
          class="justify-self-center"
          v-if="!disabled && !hideChips"
          v-model="noPainCheckbox"
          :label="$t('painPerson.noPain')"
          @change="selectNoPainCheckbox"
          hide-details
        ></v-checkbox
      ></v-col>
      <v-chip
        v-for="area in selectedAreas"
        :key="area"
        close
        class="text-body-1 ma-2"
        outlined
        append-icon="close"
        close-icon="close"
        @click:close="confirmRemovePainArea(area)"
        >{{ $t($options.bodyAreas[area].name) }}</v-chip
      >
    </v-row>
    <v-row
      justify="center"
      v-if="!disabled && !hideChips && showWhyWeAskButton"
    >
      <button
        v-if="showWhyDoWeAskThisButton"
        class="vfl-button-link"
        @click="openWhyWeAskDialog()"
      >
        <small> {{ $t("buttons.whyWeAsk") }} </small>
      </button>
    </v-row>
  </div>
</template>

<script>
import PainCircleImages from "@/assets/json/DeskAssessment/PainCircleImages.json";
import NotificationDialog from "./NotificationDialog.vue";
import bodyAreas from "./body-areas.json";
import PainPersonQuestionDialog from "./PainPersonQuestionDialog.vue";

export default {
  name: "PainPerson",
  props: {
    value: Object,
    validateForm: {
      type: Function,
      default: () => {
        return true;
      }
    },
    disabled: Boolean,
    linkedToQuestions: Boolean,
    oneAreaOnly: {
      type: Boolean,
      default: false
    },
    assessmentType: String,
    analyticsPainAreas: Array,
    selectedColor: String,
    hideChips: Boolean,
    showWhyDoWeAskThisButton: {
      type: Boolean,
      default: true
    },
    allowDeselect: Boolean,
    showTooltipInstruction: {
      type: Boolean,
      default: true
    },
    painPersonMaxWidth: {
      type: Number,
      default: 472
    }
  },
  bodyAreas,
  components: {
    NotificationDialog,
    PainPersonQuestionDialog
  },
  data() {
    return {
      currentSelection: null,
      currentHoverArea: null,
      painIndicatorImage: "/images/painindicator/basemap.png",
      discardDialog: false,
      finishQuestionsDialog: false,
      areaToBeRemoved: null,
      touchEvent: false,
      showPainQuestionPurposeDialog: false,
      noPainCheckbox: false,
      isHighlightingAll: false,
      isTransitioning: false,
      transitionTimeout: null,
      startHighlightTimeout: null,
      endHighlightTimeout: null,
      highlightBodyAreasOnMount: false
    };
  },
  computed: {
    showWhyWeAskButton() {
      return this._i18n.locale.includes("en");
    },
    areaToBeRemovedText() {
      return this.areaToBeRemoved
        ? this.$t(
            this.$options.bodyAreas[this.areaToBeRemoved].name
          ).toLowerCase()
        : "";
    },
    areaToRemoveImage() {
      return this.areaToBeRemoved
        ? PainCircleImages[this.areaToBeRemoved]
        : PainCircleImages.noArea;
    },
    selectedAreas() {
      return Object.keys(this.value.areas);
    },
    hasSelectedBodyArea() {},
    regionColour() {
      return Object.fromEntries(
        Object.keys(this.$options.bodyAreas).map(areaName => {
          const isSelected = this.selectedAreas.includes(areaName);
          const isHovered = this.currentHoverArea === areaName;
          const isDisabled = this.isAreaDisabled(areaName);

          let fillColour;
          if (this.noPainCheckbox && !this.discardDialog) {
            fillColour = "#94BCA9";
          } else if (isSelected && isHovered) {
            fillColour = "#cc5555";
          } else if (isDisabled) {
            fillColour = "#d8d7d6";
          } else if (isSelected) {
            fillColour = this.selectedColor ?? "#e51b20";
          } else if (isHovered) {
            fillColour = "#969696";
          } else {
            fillColour = "#bebebe";
          }
          return [areaName, fillColour];
        })
      );
    },
    bodyPartToolTip() {
      let area = this.currentHoverArea
        ? this.$t(this.$options.bodyAreas[this.currentHoverArea].name)
        : null;
      if (this.analyticsMode) {
        let painArea = this.analyticsPainAreas.find(
          x => x.id === this.currentHoverArea
        );
        let defaultMessage = this.touchEvent
          ? this.$t("tooltips.painBodyClick")
          : this.$t("tooltips.painBodyHover");
        return painArea
          ? area +
              ": " +
              painArea.percentage +
              "%" +
              this.$tc("painPerson.people", painArea.count, {
                n: painArea.count
              })
          : defaultMessage;
      }
      if (!area) {
        return this.showTooltipInstruction
          ? this.$t("tooltips.painChooseBodyPart")
          : "-";
      }
      return area;
    },
    regionsToPlot() {
      return Object.entries(this.$options.bodyAreas).flatMap(([name, obj]) =>
        obj.paths.map(path => [this.$t(name), path])
      );
    },
    analyticsMode() {
      return !!this.analyticsPainAreas && this.analyticsPainAreas.length > 0;
    },
    dialogHeading() {
      if (this.selectedAreas.length > 1 && this.noPainCheckbox) {
        return this.$t("painPerson.discardDialog.allAreas.heading");
      }

      return this.$t("painPerson.discardDialog.heading", {
        0: this.areaToBeRemovedText
      });
    },
    dialogSubheading() {
      if (this.selectedAreas.length > 1 && this.noPainCheckbox) {
        return this.$t("painPerson.discardDialog.allAreas.subheading");
      }

      return this.$t("painPerson.discardDialog.subheading", {
        0: this.areaToBeRemovedText
      });
    }
  },
  mounted() {
    if (this.highlightBodyAreasOnMount) {
      this.highlightPaths();
    }
  },
  beforeDestroy() {
    if (this.startTimeout) clearTimeout(this.startTimeout);
    if (this.endTimeout) clearTimeout(this.endTimeout);
    if (this.transitionTimeout) clearTimeout(this.transitionTimeout);
  },
  methods: {
    onHover(areaName) {
      if (this.isHighlightingAll) {
        this.isHighlightingAll = false;
      }

      if (
        (this.disabled && !this.analyticsMode) ||
        this.isAreaDisabled(areaName)
      ) {
        return;
      }
      this.currentHoverArea = areaName;
    },
    onLeave(areaName) {
      if (this.currentHoverArea === areaName) {
        this.currentHoverArea = null;
      }
    },
    onClick(value) {
      if (this.disabled || this.isAreaDisabled(value)) {
        return;
      }

      if (
        this.oneAreaOnly &&
        !!this.currentSelection &&
        this.currentSelection !== value
      ) {
        this.removePainArea(this.currentSelection);
      }

      if (this.selectedAreas.includes(value)) {
        this.areaToBeRemoved = value;
        if (this.linkedToQuestions || this.allowDeselect) {
          this.confirmRemovePainArea(value);
        }
      } else if (!this.selectedAreas.includes(value)) {
        let tempAreas = { ...this.value.areas };
        tempAreas[value] = {};
        this.value.areas = tempAreas;
        this.notifyParent(value);
      } else {
        this.notifyParent(value);
      }
    },
    isAreaDisabled(area) {
      const disabled = this.$options.bodyAreas[area].disabled;
      if (disabled === true || !disabled) {
        return !!disabled;
      }
      return disabled[this.assessmentType];
    },
    async confirmRemovePainArea(value) {
      this.areaToBeRemoved = value;
      let levelQuestionAnswered = !!this.value.areas[value].level;
      if (this.linkedToQuestions && levelQuestionAnswered) {
        let confirmed = await this.$refs.discardPainDialog.open();
        if (confirmed) {
          this.removePainArea(this.areaToBeRemoved);
        }
      } else {
        this.removePainArea();
      }
    },
    removePainArea(value) {
      let area = value ?? this.areaToBeRemoved;
      this.$delete(this.value.areas, area);
      this.$emit("removeArea", area);
      if (this.currentSelection === area) {
        this.notifyParent(null);
      }
      this.areaToBeRemoved = null;
    },
    changeDisplayedQuestions(value) {
      this.notifyParent(value);
    },
    notifyParent(value) {
      this.currentSelection = value;
      this.$emit("selectionChanged", value);
      this.$emit(
        "selectionNameChanged",
        value ? this.$t(this.$options.bodyAreas[value].name) : ""
      );
    },
    getAnalyticsPainColor(name) {
      let region = this.analyticsPainAreas.find(x => x.id === name);
      return region ? region.color : "#d8d7d6";
    },
    openWhyWeAskDialog() {
      this.$mixpanel.track(`Clicked on why we ask for pain person`);
      this.showPainQuestionPurposeDialog = true;
    },
    async selectNoPainCheckbox() {
      if (!this.noPainCheckbox) return; // if setting from a true to false state no further checks need to be done

      if (this.selectedAreas.length === 0) {
        this.scrollToBottom();
        return;
      }

      if (this.selectedAreas.length === 1) {
        this.areaToBeRemoved = this.selectedAreas[0];
      }

      this.discardDialog = true;
      const confirmed = await this.$refs.discardPainDialog.open();
      this.discardDialog = false;

      if (!confirmed) {
        this.noPainCheckbox = false;
        return;
      }

      this.selectedAreas.forEach(area => this.removePainArea(area));
      this.scrollToBottom();
    },
    scrollToBottom() {
      this.$mixpanel.track("User selected no pain checkbox");
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth"
      });
    },
    highlightPaths() {
      if (this.selectedAreas.length) return;
      if (this.analyticsMode) return;

      if (this.startTimeout) clearTimeout(this.startTimeout);
      if (this.endTimeout) clearTimeout(this.endTimeout);

      // Wait 250ms before highlight
      // Stay highlighted for 1s
      // Remove the transition class after 300ms (should match in CSS)

      this.startTimeout = setTimeout(() => {
        this.isHighlightingAll = true;
        this.isTransitioning = true;

        this.endTimeout = setTimeout(() => {
          this.isHighlightingAll = false;

          this.transitionTimeout = setTimeout(() => {
            this.isTransitioning = false;
          }, 300);
        }, 1000);
      }, 500);
    }
  }
};
</script>

<style scoped lang="scss">
$svg-max-width: 472px;

.body-area {
  stroke: #aaa;
  stroke-width: 0.2;
  stroke-linejoin: round;

  &.has-transition {
    transition: fill 0.3s linear;
  }

  &.is-highlighted {
    fill: #969696 !important;
  }
}

.disabled-body-area {
  fill: #d8d7d6;
  stroke-width: 0;
  stroke-linejoin: round;
}

.pain-person-wrapper {
  display: flex;
  margin: 0 auto;
  max-width: $svg-max-width;
  position: relative;
  width: 100%;
}

.pain-person-key {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  padding: 1rem 1rem 0;
  pointer-events: none;
  max-width: $svg-max-width; // bounding box of SVG
  color: #5f7b7c;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
}
</style>
