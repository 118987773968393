<template>
  <div class="d-flex align-center">
    <button
      class="button-animation text-body-2 mb-0"
      :class="{ 'has-hover': nonTypicalWorkers?.length && canOpenPeopleList }"
      @click="handleClick"
    >
      <span class="non-typical-people-count text-body-bold mr-1">{{
        nonTypicalWorkers.length
      }}</span>
      <span class="button-text">{{
        $tc("mskOverview.withHealthCondition", nonTypicalWorkers?.length)
      }}</span>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { sortPeople } from "@/components/dashboard/cards/pain-overview/helpers.js";
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "PainOverviewNonTypicalPeople",
  computed: {
    ...mapGetters({
      adminId: "userId",
      isRestrictedAdmin: "isRestrictedAdminUser"
    }),
    ...mapGetters("adminDashboard", ["nonTypicalWorkers"]),
    canOpenPeopleList() {
      return !this.isRestrictedAdmin;
    }
  },
  methods: {
    ...mapActions("sidePanelStore", ["open"]),
    handleClick() {
      if (!this.canOpenPeopleList) return;

      const sortedPeople = sortPeople(this.nonTypicalWorkers);

      this.open({
        people: sortedPeople,
        heading: this.$t(
          "mskOverview.peopleAssessed.sidePanelTitle.healthCondition"
        )
      });

      sendEvent("MSK User List opened", {
        from: "MSK Overview card",
        admin: this.adminId,
        key: "Non typical workers"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  color: var(--v-vflNeutralDark2-base);
}

.non-typical-people-count {
  aspect-ratio: 1/1;
  background: var(--v-vflAquaLight2-base);
  border-radius: 999px;
  color: var(--v-vflPrimary-base);
  display: inline-grid;
  min-width: 1.5rem;
  place-content: center;
}

.button-animation {
  display: flex;
  align-items: center;
  cursor: default;
  width: 100%;
  transition: background-color 0.2s ease;

  &.has-hover:hover {
    cursor: pointer;

    .button-text::after {
      transform: scaleX(1);
      opacity: 1;
    }
  }
}
.button-text {
  position: relative;
  color: var(--v-vflNeutralDark2-base);

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: scaleX(0);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform-origin: left;
  }
}
</style>
