import SymptomsRecommendations from "@/components/assessment/menstruation/report/recommendations/SymptomsRecommendations.json";
import { LinkTypes } from "@/services/recommendations/link-types";
import { hasPain } from "@/services/deskassessment/results-utils";
import Answers from "@/components/assessment/menstruation/answers.json";

const cardGroups = {
  // Only show cramp relief in the symptoms section if a user has no pain and suffers from cramps
  crampRelief: {
    titleKey:
      "menstruationAssessment.report.recommendations.symptoms.groups.crampRelief",
    cards: {
      menstruation_herbal_tea: {
        showConditions: results => showCrampReliefCards(results)
      },
      menstruation_diy_warm_compress: {
        showConditions: results => showCrampReliefCards(results)
      },
      menstruation_magnesium_rich_food: {
        showConditions: results => showCrampReliefCards(results)
      },
      menstruation_warm_shower: {
        showConditions: results => showCrampReliefCards(results)
      },
      menstruation_abs_massage: {
        showConditions: results => showCrampReliefCards(results)
      }
    }
  },
  general: {
    titleKey:
      "menstruationAssessment.report.recommendations.symptoms.groups.general",
    cards: {
      menstruation_prioritise_workouts: {
        showConditions: () => true
      },
      menstruation_low_sugar_food: {
        showConditions: () => true
      },
      menstruation_salt_intake: {
        showConditions: () => true
      },
      menstruation_consistent_sleep: {
        showConditions: () => true
      },
      menstruation_stay_hydrated: {
        showConditions: () => true
      }
    }
  }
};

const showCrampReliefCards = results => {
  return hasCramps(results) && !hasPain(results);
};

const hasCramps = results => {
  if (!results.symptoms?.menstruationSymptoms) {
    return false;
  }

  return results.symptoms.menstruationSymptoms.includes(
    Answers.symptoms.menstruationSymptoms.abdominalCramps
  );
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const getVisibleCardsForGroup = (groupCards, results) => {
  return Object.entries(groupCards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, SymptomsRecommendations[cardId])
    );
};

const hasVisibleCards = (group, results) => {
  return Object.values(group.cards).some(card => card.showConditions(results));
};

export const getSymptomsRecommendations = results => {
  const visibleGroups = Object.entries(cardGroups).reduce(
    (acc, [groupId, group]) => {
      if (hasVisibleCards(group, results)) {
        acc[groupId] = {
          titleKey: group.titleKey,
          cards: getVisibleCardsForGroup(group.cards, results)
        };
      }
      return acc;
    },
    {}
  );

  return {
    groups: visibleGroups
  };
};
