<template>
  <div class="d-flex flex-wrap align-center" style="gap: 0.5rem">
    <vfl-button
      ref="confettiButton"
      variant="secondary"
      size="small"
      is-square
      is-light
      @click="handleDidntKnow()"
      :style="{ pointerEvents: hasClicked ? 'none' : 'auto' }"
    >
      <template #startDecorator>
        <svg
          aria-hidden="true"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            v-if="!hasClicked"
            d="M6.64905 11.9997H7.33366V8.66634H8.66699V11.9997H9.35159C9.43959 11.1986 9.84839 10.5373 10.5121 9.81474C10.5868 9.73341 11.0662 9.23727 11.1231 9.16641C11.6882 8.46174 12.0003 7.58967 12.0003 6.66634C12.0003 4.4572 10.2095 2.66634 8.00033 2.66634C5.79119 2.66634 4.00033 4.4572 4.00033 6.66634C4.00033 7.58921 4.31215 8.46081 4.87674 9.16527C4.93369 9.23634 5.4142 9.73381 5.48802 9.81421C6.1521 10.5373 6.56101 11.1986 6.64905 11.9997ZM6.66699 13.333V13.9997H9.33366V13.333H6.66699ZM3.83629 9.99914C3.10463 9.08614 2.66699 7.92734 2.66699 6.66634C2.66699 3.72082 5.05481 1.33301 8.00033 1.33301C10.9459 1.33301 13.3337 3.72082 13.3337 6.66634C13.3337 7.92801 12.8955 9.08734 12.1632 10.0006C11.7497 10.5162 10.667 11.333 10.667 12.333V13.9997C10.667 14.7361 10.0701 15.333 9.33366 15.333H6.66699C5.93061 15.333 5.33366 14.7361 5.33366 13.9997V12.333C5.33366 11.333 4.25005 10.5154 3.83629 9.99914Z"
            fill="#005F61"
          />
          <path
            v-else
            d="M7.33366 11.9997H5.29433C5.09627 11.1513 4.20307 10.4568 3.83629 9.99914C3.10463 9.08614 2.66699 7.92734 2.66699 6.66634C2.66699 3.72082 5.05481 1.33301 8.00033 1.33301C10.9459 1.33301 13.3337 3.72082 13.3337 6.66634C13.3337 7.92801 12.8955 9.08734 12.1632 10.0006C11.7966 10.4577 10.9042 11.1514 10.7063 11.9997H8.66699V8.66634H7.33366V11.9997ZM10.667 13.333V13.9997C10.667 14.7361 10.0701 15.333 9.33366 15.333H6.66699C5.93061 15.333 5.33366 14.7361 5.33366 13.9997V13.333H10.667Z"
            fill="#005F61"
          />
        </svg>
      </template>

      <span class="vflPrimary--text">{{
        $t("assessment.report.didNotKnowThat.buttonText")
      }}</span>
    </vfl-button>
    <span class="vflNeutralDark2--text text-body-2">{{ displayCount }}</span>
  </div>
</template>

<script>
import VflButton from "@/components/vitrue-foundation-library/VflButton.vue";
import { sendEvent } from "@/services/analytics/analytics.js";
import confetti from "canvas-confetti";
import { assessmentTypes } from "@/constants/constants.js";

const STORAGE_KEY = "vida-ma-didnt-know-facts";

export default {
  name: "DidNotKnowThatFactButton",
  components: { VflButton },
  props: {
    fact: {
      type: String,
      required: true
    },
    assessmentType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hasClicked: false,
      currentCount: 0,
      confettiInstance: null
    };
  },
  mounted() {
    this.initializeCount();
  },
  computed: {
    storageKey() {
      return {
        count: `vida-ma-didnt-know-count-${this.fact}`,
        clicked: `vida-ma-didnt-know-clicked-${this.fact}`
      };
    },
    displayCount() {
      const learnedNewText = this.isWomensHealthAssessment
        ? "learnedNewWomen"
        : "learnedNew";
      return this.$t(`assessment.report.didNotKnowThat.${learnedNewText}`, {
        count: this.currentCount
      });
    },
    isWomensHealthAssessment() {
      return (
        this.assessmentType === assessmentTypes.menopauseAssessment ||
        this.assessmentType === assessmentTypes.menstruationAssessment
      );
    }
  },
  methods: {
    getStorageData() {
      try {
        const data = localStorage.getItem(STORAGE_KEY);
        return data ? JSON.parse(data) : {};
      } catch (e) {
        return {};
      }
    },
    setStorageData(data) {
      try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
        return true;
      } catch (e) {
        return false;
      }
    },
    updateFactData(updates) {
      const data = this.getStorageData();
      data[this.fact] = {
        ...(data[this.fact] || {}),
        ...updates
      };
      return this.setStorageData(data);
    },
    getFactData() {
      const data = this.getStorageData();
      return data[this.fact] || { count: null, clicked: false };
    },
    randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    initializeCount() {
      const { count, clicked } = this.getFactData();

      this.hasClicked = clicked;

      if (count !== null) {
        this.currentCount = count;
      } else {
        this.currentCount = this.randomIntFromInterval(80, 500);
        this.updateFactData({ count: this.currentCount });
      }
    },
    handleDidntKnow() {
      if (this.hasClicked) return;

      this.fireConfetti();
      this.currentCount++;

      this.updateFactData({
        count: this.currentCount,
        clicked: true
      });

      this.hasClicked = true;

      sendEvent(`${this.assessmentType} - user didn't know about`, {
        fact: this.fact
      });
    },
    fireConfetti() {
      const origin = this.getButtonPosition();

      this.confettiInstance = confetti({
        particleCount: 40,
        spread: 40,
        origin,
        colors: ["#005F61", "#4B9FA1", "#83CFCD"],
        disableForReducedMotion: true,
        startVelocity: 20,
        ticks: 80,
        shapes: ["square", "circle"],
        scalar: 0.8,
        zIndex: 300
      });
    },
    getButtonPosition() {
      const button = this.$refs?.confettiButton?.$el;
      if (!button) return {};

      const rect = button.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const buttonCenterX = rect.left + rect.width / 2;
      const buttonCenterY = rect.top + rect.height / 2;

      return {
        x: buttonCenterX / windowWidth,
        y: buttonCenterY / windowHeight
      };
    }
  },
  beforeDestroy() {
    if (this.confettiInstance) {
      confetti.reset();
      this.confettiInstance = null;
    }
  }
};
</script>
