export const capitaliseFirstLetter = text =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const hasSymptoms = results => {
  const symptoms = results?.symptoms?.symptoms;
  const NONE_ANSWER = 99;

  if (!Array.isArray(symptoms)) {
    return false;
  }

  if (symptoms.length === 1 && symptoms[0] === NONE_ANSWER) {
    return false;
  }

  return symptoms.length > 0;
};
