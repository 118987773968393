<template>
  <div>
    <vfl-textarea
      v-model="note"
      :show-counter="false"
      :height="'120'"
      label="Create new note"
    />

    <user-profile-note-sharing-info class="mt-3" />

    <div class="d-flex align-center justify-end">
      <button
        class="vfl-button-primary is-small mt-3"
        :class="{ 'is-disabled': !note.trim() || isSaving }"
        @click="handleAddNote"
      >
        {{ $t("userProfile.notes.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import VflTextarea from "@/components/vitrue-foundation-library/form/VflTextarea.vue";
import UserProfileNoteSharingInfo from "@/components/user-profile/UserProfileNoteSharingInfo.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserProfileAddNote",
  components: { VflTextarea, UserProfileNoteSharingInfo },
  data() {
    return {
      isSaving: false,
      note: ""
    };
  },
  computed: {
    ...mapGetters("userProfile", ["id"])
  },
  methods: {
    ...mapActions("userProfile", ["addNote"]),
    async handleAddNote() {
      if (!this.note.trim()) return;

      try {
        await this.addNote({ userId: this.id, note: this.note });
        this.note = "";
        this.$emit("note-added");
      } catch (error) {
        this.$logger.captureException(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  fieldset {
    border-radius: 12px;
  }
}
</style>
