<template>
  <div v-if="!submitted" class="feedback-container">
    <div class="px-4 px-md-8 py-8">
      <h1 class="text-h4 text-center">
        {{ $t("feedback.dialog.title") }}
      </h1>

      <feedback-emojis
        class="mt-8"
        v-model="reaction"
        showLabels
        @input="showGiveFeedbackMessage = false"
        @hover="hoverEmoji = $event"
        :spacing="$vuetify.breakpoint.xs ? `0` : `2`"
      />

      <v-slide-x-transition>
        <p v-show="showGiveFeedbackMessage" class="mt-2 error-message">
          {{ $t("feedback.dialog.emojiInstruction") }}
        </p>
      </v-slide-x-transition>

      <v-textarea
        class="mt-10"
        v-model="feedback"
        color="gray"
        no-resize
        style="min-height: 80px"
        hide-details
        outlined
      >
        <template v-slot:label>
          <div>{{ $t("feedback.dialog.textInstruction") }}</div>
        </template>
      </v-textarea>

      <v-slide-x-transition>
        <p v-show="showErrorSubmittingMessage" class="error-message mt-4">
          {{ $t("feedback.dialog.errorMessage") }}
        </p>
      </v-slide-x-transition>

      <div class="d-flex justify-space-bewteen mt-10" style="gap: 1rem">
        <button
          class="vfl-button-primary-outline flex-grow-1"
          @click="resetAndClose()"
        >
          {{ $t("buttons.cancel") }}
        </button>
        <button
          class="vfl-button-primary flex-grow-1"
          @click="submitFeedback()"
        >
          {{ $t("buttons.submit") }}
        </button>
      </div>
    </div>

    <FeedbackCalendlyLink />
  </div>

  <feedback-thanks
    v-else-if="showFeedbackThanks"
    :is-dismissable="true"
    @dismiss="resetAndClose"
  />

  <div v-else class="feedback-container">
    <div class="px-4 px-md-8 py-8">
      <h1 class="text-h5 text-center">
        {{ $t("feedback.dialog.successInfo") }}
      </h1>
      <div class="d-flex justify-center mt-10">
        <button
          class="vfl-button-primary-outline flex-grow-1"
          @click="resetAndClose()"
          style="max-width: 200px"
        >
          Close
        </button>
      </div>
    </div>

    <FeedbackCalendlyLink />
  </div>
</template>

<script>
import FeedbackEmojis from "@/components/common/Feedback/FeedbackEmojis.vue";
import FeedbackThanks from "@/components/common/Feedback/FeedbackThanks.vue";
import FeedbackCalendlyLink from "@/components/common/Feedback/FeedbackCalendlyLink.vue";
import { mapMutations, mapGetters } from "vuex";
import { createFeedback } from "@/customApi";

export default {
  name: "FeedbackDialog",
  components: {
    FeedbackEmojis,
    FeedbackThanks,
    FeedbackCalendlyLink
  },
  data() {
    return {
      reaction: null,
      feedback: null,
      showGiveFeedbackMessage: false,
      showErrorSubmittingMessage: false,
      submitted: false,
      timeLeft: 3,
      countdownCallback: null,
      hoverEmoji: null,
      sending: false
    };
  },
  props: {
    assessmentId: String,
    urlSuffix: String
  },
  computed: {
    ...mapGetters(["userEmail", "isAdminUser"]),
    showFeedbackThanks() {
      return this.reaction?.value === 5;
    }
  },
  methods: {
    ...mapMutations(["addAsyncRequest", "removeAsyncRequest"]),
    async submitFeedback() {
      this.showGiveFeedbackMessage = this.reaction === null;
      this.showErrorSubmittingMessage = false;
      if (this.showGiveFeedbackMessage) {
        return;
      }

      const guidRegex = new RegExp(this.assessmentId, "i");
      let cleanPath = this.$route.path.replace(guidRegex, "");
      const path = `${cleanPath}${this.urlSuffix ? `${this.urlSuffix}` : ""}`;
      let feedbackDto = {
        Email: { value: this.userEmail },
        Score: this.reaction.value,
        WrittenFeedback: this.feedback,
        AssessmentId: this.assessmentId,
        Url: path
      };
      if (this.reaction && !this.sending) {
        try {
          this.sending = true;
          this.addAsyncRequest();
          this.$gtag.event("Submit Regular Feedback", {
            event_category: "Feedback"
          });
          await createFeedback(feedbackDto);
          this.submitted = true;

          if (!this.showFeedbackThanks) {
            this.reaction = null;
            this.countdownCallback = window.setInterval(
              this.updateCountdown,
              1000
            );
          }
        } catch (err) {
          this.showErrorSubmittingMessage = true;
        } finally {
          this.removeAsyncRequest();
          this.sending = false;
        }
      }
    },
    updateCountdown() {
      this.timeLeft--;
      if (this.timeLeft === 0) {
        window.clearInterval(this.countdownCallback);
        this.resetAndClose();
      }
    },
    resetAndClose() {
      this.timeLeft = 3;
      this.submitted = false;
      this.reaction = null;
      this.feedback = "";
      this.showGiveFeedbackMessage = false;
      this.showErrorSubmittingMessage = false;
      this.$emit("input", false);
    }
  }
};
</script>

<style scoped lang="scss">
.feedback-container {
  background: white;
  border-radius: 0.5rem;
}

.error-message {
  color: var(--v-error-base);
}

::v-deep .v-text-field--outlined {
  fieldset {
    border-color: var(--lightGrey);
  }

  &.v-input--is-focused fieldset {
    border-color: var(--v-vflPrimary-base);
  }
}
</style>
