import i18n from "@/plugins/i18n.js";
import {
  STAGES,
  TREATMENT_TYPES
} from "@/components/assessment/menstruation/report/constants/index.js";

import Answers from "@/components/assessment/menstruation/answers.json";

export const TAB_INDICES = {
  PERIOD: 0,
  FOLLICULAR: 1,
  OVULATION: 2,
  LUTEAL: 3,
  INJECTABLE_CONTRACEPTIVES: 4
};

export const TABS = [
  {
    type: STAGES.PERIOD,
    title: () =>
      i18n.t("menstruationAssessment.report.content.mskHealth.tabs.period"),
    index: TAB_INDICES.PERIOD,
    show: () => true
  },
  {
    type: STAGES.FOLLICULAR,
    title: () =>
      i18n.t("menstruationAssessment.report.content.mskHealth.tabs.follicular"),
    index: TAB_INDICES.FOLLICULAR,
    show: () => true
  },
  {
    type: STAGES.OVULATION,
    title: () =>
      i18n.t("menstruationAssessment.report.content.mskHealth.tabs.ovulation"),
    index: TAB_INDICES.OVULATION,
    show: () => true
  },
  {
    type: STAGES.LUTEAL,
    title: () =>
      i18n.t("menstruationAssessment.report.content.mskHealth.tabs.luteal"),
    index: TAB_INDICES.LUTEAL,
    show: () => true
  },
  {
    type: TREATMENT_TYPES.INJECTABLE_CONTRACEPTIVES,
    title: () =>
      i18n.t(
        "menstruationAssessment.report.content.mskHealth.tabs.injectableContraceptives"
      ),
    index: TAB_INDICES.INJECTABLE_CONTRACEPTIVES,
    show: results => {
      return (
        results?.stage?.contraceptiveInjectables ===
          Answers.stage.usingContraceptiveInjectables.yes ||
        results?.stage?.contraceptiveInjectables ===
          Answers.stage.usingContraceptiveInjectables.preferNotToSay
      );
    }
  }
];
