<template>
  <div
    class="vfl-chip"
    :class="[`vfl-chip--${size}`, `vfl-chip--${variant}`]"
    :style="chipStyle"
  >
    <slot name="startDecorator"></slot>
    {{ label }}
    <slot name="endDecorator"></slot>
  </div>
</template>

<script>
export default {
  name: "VflChip",
  props: {
    label: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "md",
      validator: val => ["sm", "md", "lg"].includes(val)
    },
    variant: {
      type: String,
      default: "regular",
      validator: val => ["regular", "bold"].includes(val)
    },
    backgroundColor: {
      type: String,
      default: null
    },
    borderColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    chipStyle() {
      return {
        backgroundColor: this.backgroundColor || null,
        borderColor: this.borderColor || null,
        color: this.color || null
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.vfl-chip {
  align-items: center;
  background-color: var(--v-vflBlueVeryLight-base);
  border-width: 1px;
  border-style: solid;
  border-color: #dce9f7;
  border-radius: 20px;
  display: inline-flex;
  color: black;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0.3rem 0.75rem;

  // Size variants
  &--sm {
    padding: 0.2rem 0.75rem;
    font-size: 0.675rem;
    border-radius: 16px;
  }

  &--lg {
    padding: 0.4rem 1rem;
    font-size: 0.875rem;
    border-radius: 24px;
  }

  &--regular {
    font-weight: normal;
  }

  &--bold {
    font-weight: 700;
  }
}
</style>
