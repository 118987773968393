<template>
  <router-link
    :to="`people/${userId}`"
    target="_blank"
    class="vfl-button-primary-ghost is-icon-only d-flex justify-center align-center"
    ><v-icon>mdi-open-in-new</v-icon>
  </router-link>
</template>

<script>
export default {
  name: "UserProfileOpenFullPageButton",
  props: {
    userId: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>
