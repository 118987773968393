import { HeaderModule } from "@/components/assessment/menstruation/report/text-modules/header-module.js";
import { MskHealthModule } from "@/components/assessment/menstruation/report/text-modules/msk-health-module.js";

class MenstruationReportTextService {
  constructor() {
    this.header = new HeaderModule();
    this.mskHealth = new MskHealthModule();
  }

  getHeaderTitle() {
    return this.header.getHeaderTitle();
  }

  getHeaderContent() {
    return this.header.getHeaderContent();
  }

  getMskHealthTitle() {
    return this.mskHealth.getMskHealthTitle();
  }

  getGraphTitle(type) {
    return this.mskHealth.getGraphTitle(type);
  }

  getAccordionContent(type) {
    return this.mskHealth.getAccordionContent(type);
  }
}

export const menstruationReportTextService =
  new MenstruationReportTextService();
