<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{ $t(title) }}</template>
    <template #content>
      <vfl-tabs :initial-tab="initialTab">
        <vfl-tab
          v-for="tab in filteredTabs"
          :key="tab.type"
          :title="tab.title()"
        >
          <div class="tab-wrapper">
            <menopause-report-graph :type="tab.type" />
            <report-info-accordion
              :sections="currentSections(tab.type)"
              :assessmentType="$options.assessmentTypes.menopauseAssessment"
            />
          </div>
        </vfl-tab>
      </vfl-tabs>
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import VflTabs from "@/components/vitrue-foundation-library/tabs/VflTabs.vue";
import VflTab from "@/components/vitrue-foundation-library/tabs/VflTab.vue";
import MenopauseReportGraph from "@/components/assessment/menopause/report/MenopauseReportGraph.vue";
import ReportInfoAccordion from "@/components/assessment/common/report/ReportInfoAccordion.vue";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";
import {
  STAGE_MAPPING,
  STAGES,
  TREATMENT_TYPES
} from "@/components/assessment/menopause/report/constants/index.js";
import {
  TABS,
  TAB_INDICES
} from "@/components/assessment/menopause/report/constants/msk-health-tabs.js";
import { assessmentTypes } from "@/constants/constants.js";

export default {
  name: "MenopauseReportMskHealth",
  components: {
    ReportInfoAccordion,
    MenopauseReportGraph,
    VflTab,
    VflTabs,
    ReportSection
  },
  props: {
    results: {
      type: Object,
      required: true
    },
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tabs: TABS
    };
  },
  assessmentTypes: assessmentTypes,
  computed: {
    filteredTabs() {
      return this.tabs.filter(tab => {
        if (
          tab.type === TREATMENT_TYPES.INJECTABLE_CONTRACEPTIVES &&
          this.results?.stage?.contraceptiveInjectables === 2
        ) {
          return false;
        }
        return true;
      });
    },
    initialTab() {
      const { stage } = this.results.stage;
      const mappedStage = STAGE_MAPPING[stage];

      if (mappedStage === STAGES.PERIMENOPAUSE) {
        return TAB_INDICES.PERIMENOPAUSE;
      }

      if (mappedStage === STAGES.POSTMENOPAUSE) {
        return TAB_INDICES.POSTMENOPAUSE;
      }

      return TAB_INDICES.MENOPAUSE;
    },
    title() {
      return menopauseReportService.getMskHealthTitle();
    }
  },
  methods: {
    currentSections(tab) {
      return menopauseReportService.getAccordionContent(tab);
    }
  }
};
</script>

<style scoped lang="scss">
.tab-wrapper {
  align-items: start;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}
</style>
