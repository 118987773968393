export class ImageService {
  addUniqueOverlays(overlays, newOverlays) {
    if (!Array.isArray(newOverlays)) return;
    newOverlays.forEach(overlay => {
      if (!overlays.includes(overlay)) {
        overlays.push(overlay);
      }
    });
  }

  getBaseComponent(schema) {
    throw new Error("getBaseComponent must be implemented by sub class");
  }

  registerComponents(vm) {
    throw new Error("registerComponents must be implemented by sub class");
  }

  getOverlayComponents(currentTheme, results, schema) {
    throw new Error("getOverlayComponents must be implemented by sub class");
  }
}
