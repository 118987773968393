import { BaseTextModule } from "@/components/assessment/common/report/base-text-module.js";
import { MSK_HEALTH_CONTENT } from "./content/msk-health-content";
import { GRAPH_TYPES, GRAPH_TITLES } from "../constants/graphs.js";
import { STAGES } from "@/components/assessment/menstruation/report/constants/index.js";

export class MskHealthModule extends BaseTextModule {
  constructor() {
    super(MSK_HEALTH_CONTENT);
  }

  getMskHealthTitle() {
    return this.getText("title");
  }

  getGraphTitle(type) {
    if (Object.values(STAGES).includes(type)) {
      return GRAPH_TITLES.HORMONE_LEVELS;
    }

    if (type === GRAPH_TYPES.INJECTABLE_CONTRACEPTIVES) {
      return GRAPH_TITLES.CONTRACEPTIVE_INJECTIONS;
    }

    return "";
  }

  getAccordionContent(type) {
    return this.content.accordion[type] || [];
  }
}
