<template>
  <header
    class="messaging-header py-2 px-4 d-flex justify-space-between align-center"
  >
    <h3 class="text-overline text-body-dark">Messaging</h3>
    <messaging-header-actions />
  </header>
</template>

<script>
import MessagingHeaderActions from "@/components/messaging/layout/MessagingHeaderActions.vue";

export default {
  name: "MessagingHeader",
  components: { MessagingHeaderActions }
};
</script>

<style scoped lang="scss">
.messaging-header {
  border-bottom: 1px solid var(--vfl-border-color);
}
</style>
