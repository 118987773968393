import * as Constants from "@/constants/constants.js";

export const RedirectPages = {
  DESK_ASSESSMENT: "desk-assessment",
  WORKSPACE_RESILIENCE_ASSESSMENT: "workspace-resilience-assessment",
  DRIVER_ASSESSMENT: "driver-assessment",
  WELLNESS: "wellness",
  PHYSICAL_LABOUR_ASSESSMENT: "physical-labour-assessment",
  ANALYTICS: "analytics",
  PEOPLE: "people",
  USER_PROFILE: "user-profile",
  MS_TEAMS_DASHBOARD: "ms-teams-dashboard",
  MENOPAUSE_ASSESSMENT: "menopause-assessment",
  MENSTRUATION_ASSESSMENT: "menstruation-assessment"
};

export const Actions = {
  ASSESSMENT: { NEW: "new" },
  WELLNESS: {
    ALREADY_COMPLETED_DIALOG: "alreadyCompletedDialog",
    RECOMMENDATION_SECTION: "recommendationSection",
    GAME_SECTION: "gameSection",
    SHOW_EXERCISE_VIDEO: "showExerciseVideo"
  },
  MS_TEAMS: {
    AUTH: "submitAuth"
  },
  PEOPLE: {
    VIEW_REPORT: "viewReport"
  }
};

export const RedirectParameters = {
  PAGE: "redirectPage",
  ACTION: "redirectAction",
  ASSESSMENT_ID: "assessmentId",
  USER_ID: "userId"
};

export const RedirectStoreRoutes = {
  GETTERS: {
    REDIRECT_PAGE: "redirectStore/redirectPage",
    REDIRECT_DATA: "redirectStore/redirectData",
    REDIRECT_PARAMS: "redirectStore/redirectParams",
    REDIRECT_ACTION: "redirectStore/redirectAction"
  },
  MUTATIONS: {
    SET_REDIRECT_DATA: "redirectStore/setRedirectData"
  }
};

export const AssessmentRedirectPages = {
  [Constants.assessmentTypes.deskAssessment]: RedirectPages.DESK_ASSESSMENT,
  [Constants.assessmentTypes.burnoutAssessment]:
    RedirectPages.WORKSPACE_RESILIENCE_ASSESSMENT,
  [Constants.assessmentTypes.driverAssessment]: RedirectPages.DRIVER_ASSESSMENT,
  [Constants.assessmentTypes.physicalLabourAssessment]:
    RedirectPages.PHYSICAL_LABOUR_ASSESSMENT,
  [Constants.assessmentTypes.menopauseAssessment]:
    RedirectPages.MENOPAUSE_ASSESSMENT,
  [Constants.assessmentTypes.menstruationAssessment]:
    RedirectPages.MENSTRUATION_ASSESSMENT
};
