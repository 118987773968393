import {
  STAGE_MAPPING,
  STAGES
} from "@/components/assessment/menopause/report/constants/index.js";
import { capitaliseFirstLetter } from "@/components/assessment/menopause/report/helpers.js";
import i18n from "@/plugins/i18n.js";

const formatSymptomsList = symptoms => {
  const connector = i18n.t("form.generator.connector");

  if (!Array.isArray(symptoms) || symptoms.length === 0) return "";
  if (symptoms.length === 1) return symptoms[0];
  if (symptoms.length === 2) {
    return `${symptoms[0]} ${connector} ${symptoms[1]}`;
  }

  return `${symptoms.slice(0, -1).join(", ")}, ${connector} ${
    symptoms[symptoms.length - 1]
  }`;
};

const symptomToText = symptomId => {
  const symptomMap = {
    1: "hotFlashes",
    2: "fatigue",
    3: "moodChanges",
    4: "concentration",
    5: "memory",
    6: "wakingUp",
    7: "reducedStrength"
  };
  const translationKey = symptomMap[symptomId];
  return translationKey
    ? i18n.t(
        `menopauseAssessment.report.content.symptoms.symptomTypes.${translationKey}`
      )
    : "";
};

export const SYMPTOMS_CONTENT = {
  title: () => i18n.t("menopauseAssessment.report.content.symptoms.title"),
  getContent: results => {
    const stageKey = STAGE_MAPPING[results.stage?.stage] || STAGES.MENOPAUSE;
    const capitalizedStage = capitaliseFirstLetter(
      i18n.t(`menopauseAssessment.stages.${stageKey}`)
    );

    const symptoms = results.symptoms?.symptoms || [];

    const symptomMap = {
      1: "hotFlashes",
      2: "fatigue",
      3: "moodChanges",
      4: "concentration",
      5: "memory",
      6: "wakingUp",
      7: "reducedStrength",
      98: "other"
    };

    const filteredSymptoms = symptoms
      .filter(symptomId => symptomId !== 98 && symptomMap[symptomId])
      .slice(0, 3)
      .map(symptomId => symptomToText(symptomId));

    const formattedSymptoms = formatSymptomsList(filteredSymptoms);

    const translatedStrings = i18n.t(
      "menopauseAssessment.report.content.symptoms.content.mainText",
      {
        stage: capitalizedStage,
        symptoms: formattedSymptoms
      },
      { returnObjects: true }
    );

    return translatedStrings.map(text =>
      text
        .replace(/\{stage\}/g, capitalizedStage)
        .replace(/\{symptoms\}/g, formattedSymptoms)
    );
  }
};
