import { getMuscleStrengthRecommendations } from "./muscle-strength-recommendations.js";
import { getPainRecommendations } from "./pain-recommendations.js";
import { getSymptomsRecommendations } from "./symptoms-recommendations.js";

const recommendationMap = {
  pain: getPainRecommendations,
  muscleStrength: getMuscleStrengthRecommendations,
  symptoms: getSymptomsRecommendations
};

export const RecommendationSections = {
  PAIN: "pain",
  MUSCLE_STRENGTH: "muscleStrength",
  SYMPTOMS: "symptoms"
};

export const getRecommendationsByType = (type, results) => {
  const getRecommendations = recommendationMap[type];

  if (!getRecommendations) {
    throw new Error(`Unknown recommendation type: ${type}`);
  }

  return getRecommendations(results);
};

export const getAllRecommendations = results => ({
  pain: getPainRecommendations(results),
  symptoms: getSymptomsRecommendations(results),
  muscleStrength: getMuscleStrengthRecommendations(results)
});
