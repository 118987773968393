<template>
  <svg
    width="652"
    height="369"
    viewBox="0 0 652 369"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M109.5 336L109 336L109 336.5L109.5 336.5V336ZM109.854 19.6464C109.658 19.4511 109.342 19.4511 109.146 19.6464L105.964 22.8284C105.769 23.0237 105.769 23.3402 105.964 23.5355C106.16 23.7308 106.476 23.7308 106.672 23.5355L109.5 20.7071L112.328 23.5355C112.524 23.7308 112.84 23.7308 113.036 23.5355C113.231 23.3402 113.231 23.0237 113.036 22.8284L109.854 19.6464ZM602.354 336.354C602.549 336.158 602.549 335.842 602.354 335.646L599.172 332.464C598.976 332.269 598.66 332.269 598.464 332.464C598.269 332.66 598.269 332.976 598.464 333.172L601.293 336L598.464 338.828C598.269 339.024 598.269 339.34 598.464 339.536C598.66 339.731 598.976 339.731 599.172 339.536L602.354 336.354ZM110 336L110 20L109 20L109 336L110 336ZM109.5 336.5L602 336.5L602 335.5L109.5 335.5V336.5Z"
      fill="#5F7B7C"
    />
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="470" y="365.362">
        {{
          $t(
            "menopauseAssessment.report.content.mskHealth.graphs.injectable.monthsLabel"
          )
        }}
      </tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="148" y="365.362">
        {{
          $t(
            "menopauseAssessment.report.content.mskHealth.graphs.injectable.injectionLabel"
          )
        }}
      </tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="244" y="174.362">
        {{
          $t(
            "menopauseAssessment.report.content.mskHealth.graphs.injectable.progesteroneLabel"
          )
        }}
      </tspan>
    </text>
    <text
      transform="matrix(0 -1 1 0 49 320)"
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="0" y="14.362">
        {{
          $t(
            "menopauseAssessment.report.content.mskHealth.graphs.injectable.levelsLabel"
          )
        }}
      </tspan>
    </text>
    <path
      d="M149 297C149.333 264.5 151.2 194.7 156 175.5C162 151.5 161.5 160 172 175.5C176.199 181.698 180.237 187.816 184.5 193.598C190.897 202.276 196.798 203.698 205.5 210C209.854 213.153 217.147 223.257 225 227.054C243.297 235.903 270.063 245.803 300.5 253.5C335.3 262.3 480.333 286.166 548.5 297"
      stroke="#005F61"
      stroke-width="4"
    />
  </svg>
</template>

<script>
export default {
  name: "InjectableContraceptivesGraph"
};
</script>
