<template>
  <div :class="classList">
    <vfl-button
      v-if="!hideBackButton"
      data-test-id="dynamic-assessment-navigation-previous"
      @click="previous"
      variant="secondary"
    >
      {{ $t("buttons.back") }}
    </vfl-button>

    <div class="ml-auto">
      <vfl-button
        v-if="showSkipButton"
        data-test-id="dynamic-assessment-navigation-skip"
        @click="skip"
        variant="ghost"
      >
        {{ $t("buttons.skipIt") }}
      </vfl-button>

      <slot name="primary-action">
        <vfl-button
          v-if="!hideNextButton"
          data-test-id="dynamic-assessment-navigation-next"
          @click="next"
          :loading="loading"
          :disabled="hideBackButton"
        >
          {{ $t("buttons.continue") }}
        </vfl-button>
      </slot>
    </div>
  </div>
</template>

<script>
import VflButton from "@/components/vitrue-foundation-library/VflButton.vue";

export default {
  name: "Navigation",
  components: { VflButton },
  props: {
    loading: Boolean,
    classList: String,
    hideBackButton: Boolean,
    hideNextButton: Boolean,
    showSkipButton: Boolean
  },
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    },
    skip() {
      this.$emit("skip");
    }
  }
};
</script>

<style scoped lang="scss">
div {
  display: flex;
  gap: 0.5rem;
  max-width: 100%;
}

button {
  min-width: 10rem;
}
</style>
