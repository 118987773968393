import store from "@/store";
import {
  Actions,
  RedirectStoreRoutes
} from "@/services/authorization/url-redirect/redirect-constants.js";
import { routes } from "@/router/route-constants.js";

export async function handleMsTeamsRedirect() {
  const action = store.getters[RedirectStoreRoutes.GETTERS.REDIRECT_ACTION];

  switch (action) {
    case Actions.MS_TEAMS.AUTH:
      return {
        name: routes.MS_TEAMS_DASHBOARD,
        params: { submitAuthOnLoad: true }
      };
    default:
      return { name: routes.MS_TEAMS_DASHBOARD, params: {} };
  }
}
