<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{
      $t("menstruationAssessment.report.content.pain.title")
    }}</template>
    <template #content>
      <div class="content-wrapper">
        <div class="vfl-card-light py-4 px-8 pb-12">
          <h4 class="text-h6 text-center mb-8 text-body-darkest">
            {{ $t("menstruationAssessment.report.content.pain.painAreaTitle") }}
          </h4>
          <pain-person
            v-model="results.pain"
            disabled
            :painPersonMaxWidth="280"
          />
        </div>

        <report-info-card
          v-if="showCrampsCard"
          class="crampsCard"
          :title="$t('menstruationAssessment.report.content.pain.cramps.title')"
          illustration="/images/illustrations/period-cramps.svg"
          :text="$t('menstruationAssessment.report.content.pain.cramps.text')"
          :assessmentType="$options.assessmentTypes.menstruationAssessment"
        />
        <report-info-card
          :title="
            $t('menstruationAssessment.report.content.pain.general.title')
          "
          illustration="/images/illustrations/menopause-pain.png"
          :text="$t('menstruationAssessment.report.content.pain.general.text')"
          :assessmentType="$options.assessmentTypes.menstruationAssessment"
        />
      </div>
      <recommendation-container
        class="mt-10"
        :title="recommendationTitle"
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="painRecommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import ReportInfoCard from "@/components/assessment/common/report/ReportInfoCard.vue";
import PainPerson from "@/components/painareas/PainPerson.vue";
import { assessmentTypes } from "@/constants/constants.js";
import Answers from "@/components/assessment/menstruation/answers.json";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/menstruation/report/recommendations/recommendation-service.js";
import BodyAreas from "@/components/painareas/body-areas.json";

export default {
  name: "MenopauseReportPain",
  components: {
    PainPerson,
    ReportSection,
    ReportInfoCard,
    RecommendationContainer
  },
  data() {
    return { painRecommendations: {} };
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.painRecommendations = getRecommendationsByType(
      RecommendationSections.PAIN,
      this.results
    );
  },
  computed: {
    showCrampsCard() {
      return this.results?.symptoms?.menstruationSymptoms?.includes(
        Answers.symptoms.menstruationSymptoms.abdominalCramps
      );
    },
    recommendationTitle() {
      const painAreaKeys = Object.keys(this.results.pain.areas);
      if (painAreaKeys.length > 1) {
        return this.$t(
          "menstruationAssessment.report.recommendations.pain.title.generic"
        );
      }

      const translatedArea = this.$t(
        BodyAreas[painAreaKeys[0]].shortenedName
      ).toLowerCase();
      return this.$t(
        "menstruationAssessment.report.recommendations.pain.title.withArea",
        { area: translatedArea }
      );
    }
  }
};
</script>
<style scoped lang="scss">
.content-wrapper {
  $breakpoint-large: 1400px;
  $breakpoint-medium: 1000px;

  display: grid;
  grid-gap: 1rem;

  grid-template-columns: minmax(300px, 430px) 1fr;

  @media screen and (max-width: $breakpoint-medium) {
    grid-template-columns: 1fr;
  }

  &:has(.crampsCard) {
    grid-template-columns: minmax(300px, 430px) 1fr 1fr;

    @media screen and (max-width: $breakpoint-large) {
      grid-template-columns: 1fr 1fr;

      & > *:last-child {
        grid-column: span 2;
      }
    }

    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns: 1fr;

      & > *:last-child {
        grid-column: auto;
      }
    }
  }
}

.vfl-card-light {
  background: white;
}
</style>
