<template>
  <div class="d-flex align-center">
    <vfl-icon-button
      class="mr-4"
      @click="showFilters = !showFilters"
      isLight
      size="medium"
      :screenReader="$t('analytics.filter.filterByTag')"
    >
      <v-icon color="vflPrimary">mdi-filter-variant</v-icon>
    </vfl-icon-button>

    <transition name="slide-x-transition">
      <tag-filter
        v-if="showFilters"
        class="pt-0"
        label="analytics.filter.filterByTag"
        @input="onFilterTagsChange"
        :showNoTag="false"
      />
    </transition>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import VflIconButton from "@/components/vitrue-foundation-library/VflIconButton.vue";
import TagFilter from "@/components/common/assessment-history/TagFilter.vue";

export default {
  name: "DashboardCardsFilter",
  components: {
    VflIconButton,
    TagFilter
  },
  data() {
    return {
      showFilters: false
    };
  },
  methods: {
    ...mapMutations("adminDashboard", ["SET_FILTER_TAGS_IDS"]),
    onFilterTagsChange(newValue) {
      this.SET_FILTER_TAGS_IDS(newValue.map(({ id }) => id));
    }
  }
};
</script>
