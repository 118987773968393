import { getLatestRecommendationInteractions } from "@/customApi";
import {
  InteractionStates,
  AutoMarkAsDoingCardIds
} from "@/components/common/report/cards/Recommendation/recommendation-interaction-service.js";
import sentry from "@/sentry";

export default {
  data() {
    return {
      disableCongratulationsMessage: true,
      recommendationInteractions: [],
      InteractionStates: InteractionStates
    };
  },
  methods: {
    async getRecommendationInteractions(assessmentIds) {
      const ids = Array.isArray(assessmentIds)
        ? assessmentIds
        : [assessmentIds];

      try {
        const promises = ids.map(id => getLatestRecommendationInteractions(id));
        const results = await Promise.all(promises);
        this.recommendationInteractions = results.flat();
      } catch (err) {
        sentry.captureException(err);
      }
    },
    cardUpdated(e) {
      this.disableCongratulationsMessage = false;
      const interaction = this.recommendationInteractions.find(
        r => r.recommendation === e.id
      );
      if (interaction) {
        interaction.type = e.type;
        return;
      }

      this.recommendationInteractions.push({
        recommendation: e.id,
        type: e.type
      });
    },
    getCardState(id) {
      const interaction = this.recommendationInteractions.find(
        r => r.recommendation === id
      );

      if (!interaction && AutoMarkAsDoingCardIds.includes(id)) {
        return InteractionStates.ALREADY_DOING;
      }

      return interaction ? interaction.type : this.InteractionStates.NONE;
    }
  },
  computed: {
    completedCards() {
      return this.recommendationInteractions.filter(
        x =>
          x.type === InteractionStates.COMPLETED ||
          x.type === InteractionStates.ALREADY_DOING
      );
    }
  }
};
