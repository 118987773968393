<template>
  <div class="d-flex justify-center align-center flex-column">
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8828 41.0651L48.2992 14.4453H58.1789C59.7213 14.4499 61.1992 15.0646 62.2898 16.1554C63.3803 17.2461 63.9949 18.7241 63.9992 20.2664V46.6523C63.9947 48.1275 63.432 49.5463 62.4242 50.6236C61.4164 51.7008 60.0383 52.3568 58.5667 52.4596V61.2039L51.0696 52.4732H21.7033C20.161 52.4687 18.6831 51.854 17.5926 50.7634C16.502 49.6729 15.8873 48.195 15.8828 46.6527V41.0651Z"
        fill="#C2EDD7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.8205 2.80469H42.2955C43.8378 2.80917 45.3157 3.42384 46.4063 4.51443C47.4968 5.60501 48.1115 7.08287 48.116 8.62519V35.0121C48.1115 36.5544 47.4968 38.0322 46.4063 39.1228C45.3157 40.2134 43.8378 40.8281 42.2955 40.8326H12.9296L5.4325 49.5633V40.8192C3.96091 40.7165 2.58274 40.0606 1.57496 38.9833C0.567186 37.906 0.00450358 36.4872 0 35.0121L0 8.62556C0.00438614 7.08318 0.619012 5.60523 1.70961 4.51456C2.8002 3.42389 4.27812 2.80917 5.8205 2.80469Z"
        fill="#005F61"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4759 17.1417C11.1944 17.1438 10.9152 17.0903 10.6545 16.984C10.3938 16.8778 10.1566 16.721 9.95681 16.5227C9.75697 16.3244 9.59837 16.0884 9.49013 15.8286C9.3819 15.5687 9.32617 15.2899 9.32617 15.0084C9.32617 14.7268 9.3819 14.4481 9.49013 14.1882C9.59837 13.9283 9.75697 13.6924 9.95681 13.4941C10.1566 13.2958 10.3938 13.139 10.6545 13.0327C10.9152 12.9265 11.1944 12.8729 11.4759 12.8751H36.7209C37.0024 12.8729 37.2816 12.9265 37.5423 13.0327C37.803 13.139 38.0401 13.2958 38.24 13.4941C38.4398 13.6924 38.5984 13.9283 38.7066 14.1882C38.8149 14.4481 38.8706 14.7268 38.8706 15.0084C38.8706 15.2899 38.8149 15.5687 38.7066 15.8286C38.5984 16.0884 38.4398 16.3244 38.24 16.5227C38.0401 16.721 37.803 16.8778 37.5423 16.984C37.2816 17.0903 37.0024 17.1438 36.7209 17.1417H11.4759Z"
        fill="#FAFAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5286 29.1573C14.2471 29.1595 13.9679 29.1059 13.7072 28.9996C13.4465 28.8934 13.2094 28.7366 13.0095 28.5383C12.8097 28.34 12.6511 28.1041 12.5429 27.8442C12.4346 27.5843 12.3789 27.3055 12.3789 27.024C12.3789 26.7425 12.4346 26.4637 12.5429 26.2038C12.6511 25.9439 12.8097 25.708 13.0095 25.5097C13.2094 25.3114 13.4465 25.1546 13.7072 25.0484C13.9679 24.9421 14.2471 24.8885 14.5286 24.8907H33.6661C34.2291 24.895 34.7675 25.1217 35.1641 25.5213C35.5606 25.9209 35.7832 26.461 35.7832 27.024C35.7832 27.587 35.5606 28.1271 35.1641 28.5267C34.7675 28.9263 34.2291 29.153 33.6661 29.1573H14.5286Z"
        fill="#FAFAFA"
      />
    </svg>

    <h2 class="text-body-darkest">{{ $t("messaging.emptyState.title") }}</h2>
    <h4 class="vflNeutralDark1--text text-body-1">
      {{ description }}
    </h4>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MessagingEmptyState",
  computed: {
    ...mapGetters("userProfile", ["name"]),
    description() {
      if (this.name) {
        return this.$t("messaging.emptyState.description.name", {
          name: this.name
        });
      }

      return this.$t("messaging.emptyState.description.noName");
    }
  }
};
</script>

<style scoped lang="scss">
div {
  height: 100%;
}
</style>
