import { ImageService } from "@/components/assessment/common/dynamic-images/image-service.js";
import { Theme } from "./steps.js";

import StageBase from "@/components/assessment/common/dynamic-images/svgs/stage/StageBase.vue";
import StageUnanswered from "@/components/assessment/common/dynamic-images/svgs/stage/overlays/Unanswered.vue";
import StageSittingOnRock from "@/components/assessment/common/dynamic-images/svgs/stage/overlays/StageSittingOnRock.vue";
import StageOnRoadBeforeFlag from "@/components/assessment/common/dynamic-images/svgs/stage/overlays/StageOnRoadBeforeFlag.vue";
import StageOnRoadAfterFlag from "@/components/assessment/common/dynamic-images/svgs/stage/overlays/StageOnRoadAfterFlag.vue";
import StageSearchingOffRoad from "@/components/assessment/common/dynamic-images/svgs/stage/overlays/StageSearchingOffRoad.vue";
import StagePerimenopauseHormones from "@/components/assessment/common/dynamic-images/svgs/stage/overlays/PerimenopauseHormones.vue";
import StagePostmenopauseHormones from "@/components/assessment/common/dynamic-images/svgs/stage/overlays/PostmenopauseHormones.vue";
import SymptomsBase from "@/components/assessment/common/dynamic-images/svgs/symptoms/SymptomsBase.vue";
import SymptomsDifficultyConcentrating from "@/components/assessment/common/dynamic-images/svgs/symptoms/overlays/DifficultyConcentrating.vue";
import SymptomsHotFlashesNightSweating from "@/components/assessment/common/dynamic-images/svgs/symptoms/overlays/HotFlashesNightSweating.vue";
import SymptomsMemoryChanges from "@/components/assessment/common/dynamic-images/svgs/symptoms/overlays/MemoryChanges.vue";
import SymptomsMoodChanges from "@/components/assessment/common/dynamic-images/svgs/symptoms/overlays/MoodChanges.vue";
import SymptomsNightWaking from "@/components/assessment/common/dynamic-images/svgs/symptoms/overlays/NightWaking.vue";
import SymptomsReducedStrength from "@/components/assessment/common/dynamic-images/svgs/symptoms/overlays/ReducedStrength.vue";
import SymptomsTiredness from "@/components/assessment/common/dynamic-images/svgs/symptoms/overlays/Tiredness.vue";
import LifestyleBase from "@/components/assessment/common/dynamic-images/svgs/lifestyle/LifestyleBase.vue";
import LifestyleUnanswered from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/Unanswered.vue";
import LifestyleExerciseOnce from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/ExerciseOnce.vue";
import LifestyleExerciseTwice from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/ExerciseTwice.vue";
import LifestyleExerciseThreeMore from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/ExerciseThreeMore.vue";
import LifestyleExerciseNever from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/ExerciseNever.vue";
import LifestyleWeightlifting from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/Weightlifting.vue";

const baseComponents = {
  [Theme.STAGE]: StageBase,
  [Theme.SYMPTOMS]: SymptomsBase,
  [Theme.LIFESTYLE]: LifestyleBase
};

export class MenopauseImageService extends ImageService {
  registerComponents(vm) {
    const components = {
      StageBase,
      StageUnanswered,
      StageSittingOnRock,
      StageOnRoadBeforeFlag,
      StageOnRoadAfterFlag,
      StageSearchingOffRoad,
      StagePerimenopauseHormones,
      StagePostmenopauseHormones,
      SymptomsBase,
      SymptomsDifficultyConcentrating,
      SymptomsHotFlashesNightSweating,
      SymptomsMemoryChanges,
      SymptomsMoodChanges,
      SymptomsNightWaking,
      SymptomsReducedStrength,
      SymptomsTiredness,
      LifestyleBase,
      LifestyleUnanswered,
      LifestyleExerciseOnce,
      LifestyleExerciseTwice,
      LifestyleExerciseThreeMore,
      LifestyleExerciseNever,
      LifestyleWeightlifting
    };

    Object.entries(components).forEach(([name, component]) => {
      vm.$options.components[name] = component;
    });
  }

  getBaseComponent(schema) {
    const baseName = schema?.illustration?.base;
    if (baseName) {
      return baseName;
    }
    return baseComponents[this.currentTheme];
  }

  getOverlayComponents(currentTheme, results, schema) {
    switch (currentTheme) {
      case Theme.STAGE:
        return this.getStageOverlays(results, schema);
      case Theme.SYMPTOMS:
        return this.getSymptomsOverlays(results, schema);
      case Theme.LIFESTYLE:
        return this.getLifestyleOverlays(results, schema);
      default:
        return [];
    }
  }

  getStageOverlays(results, schema) {
    const overlays = [];
    const stageResults = results.stage;

    if (!stageResults.stage && schema?.illustration?.overlays) {
      return schema.illustration.overlays;
    }

    const stageQuestion = schema.questions[0];
    const selectedStage = stageResults.stage;

    if (selectedStage) {
      const stageOption = stageQuestion.options.find(
        opt => opt.value === selectedStage
      );
      this.addUniqueOverlays(overlays, stageOption?.illustration?.overlays);

      if (selectedStage === 2 || selectedStage === 3) {
        this.handleHormoneOverlays(
          overlays,
          selectedStage,
          stageResults,
          schema
        );
      }
    }

    return overlays;
  }

  handleHormoneOverlays(overlays, selectedStage, results, schema) {
    const injectablesQuestion = schema.questions.find(
      q => q.name === "contraceptiveInjectables"
    );
    if (results.contraceptiveInjectables === 1) {
      const injectablesOption = injectablesQuestion?.options?.find(
        opt => opt.value === 1
      );
      this.addUniqueOverlays(
        overlays,
        injectablesOption?.illustration?.overlays?.[selectedStage]
      );
    }

    const hrtQuestion = schema.questions.find(
      q => q.name === "hormoneReplacementTherapy"
    );
    if (results.hormoneReplacementTherapy === 1) {
      const hrtOption = hrtQuestion?.options?.find(opt => opt.value === 1);
      this.addUniqueOverlays(
        overlays,
        hrtOption?.illustration?.overlays?.[selectedStage]
      );
    }
  }

  getSymptomsOverlays(results, schema) {
    const selectedSymptoms = results?.symptoms?.symptoms || [];
    const symptomsQuestion = schema.questions[0];
    const overlays = [];

    selectedSymptoms.forEach(symptomValue => {
      const option = symptomsQuestion.options?.find(
        opt => opt.value === symptomValue
      );
      this.addUniqueOverlays(overlays, option?.illustration?.overlays);
    });

    return overlays;
  }

  getLifestyleOverlays(results, schema) {
    const overlays = [];
    const lifestyleResults = results.lifestyle;

    if (!lifestyleResults.physicalActivity && schema?.illustration?.overlays) {
      return schema.illustration.overlays;
    }

    this.handlePhysicalActivityOverlays(overlays, lifestyleResults, schema);
    this.handleStrengthExerciseOverlays(overlays, lifestyleResults, schema);

    return overlays;
  }

  handlePhysicalActivityOverlays(overlays, results, schema) {
    const physicalActivityQuestion = schema.questions[0];
    const selectedPhysicalActivity = results.physicalActivity;

    if (selectedPhysicalActivity) {
      const option = physicalActivityQuestion.options.find(
        opt => opt.value === selectedPhysicalActivity
      );
      this.addUniqueOverlays(overlays, option?.illustration?.overlays);
    }
  }

  handleStrengthExerciseOverlays(overlays, results, schema) {
    const strengthQuestion = schema.questions.find(
      q => q.name === "strengthExercise"
    );
    const selectedStrength = results.strengthExercise;

    if (selectedStrength === 1 || selectedStrength === 3) {
      const option = strengthQuestion?.options?.find(
        opt => opt.value === 1 || opt.value === 3
      );
      this.addUniqueOverlays(overlays, option?.illustration?.overlays);
    }
  }
}
