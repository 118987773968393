import { RedirectParameters } from "@/services/authorization/url-redirect/redirect-constants.js";

export const redirectStore = {
  namespaced: true,
  state: {
    redirectData: null
  },
  mutations: {
    setRedirectData(state, data) {
      state.redirectData = data;
    }
  },
  getters: {
    redirectData: state => state.redirectData || null,
    redirectPage: state =>
      state.redirectData?.[RedirectParameters.PAGE] || null,
    redirectAction: state =>
      state.redirectData?.[RedirectParameters.ACTION]
        ? state.redirectData[RedirectParameters.ACTION]
        : null,
    redirectParams: state => {
      if (!state.redirectData) return {};
      const params = { ...state.redirectData };
      delete params[RedirectParameters.PAGE];
      delete params[RedirectParameters.ACTION];
      return params;
    }
  }
};
