<template>
  <div class="graph-container">
    <svg viewBox="0 0 652 369" fill="none" xmlns="http://www.w3.org/2000/svg">
      <!-- axis -->
      <path
        d="M95.5 325L95 325L95 325.5L95.5 325.5V325ZM95.8535 8.64642C95.6583 8.45114 95.3417 8.45114 95.1464 8.64642L91.9645 11.8284C91.7692 12.0237 91.7692 12.3402 91.9645 12.5355C92.1597 12.7308 92.4763 12.7308 92.6716 12.5355L95.5 9.70706L98.3284 12.5355C98.5237 12.7308 98.8403 12.7308 99.0355 12.5355C99.2308 12.3402 99.2308 12.0237 99.0355 11.8284L95.8535 8.64642ZM588.354 325.354C588.549 325.158 588.549 324.842 588.354 324.646L585.172 321.464C584.976 321.269 584.66 321.269 584.464 321.464C584.269 321.66 584.269 321.976 584.464 322.172L587.293 325L584.464 327.828C584.269 328.024 584.269 328.34 584.464 328.536C584.66 328.731 584.976 328.731 585.172 328.536L588.354 325.354ZM96 325L96 8.99997L95 8.99997L95 325L96 325ZM95.5 325.5L588 325.5L588 324.5L95.5 324.5V325.5Z"
        fill="#5F7B7C"
      />

      <!-- graph line -->

      <path
        d="M139 286C139.333 253.5 141.2 183.7 146 164.5C152 140.5 151.5 149 162 164.5C166.199 170.698 170.237 176.816 174.5 182.598C180.897 191.276 186.798 192.698 195.5 199C199.854 202.153 207.147 212.257 215 216.054C233.297 224.903 260.063 234.803 290.5 242.5C325.3 251.3 470.333 275.166 538.5 286"
        stroke="#005F61"
        stroke-width="4"
      />

      <!-- line label -->
      <text
        fill="#2A3C3C"
        xml:space="preserve"
        style="white-space: pre"
        font-family="Manrope"
        font-size="14"
        font-weight="500"
        letter-spacing="0px"
      >
        <tspan x="244" y="174.362">
          {{
            $t(
              "menstruationAssessment.report.content.mskHealth.graphs.injectable.progesteroneLabel"
            )
          }}
        </tspan>
      </text>

      <!-- Y-axis label -->
      <text
        transform="matrix(0 -1 1 0 49 300)"
        fill="#2A3C3C"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        <tspan x="0" y="14.362">
          {{
            $t(
              "menstruationAssessment.report.content.mskHealth.graphs.injectable.levelsLabel"
            )
          }}
        </tspan>
      </text>

      <!-- X-axis labels -->
      <text
        fill="#2A3C3C"
        xml:space="preserve"
        style="white-space: pre"
        font-family="Manrope"
        font-size="14"
        font-weight="500"
        letter-spacing="0px"
      >
        <tspan x="470" y="355.362">
          {{
            $t(
              "menstruationAssessment.report.content.mskHealth.graphs.injectable.monthsLabel"
            )
          }}
        </tspan>
      </text>
      <text
        fill="#2A3C3C"
        xml:space="preserve"
        style="white-space: pre"
        font-family="Manrope"
        font-size="14"
        font-weight="500"
        letter-spacing="0px"
      >
        <tspan x="148" y="355.362">
          {{
            $t(
              "menstruationAssessment.report.content.mskHealth.graphs.injectable.injectionLabel"
            )
          }}
        </tspan>
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "InjectableContraceptivesGraph"
};
</script>

<style scoped lang="scss">
.graph-container {
  position: relative;
  width: 100%;
  aspect-ratio: 652 / 369; // original svg dimensions
}
</style>
