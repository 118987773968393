<template>
  <div class="report-section px-8 py-10" :style="{ backgroundColor }">
    <div class="content-wrapper">
      <h5 class="text-overline vflNeutralDark1--text">
        {{ $t("assessment.report.part") }} {{ sectionNumber }}
      </h5>
      <h2 class="text-h4 text-body-darkest mb-4"><slot name="title"></slot></h2>
      <p class="text-body-1 vflNeutralDark1--text">
        <slot name="description"></slot>
      </p>
      <div><slot name="content"></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportSection",
  props: {
    sectionNumber: Number,
    backgroundColor: String
  }
};
</script>
<style lang="scss" scoped>
.report-section {
  .content-wrapper {
    margin: 0 auto;
    max-width: 1320px;
  }
}
</style>
