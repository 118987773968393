import i18n from "@/plugins/i18n.js";
import {
  STAGES,
  STAGE_MAPPING
} from "@/components/assessment/menopause/report/constants/index.js";

export const HEADER_CONTENT = {
  getTitleText: results => {
    const titleKey =
      results?.stage?.stage >= 2 && results.stage.stage <= 3
        ? "menopauseAssessment.report.content.header.personalisedTitle"
        : "menopauseAssessment.report.content.header.defaultTitle";
    return i18n.t(titleKey);
  },
  getContentText: results => {
    const stageText = STAGE_MAPPING[results.stage.stage] || STAGES.MENOPAUSE;
    return i18n.t("menopauseAssessment.report.content.header.contentPrefix", {
      stage: stageText
    });
  }
};
