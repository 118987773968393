import i18n from "@/plugins/i18n.js";

import { GRAPH_TYPES } from "@/components/assessment/menstruation/report/constants/graphs.js";

const basePath = "menstruationAssessment.report.content.mskHealth";

export const MSK_HEALTH_CONTENT = {
  title: `${basePath}.title`,
  description: `${basePath}.description`,
  accordion: {
    [GRAPH_TYPES.PERIOD]: [
      {
        title: () => i18n.t(`${basePath}.stages.period.mskHealth.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.period.mskHealth.description`, [], {
            returnObjects: true
          })
      },
      {
        title: () => i18n.t(`${basePath}.stages.period.focus.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.period.focus.description`, [], {
            returnObjects: true
          })
      },
      {
        title: () => i18n.t(`${basePath}.stages.period.profile.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.period.profile.description`, [], {
            returnObjects: true
          })
      }
    ],
    [GRAPH_TYPES.FOLLICULAR]: [
      {
        title: () => i18n.t(`${basePath}.stages.follicular.mskHealth.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.follicular.mskHealth.description`, [], {
            returnObjects: true
          })
      },
      {
        title: () => i18n.t(`${basePath}.stages.follicular.focus.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.follicular.focus.description`, [], {
            returnObjects: true
          })
      },
      {
        title: () => i18n.t(`${basePath}.stages.follicular.profile.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.follicular.profile.description`, [], {
            returnObjects: true
          })
      }
    ],
    [GRAPH_TYPES.OVULATION]: [
      {
        title: () => i18n.t(`${basePath}.stages.ovulation.mskHealth.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.ovulation.mskHealth.description`, [], {
            returnObjects: true
          })
      },
      {
        title: () => i18n.t(`${basePath}.stages.ovulation.focus.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.ovulation.focus.description`, [], {
            returnObjects: true
          })
      },
      {
        title: () => i18n.t(`${basePath}.stages.ovulation.profile.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.ovulation.profile.description`, [], {
            returnObjects: true
          })
      }
    ],
    [GRAPH_TYPES.LUTEAL]: [
      {
        title: () => i18n.t(`${basePath}.stages.luteal.mskHealth.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.luteal.mskHealth.description`, [], {
            returnObjects: true
          })
      },
      {
        title: () => i18n.t(`${basePath}.stages.luteal.focus.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.luteal.focus.description`, [], {
            returnObjects: true
          })
      },
      {
        title: () => i18n.t(`${basePath}.stages.luteal.profile.title`),
        paragraphs: () =>
          i18n.t(`${basePath}.stages.luteal.profile.description`, [], {
            returnObjects: true
          })
      }
    ],
    [GRAPH_TYPES.INJECTABLE_CONTRACEPTIVES]: [
      {
        title: () =>
          i18n.t(`${basePath}.stages.injectableContraceptives.impact.title`),
        type: "list",
        items: () =>
          i18n
            .t(`${basePath}.stages.injectableContraceptives.impact.items`, [], {
              returnObjects: true
            })
            .map(text => ({ text }))
      },
      {
        title: () =>
          i18n.t(`${basePath}.stages.injectableContraceptives.focus.title`),
        paragraphs: () =>
          i18n.t(
            `${basePath}.stages.injectableContraceptives.focus.description`,
            [],
            { returnObjects: true }
          )
      },
      {
        title: () =>
          i18n.t(`${basePath}.stages.injectableContraceptives.profile.title`),
        paragraphs: () =>
          i18n.t(
            `${basePath}.stages.injectableContraceptives.profile.description`,
            [],
            { returnObjects: true }
          )
      }
    ]
  }
};
