export const Theme = {
  WELCOME: "welcome",
  STAGE: "stage",
  STAGE_OUTRO: "stageOutro",
  PAIN: "pain",
  PAIN_OUTRO: "painOutro",
  SYMPTOMS: "symptoms",
  SYMPTOMS_OUTRO: "symptomsOutro",
  LIFESTYLE: "lifestyle",
  REPORT: "report"
};

export const AssessmentSteps = {
  Welcome: {
    THEME: Theme.WELCOME,
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false,
    BACKGROUND: {
      backgroundColor: "#FFEDE8"
    }
  },
  Stage: {
    THEME: Theme.STAGE,
    STEPPER: true,
    STEPPER_GROUP: Theme.STAGE,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage: "linear-gradient(to bottom, #F8F5F0 66vh, #EEE8DF 66vh)"
    },
    DYNAMIC_IMAGE_CSS: {
      bottom: 0
    }
  },
  StageOutro: {
    THEME: Theme.STAGE_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.STAGE,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F8F5F0"
    }
  },
  Pain: {
    THEME: Theme.PAIN,
    STEPPER: true,
    STEPPER_GROUP: Theme.PAIN,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #F3F9FA 550px, #DBEEEF 550px)"
    }
  },
  PainOutro: {
    THEME: Theme.PAIN_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.PAIN,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F3F9FA"
    }
  },
  Symptoms: {
    THEME: Theme.SYMPTOMS,
    STEPPER: true,
    STEPPER_GROUP: Theme.SYMPTOMS,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #F7FBF3 550px, #DEE6D7 550px)"
    },
    DYNAMIC_IMAGE_CSS: {
      bottom: "auto",
      top: "calc(var(--topbarHeight) + 1rem)"
    }
  },
  SymptomsOutro: {
    THEME: Theme.SYMPTOMS_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.SYMPTOMS,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F7FBF3"
    }
  },
  Lifestyle: {
    THEME: Theme.LIFESTYLE,
    STEPPER: true,
    STEPPER_GROUP: Theme.LIFESTYLE,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #FDF9E6 560px, #F6EDBE 560px)"
    }
  },
  Report: {
    THEME: Theme.REPORT,
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false,
    BACKGROUND: {
      backgroundColor: "white"
    }
  }
};

export const THEME_ORDER = [
  Theme.WELCOME,
  Theme.STAGE,
  Theme.STAGE_OUTRO,
  Theme.PAIN,
  Theme.PAIN_OUTRO,
  Theme.SYMPTOMS,
  Theme.SYMPTOMS_OUTRO,
  Theme.LIFESTYLE,
  Theme.REPORT
];

export const getStepByIndex = index => {
  const steps = Object.values(AssessmentSteps);
  return index >= 0 && index < steps.length ? steps[index] : null;
};
