<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="218.038" cy="365.5" r="22.6176" fill="#F2575D" />
    <circle
      cx="218.034"
      cy="365.501"
      r="13.5705"
      fill="#F2575D"
      stroke="#C5CFD6"
      stroke-width="3.61881"
    />
  </svg>
</template>

<script>
export default {
  name: "AbdominalCrampsOverlay"
};
</script>
