<template>
  <div class="dynamic-image-container">
    <div class="dynamic-images">
      <component :is="baseComponent" v-if="baseComponent" />
      <div v-if="hasOverlays" class="dynamic-image-overlays">
        <component
          v-for="(overlay, index) in overlayComponents"
          :key="overlay"
          :is="overlay"
          :style="{ zIndex: index + 1 }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getImageService } from "./image-service-factory.js";

export default {
  name: "DynamicImage",
  props: {
    currentTheme: String,
    results: Object,
    schema: Object,
    assessmentType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imageService: null
    };
  },
  async created() {
    this.imageService = await getImageService(this.assessmentType);
    this.imageService.registerComponents(this);
  },
  computed: {
    baseComponent() {
      return this.imageService?.getBaseComponent(this.schema);
    },
    overlayComponents() {
      if (
        !this.imageService ||
        !this.currentTheme ||
        !this.results ||
        !this.schema?.questions
      ) {
        return [];
      }

      return this.imageService.getOverlayComponents(
        this.currentTheme,
        this.results,
        this.schema
      );
    },
    hasOverlays() {
      return this.overlayComponents?.length > 0;
    }
  }
};
</script>

<style scoped lang="scss">
.dynamic-image-container {
  // Above 1280px there is no offset
  // The maximum negative offset is -300
  left: clamp(-300px, calc((100vw - 1280px) / 2), 0px);
  position: fixed;

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
}

.dynamic-images {
  position: relative;
}

.dynamic-image-overlays {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  > svg:not(:first-child) {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
