<template>
  <svg
    width="652"
    height="369"
    viewBox="0 0 652 369"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <!-- Dynamic highlight region -->
      <rect
        :x="config.x"
        y="-9"
        :width="config.width"
        height="324"
        :fill="`url(#${gradientId})`"
        fill-opacity="0.2"
      />

      <!-- Phase label -->
      <text
        fill="#2A3C3C"
        :x="config.textX"
        y="14.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        {{
          $t(
            `menopauseAssessment.report.content.mskHealth.graphs.stages.${config.text}`
          )
        }}
      </text>

      <!-- Graph line -->
      <path
        d="M109 314C131.833 304.516 178.4 284.749 182 281.555C186.5 277.562 230.5 236.132 232 222.655C233.5 209.177 232 150.277 232 141.791C232 133.306 227.5 82.3919 285.5 75.4038C343.5 68.4156 376 89.3801 387 97.3666C398 105.353 431 122.824 442.5 192.705C454 262.587 452 270.074 467 278.56C482 287.046 540 298.027 605 296.031"
        stroke="#005F61"
        stroke-width="5"
        stroke-linecap="round"
      />

      <!-- Axes -->
      <path
        d="M109.5 316L109 316L109 316.5L109.5 316.5V316ZM109.854 -0.353577C109.658 -0.548859 109.342 -0.548859 109.146 -0.353577L105.964 2.8284C105.769 3.02365 105.769 3.34024 105.964 3.53549C106.16 3.73077 106.476 3.73077 106.672 3.53549L109.5 0.707062L112.328 3.53549C112.524 3.73077 112.84 3.73077 113.036 3.53549C113.231 3.34024 113.231 3.02365 113.036 2.8284L109.854 -0.353577ZM602.354 316.354C602.549 316.158 602.549 315.842 602.354 315.646L599.172 312.464C598.976 312.269 598.66 312.269 598.464 312.464C598.269 312.66 598.269 312.976 598.464 313.172L601.293 316L598.464 318.828C598.269 319.024 598.269 319.34 598.464 319.536C598.66 319.731 598.976 319.731 599.172 319.536L602.354 316.354ZM110 316L110 -3.05394e-05L109 -3.04957e-05L109 316L110 316ZM109.5 316.5L602 316.5L602 315.5L109.5 315.5V316.5Z"
        fill="#5F7B7C"
      />

      <!-- X-axis labels -->
      <text
        fill="#2A3C3C"
        x="109"
        y="340.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        0
      </text>
      <text
        fill="#2A3C3C"
        x="172"
        y="340.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        10
      </text>
      <text
        fill="#2A3C3C"
        x="239"
        y="340.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        20
      </text>
      <text
        fill="#2A3C3C"
        x="308"
        y="340.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        30
      </text>
      <text
        fill="#2A3C3C"
        x="377"
        y="340.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        40
      </text>
      <text
        fill="#2A3C3C"
        x="448"
        y="340.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        50
      </text>
      <text
        fill="#2A3C3C"
        x="517"
        y="340.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        60
      </text>
      <text
        fill="#2A3C3C"
        x="587"
        y="340.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        70
      </text>

      <!-- Axis titles -->
      <text
        fill="#2A3C3C"
        x="310"
        y="365.362"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        {{
          $t(
            "menopauseAssessment.report.content.mskHealth.graphs.stages.ageLabel"
          )
        }}
      </text>

      <text
        transform="matrix(0 -1 1 0 49 300)"
        fill="#2A3C3C"
        style="font-family: Manrope; font-size: 14px; font-weight: 500"
      >
        <tspan x="0" y="14.362">
          {{
            $t(
              "menopauseAssessment.report.content.mskHealth.graphs.stages.oestrogenLabel"
            )
          }}
        </tspan>
      </text>
    </g>

    <!-- Definitions -->
    <defs>
      <!-- Gradient for highlight -->
      <linearGradient
        :id="gradientId"
        :x1="config.x"
        y1="153"
        :x2="config.x + config.width"
        y2="153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#005F61" />
        <stop offset="1" stop-color="#005F61" stop-opacity="0" />
      </linearGradient>

      <!-- Clip path -->
      <clipPath id="clip0">
        <rect width="652" height="369" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
const Config = {
  PERIMENOPAUSE: {
    x: 386,
    width: 62,
    text: "perimenopause",
    textX: 391
  },
  MENOPAUSE: {
    x: 448,
    width: 41,
    text: "menopause",
    textX: 461
  },
  POSTMENOPAUSE: {
    x: 489,
    width: 113,
    text: "postmenopause",
    textX: 501
  }
};

export default {
  name: "MenopauseGraph",
  props: {
    stage: {
      type: String,
      default: "perimenopause"
    }
  },
  computed: {
    config() {
      return Config[this.stage?.toUpperCase()];
    },
    gradientId() {
      return `paint_${this.stage}`;
    }
  }
};
</script>
