<template>
  <div>
    <page-background
      v-if="currentStepObj.BACKGROUND && !loadingAssessment"
      :current-step="currentPageIndex"
      :backgrounds="pageBackgrounds"
    />

    <transition
      name="fade"
      mode="out-in"
      @after-leave="onDynamicAssessmentTransitionComplete"
    >
      <div
        v-if="
          !loadingReport &&
          currentTheme &&
          activeAsyncRequestsCount === 0 &&
          !loadingAssessment
        "
        :key="currentPageIndex"
        class="assessment-container"
        :class="containerClassList"
        :data-test-id="assessmentType"
      >
        <landing-page
          v-if="currentTheme === $options.steps.Welcome.THEME"
          :title="$t(`menopauseAssessment.landingPage.title`)"
          :description="$t('menopauseAssessment.landingPage.description')"
          imageSrc="/images/illustrations/woman-balance-illustration.svg"
          mobileImageSrc="/images/illustrations/woman-balance-illustration-mobile.svg"
          @next="onNext"
          class="assessment-full-width"
        />

        <dynamic-image
          v-if="hasDynamicImage"
          :schema="currentSchema"
          :currentTheme="currentTheme"
          :results="results"
          :assessmentType="assessmentType"
          class="assessment-dynamic-image"
          :style="currentStepObj.DYNAMIC_IMAGE_CSS"
        />

        <div
          v-if="currentTheme === $options.steps.Pain.THEME"
          ref="painPersonPlaceholder"
          class="pain-person-placeholder"
        ></div>

        <vfl-stepper
          class="mt-md-4 assessment-stepper"
          :class="{ 'is-full-width': currentStepObj.OUTRO }"
          v-if="currentStepObj.STEPPER"
          :steps="stepper"
          :stepStructure="stepStructure"
          :currentStep="currentStepperIndex"
          :currentTheme="currentTheme"
          compact
        />

        <assessment-form
          v-if="currentStepObj.FORM"
          :key="currentTheme"
          :schema="currentSchema"
          v-model="results[currentTheme]"
          :ref="sectionKey"
          :isPainTheme="currentTheme === $options.steps.Pain.THEME"
          :results="results"
          :currentTheme="currentTheme"
          :assessmentType="assessmentType"
          :class="[
            'assessment-form ml-0 mr-0',
            {
              'assessment-full-width':
                currentTheme === $options.steps.Pain.THEME
            }
          ]"
          :disable-question-numbering="true"
        />

        <outro-page
          v-if="currentStepObj.OUTRO"
          :results="results"
          :step="currentStepObj"
          class="assessment-full-width assessment-outro-page"
        />

        <menopause-report
          v-if="currentTheme === $options.steps.Report.THEME"
          :results="results"
          :assessmentId="assessmentId"
          reportContext="assessment"
          class="assessment-full-width"
          style="position: relative"
        />

        <navigation
          v-if="currentStepObj.NAVIGATION"
          @previous="onPrevious"
          @next="onNext"
          :loading="nextClicked"
          :classList="
            currentStepObj.FORM && $vuetify.breakpoint.mdAndUp
              ? 'justify-end'
              : 'justify-space-between'
          "
          class="assessment-navigation assessment-full-width"
        />
      </div>
      <assessment-loader
        v-else-if="loadingReport && !loadingAssessment"
        :trigger="loadingReport"
        @close="loadingReport = saving"
        :animation-data="loaderAnimationData"
        class="assessment-loader"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { assessmentTypes } from "@/constants/constants.js";
import AssessmentForm from "@/components/assessment/AssessmentForm.vue";
import VflStepper from "@/components/common/VflStepper.vue";
import _ from "lodash";
import SaveAssessment from "@/components/common/assessments/save-assessment-service.js";
import {
  addAssessmentRecommendations,
  updateAssessmentState
} from "@/customApi";
import LoadingService from "@/services/assessment-loading.js";
import { GetAssessmentResults } from "@/components/common/assessments/get-assessment-service.js";
import { getSchema } from "@/services/assessment/get-schema-service.js";
import Navigation from "@/components/assessment/common/Navigation.vue";
import LandingPage from "@/components/assessment/common/landing-page/LandingPage.vue";
import {
  MenopauseAssessmentSteps,
  getStepByIndex,
  Theme,
  THEME_ORDER
} from "@/components/assessment/menopause/steps.js";

import AssessmentLoader from "@/components/assessment/common/AssessmentLoader.vue";
import OutroPage from "@/components/assessment/menopause/outro-page/OutroPage.vue";
import DynamicImage from "@/components/assessment/common/dynamic-images/DynamicImage.vue";
import { sendEvent } from "@/services/analytics/analytics.js";
import PageBackground from "@/components/assessment/common/PageBackground.vue";
import MenopauseReport from "@/components/assessment/menopause/report/MenopauseReport.vue";
import {
  buildStepperStructure,
  getCurrentStepperIndex
} from "@/components/assessment/menopause/step-builder-service.js";
import { getAllRecommendations } from "@/components/assessment/menopause/report/recommendations/recommendation-service.js";
import animationData from "@/assets/json/lottie/woman-waiting.json";

const DEBUG_REPORT = false;

export default {
  name: "MenopauseAssessment",
  components: {
    MenopauseReport,
    PageBackground,
    DynamicImage,
    AssessmentLoader,
    AssessmentForm,
    VflStepper,
    Navigation,
    LandingPage,
    OutroPage
  },
  props: {
    assessmentId: String
  },
  steps: MenopauseAssessmentSteps,
  data() {
    return {
      assessmentType: assessmentTypes.menopauseAssessment,
      loaderAnimationData: animationData,
      currentPageIndex: 0,
      currentSchemaIndex: 0,
      stepper: null,
      stepStructure: null,
      results: {
        stage: {},
        pain: {
          areas: {}
        },
        symptoms: {},
        lifestyle: {}
      },
      loadingReport: false,
      loadingAssessment: true,
      saving: false,
      nextClicked: false
    };
  },
  created() {
    if (this.disableAssessments) {
      this.$router.push("/wellness");
    }

    this.schema = getSchema(assessmentTypes.menopauseAssessment);

    this.buildSteps();
  },
  mounted() {
    document.body.classList.add("is-dynamic-layout-assessment");

    const painPersonPlaceholderTop = 104;
    document.documentElement.style.setProperty(
      "--pain-person-placeholder-top",
      `${painPersonPlaceholderTop}px`
    );

    this.startAssessment();
  },
  beforeDestroy() {
    document.body.classList.remove("is-dynamic-layout-assessment");

    this.configureLayoutForAssessmentReport({
      condition: false,
      pageTitle: null
    });
  },
  computed: {
    ...mapGetters([
      "disableAssessments",
      "vitrueDeveloper",
      "activeAsyncRequestsCount",
      "enableMenopauseAssessment"
    ]),
    containerClassList() {
      if (this.isWelcome) return "";
      if (this.isStepOutro) return "is-step-outro-page";

      return `pt-4 mx-auto ${!this.isReport && "pb-4"}`;
    },
    currentStepObj() {
      if (DEBUG_REPORT) {
        return MenopauseAssessmentSteps["Report"];
      }

      return getStepByIndex(this.currentPageIndex);
    },
    currentTheme() {
      if (DEBUG_REPORT) {
        return Theme.REPORT;
      }

      return this.currentStepObj?.THEME || Theme.WELCOME;
    },
    currentThemeQuestions() {
      if (this.currentTheme === Theme.PAIN) return this.currentSchema;
      const schemaForTheme = this.schema[this.currentTheme];
      return {
        theme: this.currentTheme,
        questions: schemaForTheme?.questions || []
      };
    },
    currentSchema() {
      return this.schema[this.currentTheme];
    },
    currentStepperIndex() {
      const step = getCurrentStepperIndex(
        this.currentTheme,
        this.stepStructure,
        MenopauseAssessmentSteps
      );

      return step;
    },
    sectionKey() {
      return `${this.currentTheme}, page ${this.currentPageIndex}`;
    },
    onLastPage() {
      return this.currentTheme === Theme.LIFESTYLE;
    },
    isReport() {
      return this.currentTheme === Theme.REPORT;
    },
    isWelcome() {
      return this.currentTheme === Theme.WELCOME;
    },
    isStepOutro() {
      return this.currentStepObj.OUTRO;
    },
    hasSymptoms() {
      return (
        this.results.symptoms.symptoms?.length > 0 &&
        !this.results.symptoms.symptoms.includes(99)
      );
    },
    onlyHasOtherSymptom() {
      return (
        this.results.symptoms.symptoms?.length === 1 &&
        this.results.symptoms.symptoms.includes(98)
      );
    },
    hasDynamicImage() {
      return this.currentStepObj.DYNAMIC_IMAGE;
    },
    pageBackgrounds() {
      return Object.values(MenopauseAssessmentSteps).map(
        step => step.BACKGROUND
      );
    },
    isNotInMenopause() {
      return (
        this.results.stage?.stage === 1 || this.results.stage?.stage === 98
      );
    },
    reportIndex() {
      return THEME_ORDER.findIndex(theme => theme === Theme.REPORT);
    },
    shouldSkipToReport() {
      const nextStep = getStepByIndex(this.currentPageIndex + 1);
      return nextStep?.THEME === Theme.PAIN && this.isNotInMenopause;
    },
    shouldSkipOutroPage() {
      const nextStep = getStepByIndex(this.currentPageIndex + 1);

      const skipSymptomsOutro =
        nextStep?.THEME === Theme.SYMPTOMS_OUTRO &&
        (!this.hasSymptoms || this.onlyHasOtherSymptom);

      return skipSymptomsOutro;
    }
  },
  watch: {
    assessmentId(val) {
      if (val) {
        this.startAssessment();
      }
    },
    currentTheme: {
      handler(newTheme) {
        if (newTheme === this.$options.steps.Pain.THEME) {
          this.$nextTick(() => {
            this.setPainPersonPlaceholderPosition();
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("layout", ["configureLayoutForAssessmentReport"]),
    scrollTop() {
      window.scrollTo(0, 0);
    },
    onPrevious() {
      const currentThemeIndex = THEME_ORDER.indexOf(this.currentTheme);

      if (currentThemeIndex > 0) {
        let prevIndex = this.currentPageIndex - 1;
        const prevStep = getStepByIndex(prevIndex);

        const skipSymptomsOutro =
          prevStep.THEME === Theme.SYMPTOMS_OUTRO &&
          (!this.hasSymptoms || this.onlyHasOtherSymptom);
        if (skipSymptomsOutro) {
          prevIndex--;
        }

        this.currentPageIndex = prevIndex;
      }
    },
    async onNext() {
      if (this.nextClicked) return;

      try {
        this.nextClicked = true;

        await this.goToNextPage();
      } catch (e) {
        this.$logger.captureException(e);
      } finally {
        this.nextClicked = false;
      }
    },
    async goToNextPage() {
      if (!this.currentStepObj.VALIDATION || this.isValid()) {
        await this.saveAssessment();
        const currentThemeIndex = THEME_ORDER.indexOf(this.currentTheme);

        if (currentThemeIndex < THEME_ORDER.length - 1) {
          this.currentPageIndex = this.getNextIndex();
        }

        this.$mixpanel.track(assessmentTypes.menopauseAssessment, {
          step: `Go to ${this.sectionKey}`
        });
      }
    },
    getNextIndex() {
      if (this.shouldSkipToReport) {
        return this.reportIndex;
      }

      let nextIndex = this.currentPageIndex + 1;

      if (this.shouldSkipOutroPage) {
        nextIndex++;
      }

      return nextIndex;
    },
    buildSteps() {
      this.stepStructure = buildStepperStructure(MenopauseAssessmentSteps);
      this.stepper = this.stepStructure.length;
    },
    async startAssessment() {
      const setToOpened = true; // normally based on incomplete assessment dialog
      const getResults = false;

      const assessmentState = await LoadingService.getAssessmentStateAndResults(
        this.assessmentId,
        this.assessmentType,
        setToOpened,
        getResults
      );

      if (assessmentState.redirectObject) {
        this.$router.push(assessmentState.redirectObject);
        return;
      }

      if (!this.enableMenopauseAssessment) {
        this.$router.push("/wellness");
        return;
      }

      // load schema after retrieving feature flags - required due to schema injection based on feature flags
      this.schema = getSchema(assessmentTypes.menopauseAssessment);

      if (assessmentState.assessmentStarted) {
        this.results = await GetAssessmentResults(
          this.assessmentId,
          this.assessmentType,
          this.schema
        );
      }

      if (assessmentState.assessmentComplete) {
        const reportIndex = Object.values(MenopauseAssessmentSteps).findIndex(
          step => step.THEME === Theme.REPORT
        );
        this.currentPageIndex = reportIndex;
      }

      this.loadingAssessment = false;
    },
    async saveAssessment() {
      const nextIndex = this.getNextIndex();
      const nextPageIsReport = nextIndex === this.reportIndex; // to mark as complete

      this.saving = true;
      this.loadingReport = nextPageIsReport;

      try {
        if (nextPageIsReport && this.currentStepObj.OUTRO) {
          await updateAssessmentState(this.assessmentId, "Completed");
        } else {
          const results = this.results[this.currentTheme];
          if (results) {
            await SaveAssessment(
              this.assessmentId,
              results,
              this.assessmentType,
              this.currentThemeQuestions,
              null,
              nextPageIsReport
            );
          }
        }

        if (nextPageIsReport) {
          await this.saveRecommendations();
        }
      } catch (ex) {
        this.$logger.captureException(ex);
      } finally {
        this.saving = false;
      }
    },
    async saveRecommendations() {
      const recommendations = getAllRecommendations(this.results);
      const allRecommendationIds = [];

      Object.values(recommendations).forEach(section => {
        if (section.groups) {
          Object.values(section.groups).forEach(group => {
            if (group.cards) {
              group.cards.forEach(card => {
                allRecommendationIds.push(card.id);
              });
            }
          });
        }
      });

      const dto = {
        assessmentId: this.assessmentId,
        recommendations: allRecommendationIds
      };

      await addAssessmentRecommendations(dto);
    },
    isValid() {
      return this.$refs[this.sectionKey].isValid();
    },
    setPainPersonPlaceholderPosition() {
      if (this.$refs.painPersonPlaceholder) {
        const { top } =
          this.$refs.painPersonPlaceholder.getBoundingClientRect();
        const positionFromTop = top + window.scrollY;

        document.documentElement.style.setProperty(
          "--pain-person-placeholder-top",
          `${positionFromTop}px`
        );

        return positionFromTop;
      }

      return null;
    },
    onDynamicAssessmentTransitionComplete() {
      this.$emit("on-dynamic-assessment-transition-complete");
      this.scrollTop();
    }
  }
};
</script>
<style lang="scss" scoped>
.assessment-loader {
  position: relative;
  z-index: 1;
}

.assessment-container {
  grid-column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  max-width: 100%;

  @media screen and (min-width: 968px) {
    display: grid;
  }

  &.is-step-outro-page {
    max-width: 700px;
    margin: 0 auto;
    padding-top: 1rem;
  }
}

.assessment-full-width {
  grid-column: 1 / -1 !important;
}

.assessment-stepper {
  grid-column: 2;
  grid-row: 1;
  position: relative;

  &.is-full-width {
    grid-column: 1/-1;
  }
}

.assessment-form {
  grid-column: 2;
  margin-bottom: 6rem !important;
  position: relative;
}

.assessment-navigation {
  position: relative;
}

.pain-person-placeholder {
  grid-column: 1;
  grid-row: 2;
  height: 1px;
}

.assessment-outro-page {
  position: relative;
}

// Move these styles to the root level
::v-deep .two-column-form-layout {
  grid-template-columns: 1fr 1fr;
  grid-column: 1 / -1;
  grid-column-gap: 2rem;

  @media screen and (min-width: 968px) {
    display: grid;
  }

  // Keep title/header content in right column
  > div:not(.assessment-form-pain-container) {
    grid-column: 2;
  }

  // Make the pain container span full width
  > .assessment-form-pain-container {
    grid-column: 1 / -1;
  }
}

.assessment-dynamic-image {
  display: none;

  @media screen and (min-width: 968px) {
    display: block;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
