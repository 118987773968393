import MuscleLossRecommendations from "@/components/assessment/menopause/report/recommendations/MuscleLossRecommendations.json";
import { LinkTypes } from "@/services/recommendations/link-types";

const cardGroups = {
  muscleLoss: {
    hideTab: true,
    cards: {
      menopause_protein_intake: {
        showConditions: results => !exercisesAndWorksOut(results)
      },
      menopause_short_walk: {
        showConditions: results => !exercisesAndWorksOut(results)
      },
      menopause_bicep_curls: {
        showConditions: results => !exercisesAndWorksOut(results)
      },
      menopause_squat: {
        showConditions: results => !exercisesAndWorksOut(results)
      },
      menopause_protein_after_workout: {
        showConditions: results => exercisesAndWorksOut(results)
      },
      menopause_rest_day: {
        showConditions: results => exercisesAndWorksOut(results)
      },
      menopause_strength_training: {
        showConditions: results => exercisesAndWorksOut(results)
      }
    }
  }
};

const exercisesAndWorksOut = results => {
  return (
    (results.lifestyle.physicalActivity === 1 ||
      results.lifestyle.physicalActivity === 2 ||
      results.lifestyle.physicalActivity === 3) &&
    (results.lifestyle.strengthExercise === 1 ||
      results.lifestyle.strengthExercise === 3)
  );
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const getVisibleCardsForGroup = (groupCards, results) => {
  return Object.entries(groupCards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, MuscleLossRecommendations[cardId])
    );
};

const hasVisibleCards = (group, results) => {
  return Object.values(group.cards).some(card => card.showConditions(results));
};

export const getMuscleLossRecommendations = results => {
  const visibleGroups = Object.entries(cardGroups).reduce(
    (acc, [groupId, group]) => {
      if (hasVisibleCards(group, results)) {
        acc[groupId] = {
          titleKey: group.titleKey,
          cards: getVisibleCardsForGroup(group.cards, results),
          hideTab: group.hideTab
        };
      }
      return acc;
    },
    {}
  );

  return {
    groups: visibleGroups
  };
};
