<template>
  <div class="iframe-container d-flex flex-column">
    <iframe
      ref="gameFrame"
      :src="iframeSrc"
      class="flex-grow-1"
      allowfullscreen
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BankingAssessment",
  computed: {
    ...mapGetters(["showDemoAssessmentsInDashboardCards"]),
    iframeSrc() {
      return "https://embed.figma.com/proto/4eXZjjHTSLFVcTTc1voJYZ/Bank-worker-assessment?page-id=3349%3A63294&node-id=3349-72086&p=f&viewport=8147%2C6669%2C0.56&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=3349%3A72086&hotspot-hints=0&embed-host=share&hide-ui=1";
    }
  },
  created() {
    if (!this.showDemoAssessmentsInDashboardCards) {
      this.$router.push("/wellness");
    }
  },
  mounted() {
    document.body.classList.add("is-game-page");
  },
  beforeDestroy() {
    document.body.classList.remove("is-game-page");
  }
};
</script>

<style scoped lang="scss">
.iframe-container {
  min-height: calc(100vh - var(--topbar-height));

  iframe {
    border: none;
    display: block;
    height: 100%;
    width: 100%;
  }
}
</style>
