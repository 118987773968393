<template>
  <div>
    <feature-introduction-card
      v-if="showAdminFeedbackPopup"
      featureKey="AdminDashboardFeedback"
      imageLink="/images/illustrations/feedback-popup.svg"
      :title="$t('featureIntroductions.adminDashboardFeedback.title')"
      :description="
        $t('featureIntroductions.adminDashboardFeedback.description')
      "
      :actionButtonText="
        $t('featureIntroductions.adminDashboardFeedback.button')
      "
      :customCompleteFunction="openFeedbackSurvey"
    />
    <total-seats-warning-bar
      id="dashboardSeatWarning"
      class="pa-6"
      showCloseButton
    />

    <div class="mx-10 mb-4" v-if="showAccountBanner">
      <account-banner v-model="showAccountBanner" />
    </div>

    <v-dialog v-if="showWelcomeDialog" v-model="welcomeDialog">
      <AdminTour v-if="welcomeDialog" v-model="welcomeDialog" />
    </v-dialog>
    <v-dialog
      v-if="inviteDialog"
      v-model="inviteDialog"
      :width="inviteDialogWidth"
      persistent
      max-width="1020"
    >
      <invite-user-to-assessment-dialog
        v-if="inviteDialog"
        v-model="inviteDialog"
        :assessmentType="inviteAssessmentType"
      />
    </v-dialog>

    <dashboard-assessment-cards
      v-if="!deactivatedUser"
      class="mb-7"
      :loading="loading"
      @onAssessmentCardClicked="onAssessmentCardClicked"
    />
    <dashboard-deactivated-user-banner class="mb-7" v-else />

    <div
      :class="{
        'd-flex': !$vuetify.breakpoint.xs
      }"
    >
      <h3 class="text-h5 mr-5 d-flex align-center">
        {{ $t("dashboardTeamOverview.title") }}
      </h3>
      <dashboard-cards-filter class="summary-filter" />
    </div>

    <div class="dashboard-grid">
      <pain-overview class="pain-overview-section" />
      <div class="dashboard-subgrid">
        <pain-impact class="pain-impact-section" />
        <who-needs-attention
          v-if="!hideWhoNeedsAttentionTable"
          class="who-needs-attention-section"
        />
      </div>
      <msk-health-culture class="msk-health-culture-section" />
      <average-scores class="average-scores-section" />
    </div>

    <AiAssistantContainer
      v-if="showDashboardAssistant"
      :type="$options.assistantTypes.STRATEGY"
    />

    <vfl-sidepanel
      :is-open="sidePanelActive"
      @update:is-open="handleSidePanelChange"
      @onCloseComplete="handleSidePanelCloseComplete"
      @onBackClick="handleSidePanelBackClick"
      :show-back-button="showSidePanelBackButton"
      :title="sidePanelTitle"
      :width="showUserProfileMessaging ? '800px' : '420px'"
    >
      <template
        #headerAction
        v-if="sidePanelViewMode !== 'list' && selectedPersonId"
      >
        <user-profile-open-full-page-button :user-id="selectedPersonId" />
      </template>
      <template v-if="showUserProfileMessaging" #left
        ><user-profile-messaging layout="sidepanel"
      /></template>
      <template #right
        ><user-list v-if="sidePanelViewMode === 'list'" />
        <user-profile :user-id="selectedPersonId" layout="sidepanel"
      /></template>
    </vfl-sidepanel>
  </div>
</template>

<script>
import AccountBanner from "@/components/common/AccountBanner.vue";
import DashboardAssessmentCards from "@/components/dashboard/assessment-cards/DashboardAssessmentCards.vue";
import DashboardDeactivatedUserBanner from "@/components/dashboard/DashboardDeactivatedUserBanner.vue";
const InviteUserToAssessmentDialog = () =>
  import("./InviteDialog/InviteUserToAssessmentDialog.vue");
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getTeamMember } from "@/customApi";
import { Auth } from "aws-amplify";
import AdminTour from "@/components/account/onboarding/AdminTour.vue";
import { updateCheckListFromFeatures } from "@/services/onboarding/adminOnboarding";
import TeamService from "@/services/teamService.js";
import FeatureService from "@/services/new-feature-service.js";
import TotalSeatsWarningBar from "@/components/dashboard/TotalSeatsWarningBar.vue";
import * as Constants from "@/constants/constants.js";
import NewFeatureService from "@/services/new-feature-service.js";
import FeatureIntroductionCard from "@/components/common/FeatureIntroductionCard.vue";
import AiAssistantContainer from "@/components/AiAssistant/AiAssistantContainer.vue";
import { AssistantTypes } from "@/components/AiAssistant/constants.js";
import WhoNeedsAttention from "@/components/dashboard/tables/WhoNeedsAttention.vue";
import UserProfile from "@/components/user-profile/UserProfile.vue";
import VflSidepanel from "@/components/vitrue-foundation-library/VflSidepanel.vue";
import PainOverview from "@/components/dashboard/cards/pain-overview/PainOverview.vue";
import PainImpact from "@/components/dashboard/cards/pain-impact/PainImpact.vue";
import UserList from "@/components/user-list/UserList.vue";
import UserProfileMessaging from "@/components/user-profile/UserProfileMessaging.vue";
import { ViewMode } from "@/components/dashboard/side-panel-store.js";
import AverageScores from "@/components/dashboard/cards/average-scores/AverageScores.vue";
import MskHealthCulture from "@/components/dashboard/cards/msk-health-culture/MskHealthCulture.vue";
import UserProfileOpenFullPageButton from "@/components/user-profile/UserProfileOpenFullPageButton.vue";
import DashboardCardsFilter from "@/components/dashboard/cards/filter/DashboardCardsFilter.vue";

export default {
  name: "Dashboard",
  components: {
    UserProfileOpenFullPageButton,
    MskHealthCulture,
    UserProfileMessaging,
    UserList,
    VflSidepanel,
    UserProfile,
    WhoNeedsAttention,
    AccountBanner,
    DashboardAssessmentCards,
    InviteUserToAssessmentDialog,
    AdminTour,
    FeatureIntroductionCard,
    TotalSeatsWarningBar,
    DashboardDeactivatedUserBanner,
    AiAssistantContainer,
    PainOverview,
    PainImpact,
    AverageScores,
    DashboardCardsFilter
  },
  data() {
    return {
      welcomeDialog: true,
      loading: true,
      showAccountBanner: false,
      assessmentCardClicked: ""
    };
  },
  assistantTypes: AssistantTypes,
  computed: {
    ...mapGetters({
      currentUserTeamAndSubscriptionBasicInformation:
        "currentUserTeamAndSubscriptionBasicInformation",
      userName: "userName",
      teamMemberId: "teamMemberId",
      deactivatedUser: "deactivatedUser",
      featureIntros: "featureIntros",
      isTrialUser: "isTrialUser",
      subscriptionHasExpired: "subscriptionHasExpired",
      isSuperAdmin: "isSuperAdmin",
      hideWhoNeedsAttentionTable: "hideWhoNeedsAttentionTable",
      adminId: "userId",
      enableMessagingForDemo: "enableMessagingForDemo",
      userId: "userId"
    }),
    ...mapGetters("ai", ["assistantType"]),
    ...mapGetters("sidePanelStore", {
      sidePanelActive: "active",
      selectedPersonId: "selectedPersonId",
      sidePanelViewMode: "viewMode",
      sidePanelHeading: "heading",
      showSidePanelBackButton: "showBackButton"
    }),
    inviteDialog: {
      get() {
        return this.assessmentCardClicked;
      },
      set() {
        this.assessmentCardClicked = "";
      }
    },
    showDashboardAssistant() {
      return this.isSuperAdmin && this.assistantType !== AssistantTypes.REPORT;
    },
    showWelcomeDialog() {
      return (
        !!this.currentUserTeamAndSubscriptionBasicInformation &&
        this.currentUserTeamAndSubscriptionBasicInformation.isNew
      );
    },
    inviteDialogWidth() {
      return this.$vuetify.breakpoint.mdAndDown ? "90%" : "70%";
    },
    inviteAssessmentType() {
      const validTypes = Object.values(Constants.assessmentTypes);

      return validTypes.includes(this.assessmentCardClicked)
        ? this.assessmentCardClicked
        : "";
    },
    showAdminFeedbackPopup() {
      const englishLocale = this._i18n.locale.includes("en");
      if (!englishLocale) {
        return false;
      }

      const shouldShowCustomRecs = NewFeatureService.showFeature(
        "AdminDashboardFeedback"
      );
      return shouldShowCustomRecs;
    },
    sidePanelTitle() {
      return this.sidePanelViewMode === ViewMode.LIST
        ? this.sidePanelHeading
        : "";
    },
    showUserProfileMessaging() {
      return (
        this.sidePanelViewMode !== ViewMode.LIST &&
        this.enableMessagingForDemo &&
        this.selectedPersonId !== this.userId
      );
    }
  },
  async mounted() {
    this.updatePageTitle();

    try {
      let teamMberInfo = await TeamService.getTeamMemberInfo();
      await this.getUserTeamAndSubscriptionBaseInformation({
        memberId: this.teamMemberId,
        forceToUpdateExistedInformation: true
      });

      // TODO: add back in this.IsTrialUser to show account banner (hidden for week of 17/06/24)
      this.showAccountBanner = this.subscriptionHasExpired;

      let teamMember = await getTeamMember(this.teamMemberId);

      if (teamMember.disabledAt !== null) {
        Auth.signOut().then(() => {
          this.resetLoginState();
          this.$router.push("/", () => {});
        });
      }

      let featureIntrosNeedsLoading =
        !this.featureIntros ||
        (this.featureIntros &&
          (this.featureIntros.length == 0 ||
            this.featureIntros[0].userId !== teamMberInfo.userId));

      if (featureIntrosNeedsLoading) {
        await FeatureService.getActiveFeatureIntros();
      }

      updateCheckListFromFeatures(this.featureIntros);
    } catch (err) {
      this.$logger.captureException(err);
    } finally {
      this.loading = false;
    }
  },
  destroyed() {
    this.resetSidePanelState();
  },
  methods: {
    ...mapActions("layout", ["setPageTitle"]),
    ...mapActions([
      "getUserTeamAndSubscriptionBaseInformation",
      "resetLoginState"
    ]),
    ...mapMutations(["setTeamMemberId"]),
    ...mapActions("sidePanelStore", {
      closeSidePanel: "close",
      resetSidePanelState: "reset",
      sidePanelGoBack: "goBackToList"
    }),
    ...mapActions("userProfile", { resetUserProfile: "reset" }),
    ...mapActions("messagingStore", ["clearMessages"]),
    onAssessmentCardClicked(newValue) {
      this.assessmentCardClicked = newValue;
    },
    openFeedbackSurvey() {
      window.open("https://tally.so/r/w24EOL", "_blank");
    },
    scrollToDashboardElement(id) {
      const element = document.getElementById(id);
      if (element) {
        const headerOffset = 64;
        const elementPosition = element.getBoundingClientRect().top;

        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    },
    handleSidePanelChange(isOpen) {
      if (!isOpen) {
        this.closeSidePanel();
      }
    },
    handleSidePanelCloseComplete() {
      this.resetSidePanelState();
      this.clearMessages();
    },
    handleSidePanelBackClick() {
      this.resetUserProfile();
      this.sidePanelGoBack();
    },
    updatePageTitle() {
      if (typeof this.userName === "string" && this.userName.trim()) {
        const firstName = this.userName.trim().split(/\s+/)[0];
        this.setPageTitle(
          this.$t("pageTitle.welcomeUser", { name: firstName })
        );
      } else {
        this.setPageTitle(this.$t("pageTitle.welcome"));
      }
    }
  },
  watch: {
    inviteDialog: function (newVal) {
      if (newVal === true) {
        return;
      }

      this.getUserTeamAndSubscriptionBaseInformation({
        memberId: this.teamMemberId,
        forceToUpdateExistedInformation: true
      });
    }
  }
};
</script>

<style scoped lang="scss">
.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 3rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.dashboard-subgrid {
  display: grid;
  grid-row-gap: 2rem;

  .who-needs-attention-section {
    align-self: end;
  }
}

.average-scores-section,
.msk-health-culture-section {
  grid-column: 1/-1;
}

@container page-container (max-width: 1000px) {
  .dashboard-grid {
    display: block;

    > * + * {
      margin-top: 3rem !important;
    }
  }
}

@media screen and (max-width: 1100px) {
  @-moz-document url-prefix() {
    .dashboard-grid {
      display: block;

      > * + * {
        margin-top: 3rem !important;
      }
    }
  }
}

.summary-filter {
  max-width: 300px;
}
</style>
