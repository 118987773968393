<template>
  <div :class="['vfl-carousel', instanceClass]">
    <div class="carousel-container" ref="container">
      <div
        class="carousel-scroller vfl-scrollbar-hidden"
        ref="scrollContent"
        :class="{
          'has-gradient-left': !isScrolledFarLeft && showGradient,
          'has-gradient-right': !isScrolledFarRight && showGradient
        }"
      >
        <slot></slot>
      </div>

      <nav>
        <button
          @click="scrollLeft"
          :class="{ 'is-disabled': isScrolledFarLeft }"
        >
          <span class="d-sr-only">{{
            $t("dashboardAssessmentCards.navigation.prev")
          }}</span>
          <v-icon color="vflPrimary" size="40">mdi-chevron-left</v-icon>
        </button>
        <button
          @click="scrollRight"
          :class="{ 'is-disabled': isScrolledFarRight }"
        >
          <span class="d-sr-only">{{
            $t("dashboardAssessmentCards.navigation.next")
          }}</span>
          <v-icon color="vflPrimary" size="40">mdi-chevron-right</v-icon>
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "VflCarousel",
  props: {
    scrollAmount: {
      type: Number,
      default: 320
    },
    showGradient: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isScrolledFarLeft: true,
      isScrolledFarRight: false,
      instanceId: null,
      contentObserver: null,
      visibilityObserver: null
    };
  },
  computed: {
    instanceClass() {
      return `vfl-carousel-${this.instanceId}`;
    },
    resizeListenerKey() {
      return `resize-listener-${this.instanceId}`;
    }
  },
  created() {
    this.instanceId = this.generateGuid();
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeCarousel();
      this.initializeVisibilityObserver();
    });
  },
  beforeDestroy() {
    this.cleanup();
  },
  methods: {
    initializeCarousel() {
      if (this.$refs?.scrollContent) {
        this.$refs.scrollContent.addEventListener("scroll", this.handleScroll);

        this.contentObserver = new MutationObserver(this.handleContentChanges);
        this.contentObserver.observe(this.$refs.scrollContent, {
          childList: true,
          subtree: true,
          characterData: true
        });
      }

      this[this.resizeListenerKey] = debounce(() => {
        this.handleResize();
      }, 200);

      window.addEventListener("resize", this[this.resizeListenerKey]);

      this.updateNavigationButtonVisibility();
    },
    initializeVisibilityObserver() {
      this.visibilityObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.$nextTick(() => {
                this.updateNavigationButtonVisibility();
              });
            }
          });
        },
        { threshold: 0.1 }
      );

      if (this.$refs.container) {
        this.visibilityObserver.observe(this.$refs.container);
      }
    },

    cleanup() {
      if (this.$refs?.scrollContent) {
        this.$refs.scrollContent.removeEventListener(
          "scroll",
          this.handleScroll
        );
      }

      if (this.contentObserver) {
        this.contentObserver.disconnect();
        this.contentObserver = null;
      }

      if (this.visibilityObserver) {
        this.visibilityObserver.disconnect();
        this.visibilityObserver = null;
      }

      window.removeEventListener("resize", this[this.resizeListenerKey]);
    },
    handleContentChanges() {
      this.$nextTick(() => {
        this.updateNavigationButtonVisibility();
      });
    },
    generateGuid() {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < 6; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
    handleScroll() {
      this.updateNavigationButtonVisibility();
    },
    updateNavigationButtonVisibility() {
      const container = this.$refs.scrollContent;
      if (!container) return;

      const threshold = 1;

      this.isScrolledFarRight =
        Math.abs(
          container.scrollLeft - (container.scrollWidth - container.clientWidth)
        ) < threshold;
      this.isScrolledFarLeft = container.scrollLeft === 0;
    },
    scrollLeft() {
      const container = this.$refs.scrollContent;
      if (container) {
        container.scrollLeft -= this.scrollAmount;
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContent;
      if (container) {
        container.scrollLeft += this.scrollAmount;
      }
    },
    handleResize() {
      this.updateNavigationButtonVisibility();
    },
    scrollToIndex(index) {
      const container = this.$refs.scrollContent;
      if (!container || !container.children[index]) return;

      const element = container.children[index];
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel-container {
  position: relative;
}

.carousel-scroller {
  display: flex;
  gap: 1.5rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 1rem 0;

  &:before,
  &:after {
    bottom: 0;
    content: "";
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 50px;
    z-index: 3;
  }

  &.has-gradient-left:before {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), white);
    opacity: 1;
    left: -1px;
  }

  &.has-gradient-right:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white);
    opacity: 1;
    right: -1px;
  }
}

nav {
  button {
    background: var(--v-vflAquaLight2-base);
    border-radius: 9999px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1);
    transition: opacity 0.05s ease-out, transform 0.075s ease-out;
    z-index: 3;

    &:first-child {
      left: 0;

      i {
        left: -1px;
        position: relative;
      }
    }

    &:last-child {
      right: 0;

      i {
        position: relative;
        right: -1px;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0;
      transform: translateY(-50%) scale(0.9);
    }
  }
}

.vfl-scrollbar-hidden {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.d-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
</style>
