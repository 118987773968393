import store from "@/store";
import { startNewAssessment } from "@/customApi.js";
import {
  Actions,
  RedirectStoreRoutes
} from "@/services/authorization/url-redirect/redirect-constants.js";
import { assessmentRoutes } from "@/router/route-constants.js";
import * as sentry from "@sentry/browser";

export async function handleAssessmentRedirect(type) {
  const action = store.getters[RedirectStoreRoutes.GETTERS.REDIRECT_ACTION];
  const params = store.getters[RedirectStoreRoutes.GETTERS.REDIRECT_PARAMS];

  switch (action) {
    case Actions.ASSESSMENT.NEW:
      return await redirectToNewAssessment(
        store.getters.userEmail,
        type,
        params.teamId || store.getters.teamId
      );
    default:
      throw new Error(
        `${type} action is not handled in handleAssessmentRedirect`
      );
  }
}

async function redirectToNewAssessment(email, type, teamId) {
  let routeObject = null;
  try {
    let invitation = {
      email: { value: email },
      assessmentType: type
    };
    let id = await startNewAssessment(invitation, teamId);
    routeObject = {
      name: assessmentRoutes[type],
      params: {
        assessmentId: id
      }
    };
  } catch (err) {
    sentry.captureException(err);
  } finally {
    store.commit(RedirectStoreRoutes.MUTATIONS.SET_REDIRECT_DATA, null);
    return routeObject;
  }
}
