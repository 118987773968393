import {
  STAGES,
  TREATMENT_TYPES
} from "@/components/assessment/menopause/report/constants/index.js";

export const GRAPH_TYPES = {
  ...STAGES,
  ...TREATMENT_TYPES
};

export const GRAPH_TITLES = {
  HORMONE_LEVELS:
    "menopauseAssessment.report.content.mskHealth.stages.menopause.graphTitle",
  CONTRACEPTIVE_INJECTIONS:
    "menopauseAssessment.report.content.mskHealth.stages.injectableContraceptives.graphTitle",
  HRT: "menopauseAssessment.report.content.mskHealth.stages.hrt.graphTitle"
};
