<template>
  <div class="report-graph-card">
    <h4
      class="text-body-darkest mb-8"
      :class="$vuetify.breakpoint.lgAndUp ? 'text-h4' : 'text-h6'"
    >
      {{ $t(title) }}
    </h4>
    <menstruation-graph v-if="isStageGraph" :stage="type" />
    <injectable-contraceptives-graph
      v-else-if="isInjectableContraceptiveGraph"
    />
  </div>
</template>

<script>
import MenstruationGraph from "@/components/assessment/menstruation/report/graphs/MenstruationStageGraph.vue";
import InjectableContraceptivesGraph from "@/components/assessment/menstruation/report/graphs/InjectableContraceptivesGraph.vue";
import {
  STAGES,
  TREATMENT_TYPES
} from "@/components/assessment/menstruation/report/constants/index.js";
import { GRAPH_TYPES } from "@/components/assessment/menstruation/report/constants/graphs.js";
import { menstruationReportTextService } from "../text-service";

export default {
  name: "MenstruationReportGraph",
  components: {
    InjectableContraceptivesGraph,
    MenstruationGraph
  },
  props: {
    type: {
      type: String,
      default: STAGES.PERIOD,
      validator: value => Object.values(GRAPH_TYPES).includes(value)
    }
  },
  computed: {
    isStageGraph() {
      return Object.values(STAGES).includes(this.type);
    },
    isInjectableContraceptiveGraph() {
      return this.type === TREATMENT_TYPES.INJECTABLE_CONTRACEPTIVES;
    },
    title() {
      return menstruationReportTextService.getGraphTitle(this.type);
    }
  }
};
</script>

<style scoped lang="scss">
.report-graph-card {
  background-color: var(--v-vflBeigeLight2-base);
  border-radius: 16px;
  padding: 2rem 1rem;

  h4 {
    margin-bottom: 1rem;
    padding: 0 4rem 0 6rem;

    @media screen and (max-width: 1200px) {
      padding: 0 2rem 0 3rem;
    }
  }

  ::v-deep {
    svg {
      min-width: 180px;
      width: 100%;
    }
  }
}
</style>
