export default {
  YES_NO_QUESTION: { YES: 1, NO: 2 },
  SOMETIMES: 3,
  NONE_ANSWER_KEY: "noneOfThese",
  NONE_ANSWER_VALUE: 99,
  GENDER: {
    FEMALE: 1,
    MALE: 2,
    OTHER: 3,
    PREFER_NOT_TO_SAY: 4
  },
  PAIN: {
    ACTIVITY: { BETTER: 1, WORSE: 2, SAME: 3 },
    DURATION: {
      UP_TO_SEVEN_DAYS: 1,
      ONE_WEEK_TO_ONE_MONTH: 2,
      ONE_TO_THREE_MONTHS: 3,
      MORE_THAN_THREE_MONTHS: 4
    }
  },
  HEALTH: {
    EXERCISE: {
      NEVER: 0,
      ONCE_A_WEEK: 1,
      TWICE_A_WEEK: 2,
      THREE_OR_MORE_TIMES_A_WEEK: 3
    }
  },
  DRIVER: {
    VEHICLE_TYPE: { CAR: 1, VAN: 2, TRUCK: 3 },
    TRANSMISSION: { MANUAL: 1, AUTOMATIC: 2, ELECTRIC: 3 },
    ELBOWS_STEERING: { SLIGHTLY_BENT: 1, STRAIGHT: 2, BENT: 3 },
    JOURNEY_LENGTH: {
      LESS_THAN_ONE_HOUR: 1,
      ONE_TO_TWO_HOURS: 2,
      MORE_THAN_2_HOURS: 3
    },
    DRIVE_LENGTH: {
      HOUR: 1,
      TWO_HOURS: 2,
      TWO_TO_FOUR_HOURS: 3,
      MORE_THAN_4_HOURS: 4
    },
    BREAK_LENGTH: {
      LESS_THAN_FIFTEEN_MINS: 1,
      FIFTEEN_TO_THIRTY_MINS: 2,
      THIRTY_TO_SIXTY_MINS: 3,
      MORE_THAN_SIXTY_MINS: 4
    }
  }
};
