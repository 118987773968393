import i18n from "@/plugins/i18n.js";
import Answers from "@/components/assessment/menstruation/answers.json";

export class OutroService {
  getStageContent({ menstruationPhase, usingContraceptiveInjectables }) {
    const phaseKey = {
      [Answers.stage.menstruationPhase.follicular]: "follicular",
      [Answers.stage.menstruationPhase.ovulation]: "ovulation",
      [Answers.stage.menstruationPhase.luteal]: "luteal",
      98: "notSure"
    }[menstruationPhase];

    return {
      title: `menstruationAssessment.outroPage.stage.heading.${phaseKey}`,
      paragraphs: [
        ...this.getTranslationArray(
          `menstruationAssessment.outroPage.stage.${phaseKey}`
        ),
        usingContraceptiveInjectables === 1
          ? "menstruationAssessment.outroPage.stage.ihc"
          : null
      ].filter(Boolean)
    };
  }

  getPainContent(results) {
    const hasPain = Object.values(results.pain?.areas).some(
      area => area.level > 0
    );

    const painPath = `menstruationAssessment.outroPage.pain.${
      hasPain ? "hasPain" : "noPain"
    }`;

    return {
      title: `${painPath}.heading`,
      paragraphs: [...this.getTranslationArray(`${painPath}.text`)]
    };
  }

  getSymptomsContent(symptoms) {
    const symptomMap = {
      [Answers.symptoms.menstruationSymptoms.abdominalCramps]:
        "abdominalCramps",
      [Answers.symptoms.menstruationSymptoms.headaches]: "headaches",
      [Answers.symptoms.menstruationSymptoms.tiredness]: "tiredness",
      [Answers.symptoms.menstruationSymptoms.moodSwings]: "moodSwings",
      [Answers.symptoms.menstruationSymptoms.bloating]: "bloating",
      [Answers.symptoms.menstruationSymptoms.other]: "other"
    };

    const filteredSymptoms = symptoms
      .filter(symptomId => symptomId !== 98)
      .slice(0, 3);

    return {
      title: "menstruationAssessment.outroPage.symptoms.heading",
      paragraphs: [
        ...filteredSymptoms
          .filter(symptomId => symptomId !== 98)
          .map(symptomId =>
            i18n.t(
              `menstruationAssessment.outroPage.symptoms.symptoms.${symptomMap[symptomId]}`
            )
          ),
        i18n.t("menstruationAssessment.outroPage.symptoms.reportFooter")
      ]
    };
  }

  getTranslationArray(key) {
    return Array.isArray(i18n.t(key)) ? i18n.t(key) : [i18n.t(key)];
  }
}
