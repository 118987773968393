import store from "@/store";
import { routes } from "@/router/route-constants.js";
import { getAssessmentDetails } from "@/customApi.js";
import {
  Actions,
  RedirectStoreRoutes
} from "@/services/authorization/url-redirect/redirect-constants.js";
import sentry from "@/sentry.js";

export async function handlePeopleRedirect() {
  const action = store.getters[RedirectStoreRoutes.GETTERS.REDIRECT_ACTION];
  const params = store.getters[RedirectStoreRoutes.GETTERS.REDIRECT_PARAMS];

  switch (action) {
    case Actions.PEOPLE.VIEW_REPORT:
      if (!params.assessmentId) {
        return { name: routes.PEOPLE };
      }
      return await redirectToUserReport(params.assessmentId);
    default:
      return { name: routes.PEOPLE };
  }
}

async function redirectToUserReport(assessmentId) {
  var redirectObject = {
    name: routes.PEOPLE,
    params: {}
  };

  try {
    const assessmentDetails = await getAssessmentDetails(assessmentId);

    redirectObject = {
      name: routes.USER_PROFILE, // Use path format for UserProfile
      params: {
        userId: assessmentDetails.userId,
        preloadedAssessment: {
          id: assessmentId,
          assessmentType: assessmentDetails.assessmentType,
          date: assessmentDetails.lastUpdatedAt ?? assessmentDetails.completedAt
        }
      }
    };

    return redirectObject;
  } catch (err) {
    sentry.captureException(
      new Error(`Failed to redirect admin to report: ${err.message}`),
      {
        extra: {
          assessmentId: assessmentId
        }
      }
    );
    return redirectObject;
  }
}
