import i18n from "@/plugins/i18n.js";
import {
  STAGE_MAPPING,
  STAGES
} from "@/components/assessment/menopause/report/constants";
import { capitaliseFirstLetter } from "@/components/assessment/menopause/report/helpers.js";

export const getStageKey = stage => {
  return STAGE_MAPPING[stage] || STAGES.MENOPAUSE;
};

export const getTranslatedStage = stage => {
  const stageKey = getStageKey(stage);

  return i18n.t(`menopauseAssessment.stages.${stageKey}`);
};

export const PAIN_CONTENT = {
  areasCardTitle: () =>
    i18n.t("menopauseAssessment.report.content.pain.areasCard.title"),
  contentCardTitle: () =>
    i18n.t("menopauseAssessment.report.content.pain.contentCard.title"),
  getStageText: stage => getTranslatedStage(stage),
  getSectionTitle: stage => {
    const translatedStage = getTranslatedStage(stage);

    return i18n.t("menopauseAssessment.report.content.pain.sectionTitle", {
      stage: translatedStage
    });
  },
  getContentBody: stage => {
    const translatedStage = capitaliseFirstLetter(getTranslatedStage(stage));

    const translatedStrings = i18n.t(
      "menopauseAssessment.report.content.pain.content.body",
      {
        stage: translatedStage
      },
      { returnObjects: true }
    );

    return translatedStrings.map(text =>
      text.replace(/\{stage\}/g, translatedStage)
    );
  }
};
