import Sentry from "@/sentry.js";
import { addRecommendationInteraction } from "@/customApi";

export const InteractionStates = {
  NONE: "Unresolved",
  COMPLETED: "Completed",
  WANT_TO_TRY: "WantToTry",
  NOT_INTERESTED: "NotInterested",
  ALREADY_DOING: "AlreadyDoing"
};

export const InteractionPriorityMap = {
  [InteractionStates.WANT_TO_TRY]: 1,
  [InteractionStates.NONE]: 2,
  [InteractionStates.COMPLETED]: 3,
  [InteractionStates.ALREADY_DOING]: 4,
  [InteractionStates.NOT_INTERESTED]: 5
};

export const AutoMarkAsDoingCardIds = [
  "menopause_strength_training",
  "menstruation_strength_training"
];

export function getInteractionStateDetails(state) {
  switch (state) {
    case InteractionStates.COMPLETED:
    case InteractionStates.ALREADY_DOING:
      return { icon: "images/icons/check.svg", color: "#3EB288" };
    case InteractionStates.WANT_TO_TRY:
      return { icon: "images/icons/confetti.svg", color: "vflPrimary" };
    case InteractionStates.NOT_INTERESTED:
      return { icon: "images/icons/cancel.svg", color: "#5E0000" };
    default:
      return { icon: "", color: "" };
  }
}

export async function updateRecommendationStatus(
  assessmentId,
  recommendationName,
  newState
) {
  try {
    await addRecommendationInteraction(
      assessmentId,
      recommendationName,
      newState
    );
    return { success: true, message: "Status updated successfully" };
  } catch (err) {
    Sentry.captureException(err);
    return { success: false, message: "Error updating status" };
  }
}
